@import url(~slick-carousel/slick/slick-theme.css);
@import url(~magnify/dist/css/magnify.css);
@import url(~nprogress/nprogress.css);
@import url(~perfect-scrollbar/css/perfect-scrollbar.css);
@import url("https://fonts.googleapis.com/css2?family=Tajawal&display=swap");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/**
 * In "iThoob", we are using "Tajawal"
 * In "JZL", we are using "Bahij"
 * Instead of replacing all Bahij fonts, we only replace the main variable's value
 */
html {
  height: 100%; }

body {
  background: #ffffff !important;
  height: 100vh;
  overflow-x: hidden;
  padding-right: 0 !important; }
  body p,
  body div,
  body span,
  body ul,
  body li,
  body h1,
  body h2,
  body h3,
  body h4,
  body h5,
  body h6,
  body label,
  body input,
  body button {
    margin: 0;
    font-family: "Tajawal"; }
  body input::-ms-clear {
    display: none;
    width: 0;
    height: 0; }
  body .isLoading {
    position: relative; }
    body .isLoading:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      z-index: 2; }
    body .isLoading:after {
      content: "";
      display: block;
      position: absolute;
      border: 2px rgba(0, 0, 0, 0.5) solid;
      border-right: 0;
      border-radius: 50%;
      z-index: 3;
      width: 3.125rem;
      height: 3.125rem;
      top: calc(50% - 1.5625rem);
      left: calc(50% - 1.5625rem);
      -webkit-animation: spin 0.8s linear infinite;
      animation: spin 0.8s linear infinite; }
    body .isLoading__page:before {
      background: rgba(255, 255, 255, 0.95);
      z-index: 9999;
      position: fixed;
      display: none; }
    body .isLoading__page:after {
      z-index: 99999;
      position: fixed;
      display: none; }
  body .btnStyle a,
  body .btnStyle button,
  body .btnStyle .button,
  body .btnStyle .oppBtn {
    border: 0;
    cursor: pointer;
    background-color: #7c7c7b;
    display: inline-block;
    padding: 1rem 1.75rem;
    border-radius: 30px;
    transition: all 200ms ease;
    color: #ffffff !important;
    text-decoration: none;
    border: none; }
    @media (max-width: 767.98px) {
      body .btnStyle a,
      body .btnStyle button,
      body .btnStyle .button,
      body .btnStyle .oppBtn {
        padding: 0.9375rem; } }
    body .btnStyle a:hover,
    body .btnStyle button:hover,
    body .btnStyle .button:hover,
    body .btnStyle .oppBtn:hover {
      background-color: #8a633e;
      color: #8a8a8a;
      text-decoration: none; }
    body .btnStyle a.whiteBg,
    body .btnStyle button.whiteBg,
    body .btnStyle .button.whiteBg,
    body .btnStyle .oppBtn.whiteBg {
      background-color: #ffffff !important;
      color: #333333 !important;
      border: 1px solid #333333; }
    body .btnStyle a.disabled, body .btnStyle a:disabled, body .btnStyle a.btn.disabled, body .btnStyle a.btn:disabled,
    body .btnStyle button.disabled,
    body .btnStyle button:disabled,
    body .btnStyle button.btn.disabled,
    body .btnStyle button.btn:disabled,
    body .btnStyle .button.disabled,
    body .btnStyle .button:disabled,
    body .btnStyle .button.btn.disabled,
    body .btnStyle .button.btn:disabled,
    body .btnStyle .oppBtn.disabled,
    body .btnStyle .oppBtn:disabled,
    body .btnStyle .oppBtn.btn.disabled,
    body .btnStyle .oppBtn.btn:disabled {
      opacity: 1;
      color: #ffffff !important;
      background: #bdbdbd !important; }
      body .btnStyle a.disabled:hover, body .btnStyle a:disabled:hover, body .btnStyle a.btn.disabled:hover, body .btnStyle a.btn:disabled:hover,
      body .btnStyle button.disabled:hover,
      body .btnStyle button:disabled:hover,
      body .btnStyle button.btn.disabled:hover,
      body .btnStyle button.btn:disabled:hover,
      body .btnStyle .button.disabled:hover,
      body .btnStyle .button:disabled:hover,
      body .btnStyle .button.btn.disabled:hover,
      body .btnStyle .button.btn:disabled:hover,
      body .btnStyle .oppBtn.disabled:hover,
      body .btnStyle .oppBtn:disabled:hover,
      body .btnStyle .oppBtn.btn.disabled:hover,
      body .btnStyle .oppBtn.btn:disabled:hover {
        cursor: not-allowed !important; }
  body .button {
    background-color: #7c7c7b;
    display: inline-block;
    padding: 1rem 1.75rem;
    border-radius: 0;
    color: #ffffff !important;
    text-decoration: none;
    border: none;
    position: relative;
    border-radius: 30px;
    transition: all 200ms ease; }
    body .button:hover {
      background: #8a633e; }
    body .button.disabled, body .button:disabled, body .button.btn.disabled, body .button.btn:disabled {
      pointer-events: none;
      opacity: 1;
      color: #ffffff !important;
      background: #bdbdbd !important; }
      body .button.disabled:hover, body .button:disabled:hover, body .button.btn.disabled:hover, body .button.btn:disabled:hover {
        cursor: not-allowed !important; }
      body .button.disabled::after, body .button:disabled::after, body .button.btn.disabled::after, body .button.btn:disabled::after {
        filter: grayscale(100%) opacity(0.28); }
    body .button__secondary {
      background-color: #ffffff !important;
      border: 1px #333333 solid;
      color: #333333 !important; }
      body .button__secondary:hover {
        background: #fbfaf8 !important; }
    body .button__block {
      display: block;
      text-align: center; }
    body .button.btnVersa {
      padding: 0.3125rem 3.125rem;
      display: flex;
      align-items: center; }
      body .button.btnVersa::after {
        transform: rotate(180deg);
        right: -33px; }
      body .button.btnVersa a {
        color: #ffffff !important;
        text-decoration: none; }
  body .buttonVersa {
    margin-right: 0.625rem;
    background-color: #f3f3f3;
    border: 1.1px solid #7c7c7b;
    border-radius: 30px;
    position: relative;
    padding: 0.6875rem 1.875rem; }
    body .buttonVersa a {
      color: #7c7c7b;
      text-decoration: none;
      font-weight: bold; }
  body img {
    max-width: 100%; }
  body .card {
    border: 0;
    border-radius: 0;
    background-color: #ffffff; }
    body .card.active {
      background-color: #fcfcfc !important; }
      body .card.active .card-header {
        background-color: #fcfcfc !important;
        border: 0;
        border-bottom: 1px solid #ededed; }
    body .card .card-header {
      background-color: #ffffff;
      border: 0;
      border-bottom: 1px dotted #ededed; }
      body .card .card-header h5 p {
        font-size: 1.25rem;
        padding: 0 0.2rem; }
      body .card .card-header h5 button .collapserTitle {
        font-family: "Tajawal";
        color: #7c7c7b; }
        body .card .card-header h5 button .collapserTitle span {
          margin-left: 0.9375rem; }
          body .card .card-header h5 button .collapserTitle span.icon-arrow {
            font-size: 0.5rem; }
  body button.btn-link {
    text-decoration: none !important; }
    body button.btn-link:hover, body button.btn-link:focus, body button.btn-link.focus {
      text-decoration: none !important; }
  body button.collapsed span.icon-arrow {
    transform: rotate(90deg); }
  body .content {
    margin-top: 2.5rem; }
  body.modal-open {
    padding: 0 !important;
    overflow: auto;
    overflow-x: hidden; }

#nprogress .bar {
  background: #e62323;
  height: 3px; }

#nprogress .peg {
  box-shadow: 0 0 10px #e62323; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.nav-link.active {
  color: #ffffff !important; }
  @media screen and (max-width: 991.98px) {
    .nav-link.active {
      color: #8a633e !important; } }

.loader {
  position: absolute;
  background: rgba(255, 255, 255, 0.71);
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 1.375rem;
  padding-top: 100px;
  padding-bottom: 100px;
  z-index: 99999; }

.positionRelative {
  position: relative; }

.modal {
  border-radius: 0; }
  .modal * {
    border-radius: 0;
    font-family: "Tajawal"; }
  .modal-content {
    border: none; }
  .modal-header {
    justify-content: center;
    align-items: center;
    background-color: #7c7c7b;
    color: #ffffff;
    border: none;
    padding: 1.5625rem; }
    .modal-header button {
      margin-left: 0;
      margin-right: auto;
      flex-grow: 0;
      font-size: 0.75rem;
      color: #ffffff;
      box-shadow: none;
      text-shadow: none;
      opacity: 1; }
      .modal-header button:hover {
        color: #ffffff !important;
        opacity: 1 !important; }
  .modal-title {
    flex-grow: 1;
    text-align: center !important;
    font-size: 1.25rem; }
  .modal-body {
    padding: 1.5625rem 2.5rem; }
  .modal-footer {
    padding: 1.5625rem 0;
    margin: 0 2.5rem;
    flex-wrap: nowrap !important; }
    .modal-footer .button {
      padding: 0.9375rem;
      margin-right: 0; }
      .modal-footer .button::after {
        display: none; }
      .modal-footer .button:not(:first-child) {
        margin-left: 0; }
      .modal-footer .button .disabled:hover {
        cursor: not-allowed; }
  .modal-backdrop {
    background: #ffffff; }
    .modal-backdrop.show {
      opacity: 0.9; }
  .modal#deleteItemModel .modal-header {
    background: none;
    color: #7c7c7b;
    border-bottom: 1.5px solid #ededed; }
    .modal#deleteItemModel .modal-header button span::before {
      color: #7c7c7b; }
  .modal#deleteItemModel .modal-content {
    border: 1px solid #ededed; }
  .modal#deleteItemModel .modal-footer {
    border: none;
    flex-wrap: nowrap !important; }
    .modal#deleteItemModel .modal-footer button.btn {
      border: 1px solid #7c7c7b; }
      .modal#deleteItemModel .modal-footer button.btn.w-100.whiteBg {
        background: #ffffff !important;
        color: #7c7c7b !important;
        border: 1px solid #7c7c7b; }

.boxShadow {
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3); }

.dropdown-item {
  background: #ffffff; }

.black {
  color: #7c7c7b !important; }

.cart-notification {
  position: fixed;
  padding: 0.5rem 1rem;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  background: #8a633e;
  color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  transition: all 300ms ease;
  opacity: 0;
  z-index: -1; }
  .cart-notification.active {
    opacity: 1;
    bottom: 15px;
    z-index: 99999; }

.message-danger {
  margin: 10px 0;
  color: #c30000;
  font-weight: bold;
  background: #c738381f;
  padding: 10px; }

.page__en .sideFilterContainer {
  text-align: left; }
  .page__en .sideFilterContainer__sideFilter__mainMenu__subMenu__link,
  .page__en .sideFilterContainer__sideFilter__mainMenu__subMenu a {
    margin-left: 0;
    margin-right: 1.25rem; }

.page__en .productTags {
  right: 0;
  left: auto; }

.page__en .dropdown-menu {
  right: 0;
  left: auto; }

.page__ar {
  text-align: right !important;
  direction: rtl; }
  .page__ar .dropdown-item,
  .page__ar p,
  .page__ar ul,
  .page__ar h1,
  .page__ar h2,
  .page__ar h3,
  .page__ar h4,
  .page__ar h5,
  .page__ar h6,
  .page__ar input {
    text-align: right; }
  .page__ar .cartHeader__item {
    direction: rtl; }
  .page__ar .ps__rail-y {
    left: 0;
    right: auto !important; }
  .page__ar .was-validated .form-control:invalid,
  .page__ar .form-control.is-invalid {
    background-position: center left calc(0.375em + 0.1875rem);
    background: none; }

.page__en {
  text-align: left !important;
  direction: ltr; }
  .page__en .dropdown-item,
  .page__en p,
  .page__en ul,
  .page__en h1,
  .page__en h2,
  .page__en h3,
  .page__en h4,
  .page__en h5,
  .page__en h6,
  .page__en input {
    text-align: left; }

.DirLTR {
  direction: ltr; }
  .DirLTR__IB {
    display: inline-block; }

@font-face {
  font-family: "ci-icons";
  src: url("fonts/ci-icons.eot?2bpztd");
  src: url("fonts/ci-icons.eot?2bpztd#iefix") format("embedded-opentype"), url("fonts/ci-icons.ttf?2bpztd") format("truetype"), url("fonts/ci-icons.woff?2bpztd") format("woff"), url("fonts/ci-icons.svg?2bpztd#ci-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "ci-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-round-error-symbol:before {
  content: "\e91b"; }

.icon-Location:before {
  content: "\e915"; }

.icon-Search:before {
  content: "\e916"; }

.icon-search-icon:before {
  content: "\e901"; }

.icon-arrow-right:before {
  content: "\e912";
  color: #fff; }

.icon-arrow-left:before {
  content: "\e913";
  color: #fff; }

.icon-redo1:before {
  content: "\e910"; }

.icon-undo1:before {
  content: "\e911"; }

.icon-shopping-cart1:before {
  content: "\e90f";
  color: #f6f5f1; }

.icon-Facebook:before {
  content: "\e908";
  color: #fff; }

.icon-Instagram:before {
  content: "\e909";
  color: #fff; }

.icon-label:before {
  content: "\e90a";
  color: #b88b1c; }

.icon-Twitter:before {
  content: "\e90d";
  color: #fff; }

.icon-btn:before {
  content: "\e90e"; }

.icon-shopping-card-new:before {
  content: "\e906";
  color: #fff; }

.icon-user-down:before {
  content: "\e907";
  color: #fbfaf8; }

.icon-arrow:before {
  content: "\e904";
  color: #8a633e; }

.icon-carouselArrow:before {
  content: "\e905";
  color: #8a633e; }

.icon-undo:before {
  content: "\e914"; }

.icon-redo:before {
  content: "\e902"; }

.icon-close:before {
  content: "\e903"; }

.icon-shopping-cart:before {
  content: "\e91e"; }

.icon-carouselarrowcopy:before {
  content: "\e90c";
  color: #8a633e; }

.icon-tick .path1:before {
  content: "\e900";
  font-family: "ci-icons";
  color: #fdd4a8; }

.icon-tick .path2:before {
  content: "\e90b";
  font-family: "ci-icons";
  margin-left: -1em;
  color: #ffffff; }

.icon-delivery:before {
  content: "\e917";
  color: #939393; }

.icon-branch:before {
  content: "\e91a";
  color: #939393; }

.icon-whiteTick:before {
  content: "\e918";
  color: #fff; }

.icon-sewmachine:before {
  content: "\e919";
  color: #939393; }

.icon-attachment:before {
  content: "\e9cd"; }

.icon-warning:before {
  content: "\ea07"; }

.icon-compass:before {
  content: "\e949"; }

.icon-google-plus:before {
  content: "\ea8b"; }

.slickSlider .slick-arrow {
  top: 110% !important; }
  .slickSlider .slick-arrow:before {
    color: #fdd4a8 !important; }

.slickSlider .slick-prev {
  left: 20px; }

.slickSlider .slick-next {
  left: 80px; }

.firstSlickSlider.contentSlider, .secondSlickSlider.contentSlider, .thirdSlickSlider.contentSlider {
  margin-bottom: 35px; }

.firstSlickSlider .slick-arrow, .secondSlickSlider .slick-arrow, .thirdSlickSlider .slick-arrow {
  position: absolute;
  bottom: -35px;
  cursor: pointer;
  width: 1.5625rem;
  height: 1.5625rem;
  background-color: #ededed;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.5625rem; }
  .firstSlickSlider .slick-arrow:hover, .secondSlickSlider .slick-arrow:hover, .thirdSlickSlider .slick-arrow:hover {
    background-color: #dcdcdc; }
  .firstSlickSlider .slick-arrow span.icon-carouselarrowcopy:before, .firstSlickSlider .slick-arrow span.icon-carouselArrow:before, .secondSlickSlider .slick-arrow span.icon-carouselarrowcopy:before, .secondSlickSlider .slick-arrow span.icon-carouselArrow:before, .thirdSlickSlider .slick-arrow span.icon-carouselarrowcopy:before, .thirdSlickSlider .slick-arrow span.icon-carouselArrow:before {
    color: #7c7c7b; }
  .firstSlickSlider .slick-arrow.slick-disabled, .secondSlickSlider .slick-arrow.slick-disabled, .thirdSlickSlider .slick-arrow.slick-disabled {
    cursor: default; }
    .firstSlickSlider .slick-arrow.slick-disabled .icon-carouselarrowcopy:before, .firstSlickSlider .slick-arrow.slick-disabled .icon-carouselArrow:before, .secondSlickSlider .slick-arrow.slick-disabled .icon-carouselarrowcopy:before, .secondSlickSlider .slick-arrow.slick-disabled .icon-carouselArrow:before, .thirdSlickSlider .slick-arrow.slick-disabled .icon-carouselarrowcopy:before, .thirdSlickSlider .slick-arrow.slick-disabled .icon-carouselArrow:before {
      color: #c8c8c8; }
    .firstSlickSlider .slick-arrow.slick-disabled:hover, .secondSlickSlider .slick-arrow.slick-disabled:hover, .thirdSlickSlider .slick-arrow.slick-disabled:hover {
      background-color: #ededed; }
  .firstSlickSlider .slick-arrow.secondSliderleft, .firstSlickSlider .slick-arrow.firstSlickSliderleft, .firstSlickSlider .slick-arrow.thirdSlickSliderleft, .secondSlickSlider .slick-arrow.secondSliderleft, .secondSlickSlider .slick-arrow.firstSlickSliderleft, .secondSlickSlider .slick-arrow.thirdSlickSliderleft, .thirdSlickSlider .slick-arrow.secondSliderleft, .thirdSlickSlider .slick-arrow.firstSlickSliderleft, .thirdSlickSlider .slick-arrow.thirdSlickSliderleft {
    left: 70px; }
    @media (max-width: 991.98px) {
      .firstSlickSlider .slick-arrow.secondSliderleft, .firstSlickSlider .slick-arrow.firstSlickSliderleft, .firstSlickSlider .slick-arrow.thirdSlickSliderleft, .secondSlickSlider .slick-arrow.secondSliderleft, .secondSlickSlider .slick-arrow.firstSlickSliderleft, .secondSlickSlider .slick-arrow.thirdSlickSliderleft, .thirdSlickSlider .slick-arrow.secondSliderleft, .thirdSlickSlider .slick-arrow.firstSlickSliderleft, .thirdSlickSlider .slick-arrow.thirdSlickSliderleft {
        left: 0px !important; } }
  .firstSlickSlider .slick-arrow.secondSliderright, .firstSlickSlider .slick-arrow.firstSlickSliderright, .firstSlickSlider .slick-arrow.thirdSlickSliderright, .secondSlickSlider .slick-arrow.secondSliderright, .secondSlickSlider .slick-arrow.firstSlickSliderright, .secondSlickSlider .slick-arrow.thirdSlickSliderright, .thirdSlickSlider .slick-arrow.secondSliderright, .thirdSlickSlider .slick-arrow.firstSlickSliderright, .thirdSlickSlider .slick-arrow.thirdSlickSliderright {
    left: 100px; }
    @media (max-width: 991.98px) {
      .firstSlickSlider .slick-arrow.secondSliderright, .firstSlickSlider .slick-arrow.firstSlickSliderright, .firstSlickSlider .slick-arrow.thirdSlickSliderright, .secondSlickSlider .slick-arrow.secondSliderright, .secondSlickSlider .slick-arrow.firstSlickSliderright, .secondSlickSlider .slick-arrow.thirdSlickSliderright, .thirdSlickSlider .slick-arrow.secondSliderright, .thirdSlickSlider .slick-arrow.firstSlickSliderright, .thirdSlickSlider .slick-arrow.thirdSlickSliderright {
        left: 35px !important; } }

.firstSlickSlider .slick-track .slick-slide, .secondSlickSlider .slick-track .slick-slide, .thirdSlickSlider .slick-track .slick-slide {
  float: right !important; }

@media (max-width: 991.98px) {
  .firstSlickSlider .sliderArrowTxt, .secondSlickSlider .sliderArrowTxt, .thirdSlickSlider .sliderArrowTxt {
    left: 70px !important; } }

.page__en .firstSlickSlider .slick-track .slick-slide, .page__en .secondSlickSlider .slick-track .slick-slide, .page__en .thirdSlickSlider .slick-track .slick-slide {
  float: left !important; }

.page__en .firstSlickSlider .slick-arrow, .page__en .secondSlickSlider .slick-arrow, .page__en .thirdSlickSlider .slick-arrow {
  transform: rotate(180deg); }
  .page__en .firstSlickSlider .slick-arrow.secondSliderleft, .page__en .firstSlickSlider .slick-arrow.firstSlickSliderleft, .page__en .firstSlickSlider .slick-arrow.thirdSlickSliderleft, .page__en .secondSlickSlider .slick-arrow.secondSliderleft, .page__en .secondSlickSlider .slick-arrow.firstSlickSliderleft, .page__en .secondSlickSlider .slick-arrow.thirdSlickSliderleft, .page__en .thirdSlickSlider .slick-arrow.secondSliderleft, .page__en .thirdSlickSlider .slick-arrow.firstSlickSliderleft, .page__en .thirdSlickSlider .slick-arrow.thirdSlickSliderleft {
    right: 70px;
    left: auto !important; }
    @media (max-width: 991.98px) {
      .page__en .firstSlickSlider .slick-arrow.secondSliderleft, .page__en .firstSlickSlider .slick-arrow.firstSlickSliderleft, .page__en .firstSlickSlider .slick-arrow.thirdSlickSliderleft, .page__en .secondSlickSlider .slick-arrow.secondSliderleft, .page__en .secondSlickSlider .slick-arrow.firstSlickSliderleft, .page__en .secondSlickSlider .slick-arrow.thirdSlickSliderleft, .page__en .thirdSlickSlider .slick-arrow.secondSliderleft, .page__en .thirdSlickSlider .slick-arrow.firstSlickSliderleft, .page__en .thirdSlickSlider .slick-arrow.thirdSlickSliderleft {
        right: 0px !important; } }
  .page__en .firstSlickSlider .slick-arrow.secondSliderright, .page__en .firstSlickSlider .slick-arrow.firstSlickSliderright, .page__en .firstSlickSlider .slick-arrow.thirdSlickSliderright, .page__en .secondSlickSlider .slick-arrow.secondSliderright, .page__en .secondSlickSlider .slick-arrow.firstSlickSliderright, .page__en .secondSlickSlider .slick-arrow.thirdSlickSliderright, .page__en .thirdSlickSlider .slick-arrow.secondSliderright, .page__en .thirdSlickSlider .slick-arrow.firstSlickSliderright, .page__en .thirdSlickSlider .slick-arrow.thirdSlickSliderright {
    right: 100px;
    left: auto !important; }
    @media (max-width: 991.98px) {
      .page__en .firstSlickSlider .slick-arrow.secondSliderright, .page__en .firstSlickSlider .slick-arrow.firstSlickSliderright, .page__en .firstSlickSlider .slick-arrow.thirdSlickSliderright, .page__en .secondSlickSlider .slick-arrow.secondSliderright, .page__en .secondSlickSlider .slick-arrow.firstSlickSliderright, .page__en .secondSlickSlider .slick-arrow.thirdSlickSliderright, .page__en .thirdSlickSlider .slick-arrow.secondSliderright, .page__en .thirdSlickSlider .slick-arrow.firstSlickSliderright, .page__en .thirdSlickSlider .slick-arrow.thirdSlickSliderright {
        right: 35px !important; } }

.page__en .sliderArrowTxt {
  right: 135px;
  left: auto !important; }
  @media (max-width: 991.98px) {
    .page__en .sliderArrowTxt {
      right: 70px !important; } }

.sideBar {
  position: fixed;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
  padding-bottom: 3rem; }
  .sideBar .customizationsContent__actionBar {
    background-color: #f8f8f8;
    padding-left: 0.9375rem; }
  @media (min-width: 576px) {
    .sideBar {
      width: calc(191.998px - 50px); } }
  @media (min-width: 768px) {
    .sideBar {
      width: calc(255.997px - 50px); } }
  @media (min-width: 992px) {
    .sideBar {
      width: calc(330.66336px - 50px); } }
  @media (min-width: 1200px) {
    .sideBar {
      width: calc( 399.996px - 50px); } }
  @media (max-width: 767.98px) {
    .sideBar {
      position: relative;
      width: 100%; } }
  .sideBar__header {
    text-align: right;
    font-family: "Tajawal";
    margin-left: 1.25rem;
    font-size: 1.5625rem;
    padding: 3rem 0 3rem; }
  .sideBar__productImages .warningNote {
    display: none;
    color: #e62323; }
  .sideBar__productImages__productDesc {
    flex-direction: row-reverse; }
    .sideBar__productImages__productDesc.onScroll {
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 99;
      background-color: #ffffff;
      padding: 20px;
      right: 0;
      background-color: #f8f8f8; }
    .sideBar__productImages__productDesc__btn {
      display: inline-flex;
      width: 65%;
      margin-left: 5%; }
      .sideBar__productImages__productDesc__btn p {
        font-size: 0.75rem;
        padding: 0.625rem 0;
        text-align: center; }
      .sideBar__productImages__productDesc__btn button {
        width: 100%;
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
        padding: 0.625rem 1.5625rem;
        border: 0;
        border-radius: 30px;
        background-color: #8a8a8a;
        cursor: auto; }
        @media (max-width: 991.98px) {
          .sideBar__productImages__productDesc__btn button {
            padding: 0.625rem 0.9375rem; } }
        .sideBar__productImages__productDesc__btn button.active {
          background-color: #7c7c7b;
          cursor: pointer; }
        .sideBar__productImages__productDesc__btn button p {
          color: #ffffff;
          font-size: 1.25rem;
          padding: 0; }
          @media (max-width: 991.98px) {
            .sideBar__productImages__productDesc__btn button p {
              font-size: 1rem; } }
        .sideBar__productImages__productDesc__btn button .icon-shopping-cart:before {
          color: #ffffff; }
        .sideBar__productImages__productDesc__btn button span {
          font-size: 30px;
          padding-left: 0.625rem;
          border-left: 1px solid #7c7c7b;
          margin-left: 0.625rem; }
    .sideBar__productImages__productDesc__cost {
      margin-top: 0.625rem;
      margin-bottom: 0.625rem;
      display: inline-flex;
      width: 30%; }
      .sideBar__productImages__productDesc__cost p {
        color: #7c7c7b;
        margin: auto;
        font-size: 1.5625rem;
        font-weight: bold; }
        @media (max-width: 991.98px) {
          .sideBar__productImages__productDesc__cost p {
            font-size: 1.25rem; } }
      .sideBar__productImages__productDesc__cost span {
        padding-right: 0.3125rem;
        color: #7c7c7b;
        margin: auto;
        font-size: 1.5625rem;
        font-weight: bold; }
        @media (max-width: 991.98px) {
          .sideBar__productImages__productDesc__cost span {
            font-size: 1.25rem; } }
  .sideBar__productImages__thumbnials {
    margin-top: 1.25rem; }
    .sideBar__productImages__thumbnials img {
      display: block; }
  .sideBar__productImages__mainImage img {
    width: 100%; }
  .sideBar .productImages__main {
    max-height: 430px;
    overflow: hidden; }
  .sideBar .productImages__thumbs > a.active::after {
    line-height: 56px; }

.customizations.page_en {
  background-position: left top !important; }

.page__en .measurementsCustom .title {
  padding-right: 1.5625rem;
  padding-left: 0; }

.page__en .measurementsCustom .radioBtn input {
  margin-right: 0.625rem; }

.page__en .measurementsCustom .showMeasurementTable {
  margin-left: 0.4375rem;
  margin-right: 0; }

.page__en .measurementsCustom .dropdownForm .dropdownHeaderCopy {
  padding-right: 2.1875rem !important;
  padding-left: 0.9375rem !important; }
  .page__en .measurementsCustom .dropdownForm .dropdownHeaderCopy::after {
    right: 0.9375rem;
    left: unset !important;
    transform: rotate(90deg) !important; }

.page__en .measurementsCustom .dropdownList {
  right: auto;
  left: 0; }

.page__en .customsPage .customsContainer #accordion {
  box-shadow: none; }

.page__en .customsPage .sidebarContainer {
  padding-left: 0;
  padding-right: 1.875rem; }

.page__en .sideBar__header {
  text-align: left;
  margin-right: 1.25rem;
  margin-left: 0; }

.page__en .sideBar__productImages__productDesc__btn {
  margin-right: 5%;
  margin-left: 0; }
  .page__en .sideBar__productImages__productDesc__btn button span {
    border-right: 1px solid #7c7c7b;
    border-left: 0;
    padding-left: 0;
    padding-right: 0.625rem;
    margin-left: 0; }
  .page__en .sideBar__productImages__productDesc__btn button p {
    padding-left: 0.625rem; }

.page__en .customizationsContent__actionBar .close {
  padding-left: 1.875rem;
  padding-right: 1.25rem;
  border-left: 1px solid #ecebe6;
  margin-right: 0 !important;
  border-right: 0; }

.page__en .customizationsContent .collapserTitle span.icon-arrow {
  margin-left: 0;
  margin-right: 0.9375rem;
  transform: rotate(-90deg) !important; }

.page__en .customizationsContent .card .card-header-items {
  margin-right: auto !important;
  margin-left: 2.375rem; }

.page__en .customizationsContent .card .types, .page__en .customizationsContent .card .images {
  direction: ltr !important; }

.page__en .customizationsContent .card-header {
  border-left: 3px solid #ededed !important; }

.page__en .customizationsContent .card-body .custom {
  text-align: left !important; }
  .page__en .customizationsContent .card-body .custom .title::after {
    left: 0;
    margin-left: -10px;
    right: unset; }

.page__en .customizationsContent .card-body .notes__input p {
  right: 5px;
  left: auto !important; }

.page__en .customizationsContent .card.active .collapserTitle span.icon-arrow {
  transform: rotate(0deg) !important; }

.customizations {
  height: 100%;
  min-height: 100%;
  background-color: #ffffff !important;
  background-image: url("images/customizations-bg.png") !important;
  background-repeat-x: no-repeat !important;
  background-position: right top;
  height: 100%; }
  @media (max-width: 991.98px) {
    .customizations {
      background-image: unset !important; } }
  .customizations .customsPage {
    margin-left: 0;
    margin-right: 0;
    background-color: #f8f8f8; }
    .customizations .customsPage .sidebarContainer {
      padding-right: 0;
      padding-left: 1.875rem;
      background-color: #f8f8f8; }
      @media (max-width: 991.98px) {
        .customizations .customsPage .sidebarContainer {
          padding-left: 0; } }
      .customizations .customsPage .sidebarContainer .sideBar {
        background-color: #f8f8f8;
        position: relative;
        z-index: 99; }
        .customizations .customsPage .sidebarContainer .sideBar:before {
          position: absolute;
          content: "";
          width: 20px;
          left: -20px;
          color: #f8f8f8; }
        .customizations .customsPage .sidebarContainer .sideBar .productImages__main {
          height: auto !important;
          max-height: none; }
          .customizations .customsPage .sidebarContainer .sideBar .productImages__main img {
            height: auto; }
    .customizations .customsPage .customsContainer {
      padding-left: 0;
      padding-right: 0;
      position: relative;
      height: 100%; }
      .customizations .customsPage .customsContainer #accordion {
        box-shadow: 171px 11px 0px #f8f8f8;
        border: 1px solid #ededed;
        border-top: 0;
        z-index: -1;
        background: #f8f8f8;
        padding-bottom: 6.875rem; }

.customizationsContent {
  border-right: 1px solid #ededed;
  background-color: #f8f8f8;
  height: 100vh; }
  .customizationsContent .fabricChildrens .firstChild .subShape,
  .customizationsContent .fabricChildrens .firstChild .items,
  .customizationsContent .fabricChildrens .firstChild .subitems,
  .customizationsContent .fabricChildrens .secondChild .subShape,
  .customizationsContent .fabricChildrens .secondChild .items,
  .customizationsContent .fabricChildrens .secondChild .subitems,
  .customizationsContent .fabricChildrens .thirdChild .subShape,
  .customizationsContent .fabricChildrens .thirdChild .items,
  .customizationsContent .fabricChildrens .thirdChild .subitems,
  .customizationsContent .fabricChildrens .secondChild__Sub .subShape,
  .customizationsContent .fabricChildrens .secondChild__Sub .items,
  .customizationsContent .fabricChildrens .secondChild__Sub .subitems {
    display: none; }
  .customizationsContent .fabricChildrens .firstChild p,
  .customizationsContent .fabricChildrens .secondChild p,
  .customizationsContent .fabricChildrens .thirdChild p,
  .customizationsContent .fabricChildrens .secondChild__Sub p {
    margin-top: -0.625rem; }
    .customizationsContent .fabricChildrens .firstChild p.tick,
    .customizationsContent .fabricChildrens .secondChild p.tick,
    .customizationsContent .fabricChildrens .thirdChild p.tick,
    .customizationsContent .fabricChildrens .secondChild__Sub p.tick {
      position: relative;
      z-index: 999;
      margin-top: -1.5rem; }
    .customizationsContent .fabricChildrens .firstChild p.title,
    .customizationsContent .fabricChildrens .secondChild p.title,
    .customizationsContent .fabricChildrens .thirdChild p.title,
    .customizationsContent .fabricChildrens .secondChild__Sub p.title {
      padding: 0.625rem 0;
      position: relative;
      margin-right: 0.9375rem; }
      .customizationsContent .fabricChildrens .firstChild p.title::after,
      .customizationsContent .fabricChildrens .secondChild p.title::after,
      .customizationsContent .fabricChildrens .thirdChild p.title::after,
      .customizationsContent .fabricChildrens .secondChild__Sub p.title::after {
        content: "";
        width: 0.3125rem;
        height: 0.3125rem;
        border-radius: 100%;
        background: #bbb39b;
        position: absolute;
        right: 0;
        top: 1.25rem;
        margin-right: -0.875rem; }
      .customizationsContent .fabricChildrens .firstChild p.title span,
      .customizationsContent .fabricChildrens .secondChild p.title span,
      .customizationsContent .fabricChildrens .thirdChild p.title span,
      .customizationsContent .fabricChildrens .secondChild__Sub p.title span {
        margin-right: 0.3125rem; }
        .customizationsContent .fabricChildrens .firstChild p.title span.moreinfo,
        .customizationsContent .fabricChildrens .secondChild p.title span.moreinfo,
        .customizationsContent .fabricChildrens .thirdChild p.title span.moreinfo,
        .customizationsContent .fabricChildrens .secondChild__Sub p.title span.moreinfo {
          padding: 0.125rem 0.3125rem;
          cursor: pointer;
          border-radius: 10px;
          border: 1px solid #ededed; }
  .customizationsContent .fabricChildrens .secondChild .images,
  .customizationsContent .fabricChildrens .thirdChild .images,
  .customizationsContent .fabricChildrens .secondChild__Sub .images {
    margin-right: 1.875rem; }
    @media (max-width: 991.98px) {
      .customizationsContent .fabricChildrens .secondChild .images,
      .customizationsContent .fabricChildrens .thirdChild .images,
      .customizationsContent .fabricChildrens .secondChild__Sub .images {
        margin-right: 0.9375rem; } }
  .customizationsContent .fabricChildrens .secondChild p.title,
  .customizationsContent .fabricChildrens .thirdChild p.title,
  .customizationsContent .fabricChildrens .secondChild__Sub p.title {
    margin-right: 1.875rem; }
    @media (max-width: 991.98px) {
      .customizationsContent .fabricChildrens .secondChild p.title,
      .customizationsContent .fabricChildrens .thirdChild p.title,
      .customizationsContent .fabricChildrens .secondChild__Sub p.title {
        margin-right: 0.9375rem; } }
    .customizationsContent .fabricChildrens .secondChild p.title::after,
    .customizationsContent .fabricChildrens .thirdChild p.title::after,
    .customizationsContent .fabricChildrens .secondChild__Sub p.title::after {
      content: none; }
  .customizationsContent__actionBar {
    font-size: 1.5625rem;
    padding: 3rem 0 3.375rem;
    line-height: 30px;
    background: #ffffff;
    border-right: 3px solid #ededed;
    border-left: 3px solid #ededed; }
    @media (max-width: 991.98px) {
      .customizationsContent__actionBar {
        border-right: 0; } }
    .customizationsContent__actionBar ul {
      align-items: center;
      padding: 0; }
      .customizationsContent__actionBar ul li {
        list-style: none; }
        .customizationsContent__actionBar ul li a {
          text-decoration: none;
          margin-right: 0.625rem;
          color: #7c7c7b; }
        .customizationsContent__actionBar ul li span {
          font-size: 1.0625rem; }
          .customizationsContent__actionBar ul li span.icon-close {
            font-size: 0.875rem; }
          .customizationsContent__actionBar ul li span.icon-redo, .customizationsContent__actionBar ul li span.icon-redo1, .customizationsContent__actionBar ul li span.icon-undo, .customizationsContent__actionBar ul li span.icon-undo1 {
            cursor: pointer;
            color: #dcdcdc;
            margin-right: 1.25rem;
            font-size: 1.25rem; }
            .customizationsContent__actionBar ul li span.icon-redo.active, .customizationsContent__actionBar ul li span.icon-redo1.active, .customizationsContent__actionBar ul li span.icon-undo.active, .customizationsContent__actionBar ul li span.icon-undo1.active {
              color: #7c7c7b; }
        .customizationsContent__actionBar ul li span,
        .customizationsContent__actionBar ul li a {
          vertical-align: middle; }
        .customizationsContent__actionBar ul li .close {
          padding-right: 1.875rem;
          padding-left: 1.875rem;
          margin-right: 1.875rem;
          border-right: 1px solid #ecebe6;
          font-size: 1.75rem;
          color: #7c7c7b;
          opacity: 1;
          cursor: pointer; }
  .customizationsContent .other-options {
    position: relative; }
    .customizationsContent .other-options .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0%;
      left: 0;
      background: #ffffff99;
      opacity: 1;
      z-index: 1; }
      .customizationsContent .other-options .overlay .not-available {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 10px;
        background: #151515;
        color: #ffffff; }
  .customizationsContent .card {
    border: 0;
    border-radius: 0;
    background-color: #ffffff;
    width: 100%; }
    .customizationsContent .card .types,
    .customizationsContent .card .images {
      list-style-type: none;
      padding: 0;
      direction: rtl; }
      .customizationsContent .card .types .contentItem,
      .customizationsContent .card .images .contentItem {
        width: auto !important;
        position: relative;
        margin-right: 1px;
        margin-left: 1.25rem; }
        .customizationsContent .card .types .contentItem label *,
        .customizationsContent .card .types .contentItem .image *,
        .customizationsContent .card .images .contentItem label *,
        .customizationsContent .card .images .contentItem .image * {
          cursor: pointer; }
        .customizationsContent .card .types .contentItem label__with__desc,
        .customizationsContent .card .types .contentItem .image__with__desc,
        .customizationsContent .card .images .contentItem label__with__desc,
        .customizationsContent .card .images .contentItem .image__with__desc {
          min-width: 19.8125rem;
          background: #ffffff;
          border: 1px solid #ededed; }
          @media (max-width: 991.98px) {
            .customizationsContent .card .types .contentItem label__with__desc,
            .customizationsContent .card .types .contentItem .image__with__desc,
            .customizationsContent .card .images .contentItem label__with__desc,
            .customizationsContent .card .images .contentItem .image__with__desc {
              min-width: font-remm(272); } }
          .customizationsContent .card .types .contentItem label__with__desc .image__img,
          .customizationsContent .card .types .contentItem .image__with__desc .image__img,
          .customizationsContent .card .images .contentItem label__with__desc .image__img,
          .customizationsContent .card .images .contentItem .image__with__desc .image__img {
            background: #ffffff; }
          .customizationsContent .card .types .contentItem label__with__desc img,
          .customizationsContent .card .types .contentItem .image__with__desc img,
          .customizationsContent .card .images .contentItem label__with__desc img,
          .customizationsContent .card .images .contentItem .image__with__desc img {
            border: 0;
            margin: 0 auto; }
        .customizationsContent .card .types .contentItem p,
        .customizationsContent .card .images .contentItem p {
          visibility: hidden; }
          .customizationsContent .card .types .contentItem p span.icon-selected,
          .customizationsContent .card .images .contentItem p span.icon-selected {
            position: absolute;
            left: auto;
            right: auto;
            bottom: 12px; }
        .customizationsContent .card .types .contentItem.item .icon-selected,
        .customizationsContent .card .images .contentItem.item .icon-selected {
          bottom: -7px; }
        .customizationsContent .card .types .contentItem:hover .image__img,
        .customizationsContent .card .images .contentItem:hover .image__img {
          cursor: pointer; }
        .customizationsContent .card .types .contentItem:hover .image__desc,
        .customizationsContent .card .images .contentItem:hover .image__desc {
          cursor: initial; }
        .customizationsContent .card .types .contentItem.hoverEffect,
        .customizationsContent .card .images .contentItem.hoverEffect {
          height: 8.125rem !important; }
          .customizationsContent .card .types .contentItem.hoverEffect .image,
          .customizationsContent .card .images .contentItem.hoverEffect .image {
            height: 100%; }
            .customizationsContent .card .types .contentItem.hoverEffect .image__img,
            .customizationsContent .card .images .contentItem.hoverEffect .image__img {
              height: 100%; }
              .customizationsContent .card .types .contentItem.hoverEffect .image__img img,
              .customizationsContent .card .images .contentItem.hoverEffect .image__img img {
                height: 100%; }
          .customizationsContent .card .types .contentItem.hoverEffect:hover .image,
          .customizationsContent .card .images .contentItem.hoverEffect:hover .image {
            border: 1px solid #ededed;
            background-color: #fcfcfc; }
            .customizationsContent .card .types .contentItem.hoverEffect:hover .image *,
            .customizationsContent .card .images .contentItem.hoverEffect:hover .image * {
              cursor: pointer; }
            .customizationsContent .card .types .contentItem.hoverEffect:hover .image__desc,
            .customizationsContent .card .images .contentItem.hoverEffect:hover .image__desc {
              max-width: 100%;
              left: 0;
              top: 30px;
              width: 100%;
              height: calc(100% - 30px);
              position: absolute;
              background: #fcfcfc; }
              .customizationsContent .card .types .contentItem.hoverEffect:hover .image__desc .text,
              .customizationsContent .card .images .contentItem.hoverEffect:hover .image__desc .text {
                top: 10px;
                bottom: auto;
                padding: 0 0.625rem;
                background: #fcfcfc; }
                .customizationsContent .card .types .contentItem.hoverEffect:hover .image__desc .text .desc,
                .customizationsContent .card .types .contentItem.hoverEffect:hover .image__desc .text .cost,
                .customizationsContent .card .images .contentItem.hoverEffect:hover .image__desc .text .desc,
                .customizationsContent .card .images .contentItem.hoverEffect:hover .image__desc .text .cost {
                  font-family: "Tajawal";
                  font-size: 0.875rem; }
              .customizationsContent .card .types .contentItem.hoverEffect:hover .image__desc .description,
              .customizationsContent .card .images .contentItem.hoverEffect:hover .image__desc .description {
                display: block !important;
                bottom: 15px; }
                .customizationsContent .card .types .contentItem.hoverEffect:hover .image__desc .description .desc,
                .customizationsContent .card .images .contentItem.hoverEffect:hover .image__desc .description .desc {
                  font-family: "Tajawal";
                  font-size: 0.75rem;
                  color: #7b7765; }
        .customizationsContent .card .types .contentItem.hoverEffect.item-selected .image,
        .customizationsContent .card .images .contentItem.hoverEffect.item-selected .image {
          border: 1px solid #8a633e; }
          .customizationsContent .card .types .contentItem.hoverEffect.item-selected .image img,
          .customizationsContent .card .images .contentItem.hoverEffect.item-selected .image img {
            border: none !important;
            border: 0px solid transparent; }
        .customizationsContent .card .types .contentItem.hoverEffect.item-selected .text,
        .customizationsContent .card .images .contentItem.hoverEffect.item-selected .text {
          width: 100%;
          left: 0; }
        .customizationsContent .card .types .contentItem .image,
        .customizationsContent .card .images .contentItem .image {
          z-index: 1;
          border: 1px solid transparent;
          overflow: hidden;
          margin-bottom: 0.9375rem;
          position: relative; }
          .customizationsContent .card .types .contentItem .image__img,
          .customizationsContent .card .images .contentItem .image__img {
            max-width: 100%;
            min-width: 100%; }
          .customizationsContent .card .types .contentItem .image__recommend,
          .customizationsContent .card .images .contentItem .image__recommend {
            position: absolute;
            left: 0.625rem;
            top: 0.625rem;
            cursor: initial;
            text-align: center;
            background-color: #7c7c7b;
            color: #ffffff;
            width: 100%;
            width: 3.125rem;
            height: 3.125rem;
            border-radius: 100%;
            font-size: 0.75rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.3125rem; }
          .customizationsContent .card .types .contentItem .image__desc,
          .customizationsContent .card .images .contentItem .image__desc {
            position: relative; }
            .customizationsContent .card .types .contentItem .image__desc .text,
            .customizationsContent .card .images .contentItem .image__desc .text {
              position: absolute;
              top: auto;
              bottom: 0;
              left: 0;
              width: 100%;
              padding: 10px;
              background: #ffffff; }
              .customizationsContent .card .types .contentItem .image__desc .text .desc,
              .customizationsContent .card .images .contentItem .image__desc .text .desc {
                font-family: "Tajawal";
                font-size: 0.9375rem;
                margin: 0 5px; }
            .customizationsContent .card .types .contentItem .image__desc .description,
            .customizationsContent .card .images .contentItem .image__desc .description {
              display: none !important;
              position: absolute;
              right: 10px;
              left: 10px;
              font-family: "Tajawal";
              font-size: 0.9375rem; }
              .customizationsContent .card .types .contentItem .image__desc .description .desc,
              .customizationsContent .card .images .contentItem .image__desc .description .desc {
                font-size: 0.9375rem; }
          .customizationsContent .card .types .contentItem .image__no-desc,
          .customizationsContent .card .images .contentItem .image__no-desc {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 10px;
            background: #ffffff; }
        .customizationsContent .card .types .contentItem img,
        .customizationsContent .card .images .contentItem img {
          border: 1px solid #ededed;
          cursor: pointer; }
      .customizationsContent .card .types label,
      .customizationsContent .card .images label {
        border: 1px solid #ededed;
        padding: 0.9375rem;
        margin-bottom: 0;
        background: #ffffff;
        cursor: pointer; }
      .customizationsContent .card .types .contentItem.item-selected label,
      .customizationsContent .card .images .contentItem.item-selected label {
        background: #fcfcfc;
        border: 1px solid #8a633e; }
      .customizationsContent .card .types .contentItem.item-selected .tickCircle .icon-tick .path1::before,
      .customizationsContent .card .images .contentItem.item-selected .tickCircle .icon-tick .path1::before {
        color: #8a633e !important; }
    .customizationsContent .card .card-header {
      border-right: 3px solid #ededed !important;
      cursor: pointer; }
      .customizationsContent .card .card-header-items {
        height: 3.125rem;
        overflow: hidden;
        align-items: center;
        margin-right: 1.5625rem;
        margin-left: auto; }
        @media (min-width: 992px) and (max-width: 1199.98px) {
          .customizationsContent .card .card-header-items {
            margin-right: 0.3125rem; } }
        @media (max-width: 991.98px) {
          .customizationsContent .card .card-header-items {
            margin-right: 0.3125rem; } }
        @media (max-width: 991.98px) {
          .customizationsContent .card .card-header-items > div {
            display: none; }
            .customizationsContent .card .card-header-items > div:first-child {
              display: block; } }
        .customizationsContent .card .card-header-items img {
          margin-right: 0.1875rem;
          max-width: none;
          width: 85px;
          height: 55px;
          cursor: default; }
        .customizationsContent .card .card-header-items p.moreItems {
          cursor: pointer;
          font-weight: bold; }
        @media (max-width: 991.98px) {
          .customizationsContent .card .card-header-items p.mr-3.ml-3 {
            margin-right: 0.5rem !important;
            margin-left: 0.5rem !important; } }
      .customizationsContent .card .card-header h5 button.btn {
        padding: 0;
        border: none; }
        .customizationsContent .card .card-header h5 button.btn .collapserTitle .icon-arrow {
          color: #8a633e !important;
          transform: rotate(90deg);
          display: inline-block; }
          .customizationsContent .card .card-header h5 button.btn .collapserTitle .icon-arrow::before {
            color: #8a633e !important; }
        @media (max-width: 991.98px) {
          .customizationsContent .card .card-header h5 button.btn .collapserTitle {
            width: max-content; } }
      .customizationsContent .card .card-header h5 label {
        border: 1px solid #ededed;
        padding: 0.9375rem;
        margin-bottom: 0;
        font-size: 1rem;
        background-color: #fcfcfc; }
    .customizationsContent .card .card-body .notLoggedIn {
      display: none; }
      .customizationsContent .card .card-body .notLoggedIn span {
        text-decoration: underline;
        cursor: pointer;
        color: blue; }
    .customizationsContent .card .card-body .notes label,
    .customizationsContent .card .card-body .notes textarea {
      display: block;
      width: 100%; }
    .customizationsContent .card .card-body .notes__input {
      position: relative; }
      .customizationsContent .card .card-body .notes__input p {
        position: absolute;
        left: 0.3125rem;
        top: 0.9375rem;
        cursor: pointer; }
    .customizationsContent .card .card-body .attachments {
      display: none; }
    .customizationsContent .card .card-body .attachmentsList p span {
      color: #e62323;
      cursor: pointer; }
  .customizationsContent .card.active .card-header {
    background-color: #ffffff !important; }
    .customizationsContent .card.active .card-header h5 button.btn .collapserTitle .icon-arrow {
      color: #bbb39b !important;
      transform: rotate(0deg);
      display: inline-block; }
      .customizationsContent .card.active .card-header h5 button.btn .collapserTitle .icon-arrow::before {
        color: #bbb39b !important; }
    .customizationsContent .card.active .card-header h5 label {
      border: 1px solid #ededed;
      padding: 0.9375rem;
      margin-bottom: 0;
      font-size: 1rem;
      background-color: #fcfcfc; }
  .customizationsContent .imagespopUp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(242, 242, 242, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 99999; }
    .customizationsContent .imagespopUp__imagesContent {
      width: 50%;
      margin: 0 auto;
      background-color: #ffffff;
      padding: 2.5rem;
      display: flex;
      align-items: start; }
      .customizationsContent .imagespopUp__imagesContent.closeIcon {
        cursor: pointer;
        display: inline-flex !important;
        justify-content: flex-start; }
      @media (max-width: 991.98px) {
        .customizationsContent .imagespopUp__imagesContent {
          width: 98%;
          padding: 1.25rem; } }
      .customizationsContent .imagespopUp__imagesContent__title {
        font-size: 2.1875rem; }
        @media (max-width: 991.98px) {
          .customizationsContent .imagespopUp__imagesContent__title {
            font-size: 1.5625rem; } }
      .customizationsContent .imagespopUp__imagesContent__cost {
        font-size: 1.5625rem; }
        @media (max-width: 991.98px) {
          .customizationsContent .imagespopUp__imagesContent__cost {
            font-size: 1.25rem; } }
      .customizationsContent .imagespopUp__imagesContent p,
      .customizationsContent .imagespopUp__imagesContent img {
        padding: 0.625rem;
        width: 20%; }
        @media (max-width: 991.98px) {
          .customizationsContent .imagespopUp__imagesContent p,
          .customizationsContent .imagespopUp__imagesContent img {
            padding: 0.625rem; } }
  .customizationsContent .sliderArrowTxt {
    position: absolute;
    bottom: -33px;
    left: 135px;
    display: inline-block;
    color: #848380;
    font-weight: bold;
    font-size: 0.875rem; }

.measurementsCustom {
  margin-bottom: 2.25rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .measurementsCustom.sizeSection {
    margin-bottom: 1.75rem;
    align-items: baseline; }
  .measurementsCustom .title {
    color: #7c7c7b;
    font-size: 0.875rem;
    font-weight: 300;
    padding-left: 1.5625rem; }
    .measurementsCustom .title span {
      display: block;
      padding-right: 0;
      padding-left: 1.5625rem;
      font-size: 0.75rem;
      font-weight: normal; }
      .measurementsCustom .title span a {
        text-decoration: none;
        border-bottom: 1px solid #8a633e; }
  .measurementsCustom .showMeasurementTable {
    display: block;
    padding-right: 0;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: normal;
    margin-right: 0.4375rem;
    color: #767676;
    font-family: "Tajawal"; }
    .measurementsCustom .showMeasurementTable a {
      text-decoration: none;
      border-bottom: 1px solid #767676; }
  .measurementsCustom .radioBtn input {
    margin-left: 0.625rem;
    margin-bottom: 0.3125rem;
    cursor: pointer;
    background-color: #ffffff !important; }
  .measurementsCustom .radioBtn label {
    font-size: 0.75rem;
    width: 100%;
    display: block;
    padding: 0.875rem 0;
    color: #333333; }
    .measurementsCustom .radioBtn label span {
      color: #8a8a8a;
      font-size: 0.625rem; }
  .measurementsCustom .contentItem {
    padding: 0.3125rem;
    border: 1px solid #f2f2f2; }
    .measurementsCustom .contentItem label {
      padding: 0.9375rem;
      margin-left: 0.625rem;
      margin-bottom: 0;
      cursor: pointer; }
      .measurementsCustom .contentItem label.quantityValue {
        cursor: default;
        white-space: nowrap;
        border: none;
        padding: 0.3125rem;
        margin-right: 0.625rem; }
    .measurementsCustom .contentItem .item-selected label {
      background: #fcfcfc;
      color: #8a633e; }
    .measurementsCustom .contentItem p {
      cursor: pointer;
      padding: 0.625rem; }
      .measurementsCustom .contentItem p.inc {
        width: 1.375rem;
        height: 1.375rem;
        background: #e8e8e8;
        color: #7c7c7b;
        padding: 0;
        text-align: center;
        line-height: 1.375rem; }
        .measurementsCustom .contentItem p.inc:hover {
          background: #8a633e;
          color: #ffffff; }
        .measurementsCustom .contentItem p.inc.quantityDisabled {
          background: #e8e8e8;
          color: #8a8a8a; }
          .measurementsCustom .contentItem p.inc.quantityDisabled:hover {
            cursor: not-allowed; }
      .measurementsCustom .contentItem p.tickCircle {
        margin-top: -1.25rem;
        visibility: hidden;
        padding-bottom: 0;
        display: none; }
    .measurementsCustom .contentItem.p-0 {
      border: none;
      margin: 0.625rem 0; }
  .measurementsCustom .dropdownForm {
    position: relative;
    display: inline-block;
    width: auto;
    z-index: 99; }
    @media (max-width: 991.98px) {
      .measurementsCustom .dropdownForm {
        width: 100%; } }
    .measurementsCustom .dropdownForm .dropdown {
      white-space: nowrap;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0.625rem 0;
      border: 1px solid #ededed;
      padding: 0.9375rem 0;
      cursor: pointer;
      pointer-events: none;
      width: 100%; }
      .measurementsCustom .dropdownForm .dropdown p {
        color: #333333;
        margin: 0;
        padding: 0 0.625rem;
        pointer-events: auto;
        margin-left: 10px; }
      .measurementsCustom .dropdownForm .dropdown.active {
        background: #ededed;
        border: 1px solid #8a633e; }
        .measurementsCustom .dropdownForm .dropdown.active p {
          color: #7c7c7b;
          margin: 0;
          pointer-events: auto; }
          .measurementsCustom .dropdownForm .dropdown.active p.dropdownHeaderCopy {
            background: #ededed;
            white-space: nowrap; }
  .measurementsCustom .dropdownList {
    display: none;
    position: absolute;
    top: 3.125rem;
    right: 0;
    left: auto;
    width: auto;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ededed;
    max-height: 190px;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 200px; }
    .measurementsCustom .dropdownList p {
      cursor: pointer;
      margin: 0;
      padding: 0.3125rem 0.625rem;
      color: #7c7c7b;
      border-bottom: 1px solid #ededed; }
      .measurementsCustom .dropdownList p a {
        color: #333333; }
        .measurementsCustom .dropdownList p a:hover {
          color: #7c7c7b; }
      .measurementsCustom .dropdownList p.uncomplete {
        white-space: normal;
        font-size: 0.5rem;
        color: #333333;
        padding: 0 0.3125rem; }
    .measurementsCustom .dropdownList__item {
      white-space: nowrap;
      padding: 0.3125rem 0;
      border: 1px solid #ededed;
      background-color: #ffffff; }
      .measurementsCustom .dropdownList__item:not(:first-of-type) {
        border-top: 0; }

.requiredNote [class^="icon-"] {
  font-size: 0.75rem;
  margin: 0 0.3125rem; }

.requiredNote__txt {
  display: none;
  font-size: 0.75rem;
  max-width: 116px;
  margin: 0 0.4rem; }

.requiredNote:hover .requiredNote__txt {
  display: inline-block; }

.page__ar .sliderArrowTxt {
  font-family: "Swissra-Normal"; }

.page__en {
  background-position: left top; }

.requestTailorPart {
  margin-top: -275px; }
  @media (max-width: 767.98px) {
    .requestTailorPart {
      margin-top: -170px; } }
  .requestTailorPart .requestTailor_content {
    width: 70% !important;
    margin: 50px auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto; }
    .requestTailorPart .requestTailor_content .signupPopup__content__form {
      background: #fff !important; }
    @media (max-width: 767.98px) {
      .requestTailorPart .requestTailor_content {
        width: 90% !important;
        margin: 50px auto; } }

header {
  z-index: 1000;
  position: absolute;
  width: 100%; }
  header .buttons-nav {
    background: #bf9255;
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    border-bottom: 1px solid #ac844d; }
  header .headerNav {
    position: relative;
    z-index: 1000;
    right: 0;
    left: 0; }
    header .headerNav:hover {
      background: rgba(0, 0, 0, 0.2); }
    header .headerNav .upperHeader {
      background: #fff;
      position: relative; }
      header .headerNav .upperHeader::before {
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: calc(((100% - 1140px) / 2) + 15px);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
        @media (max-width: 1199.98px) {
          header .headerNav .upperHeader::before {
            width: calc(((100% - 960px) / 2) + 15px); } }
        @media (max-width: 991.98px) {
          header .headerNav .upperHeader::before {
            width: calc((((100% - 705px) / 2) + 15px) - 22px); } }
      header .headerNav .upperHeader::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: calc((((100% - 1140px) / 2) + 15px) - 22px);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        margin-left: 22px;
        border-left: 1px solid rgba(255, 255, 255, 0.1); }
        @media (max-width: 1199.98px) {
          header .headerNav .upperHeader::after {
            width: calc((((100% - 960px) / 2) + 15px) - 22px); } }
        @media (max-width: 991.98px) {
          header .headerNav .upperHeader::after {
            width: calc((((100% - 720px) / 2) + 15px) - 22px); } }
      header .headerNav .upperHeader__ContactUs {
        margin: 0 auto 0 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        width: 100%; }
        header .headerNav .upperHeader__ContactUs__internalLinks {
          padding: 0;
          align-items: center;
          text-align: left;
          margin: 0 auto 0 0; }
          header .headerNav .upperHeader__ContactUs__internalLinks li a {
            font-size: 0.75rem;
            color: #b7b7b7;
            transition: all 200ms ease; }
            header .headerNav .upperHeader__ContactUs__internalLinks li a:hover {
              text-decoration: none;
              color: #8a633e; }
            @media screen and (max-width: 991.98px) {
              header .headerNav .upperHeader__ContactUs__internalLinks li a {
                margin-right: 0;
                display: block;
                padding: 0.5rem 0; } }
          header .headerNav .upperHeader__ContactUs__internalLinks li.lang {
            padding: 0.5rem;
            border-right: 1px solid rgba(255, 255, 255, 0.1);
            color: #b7b7b7; }
            header .headerNav .upperHeader__ContactUs__internalLinks li.lang .button {
              padding-left: 0px;
              padding-right: 5px;
              padding-top: 0;
              padding-bottom: 0;
              background: transparent !important;
              color: #eaeaea !important;
              font-size: 0.8125rem !important; }
              header .headerNav .upperHeader__ContactUs__internalLinks li.lang .button::after {
                display: none; }
          @media screen and (max-width: 991.98px) {
            header .headerNav .upperHeader__ContactUs__internalLinks {
              flex-direction: column;
              align-items: baseline; } }
      header .headerNav .upperHeader__socialMedia {
        margin: 0 6.75rem 0 0;
        padding-right: 1rem;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
        header .headerNav .upperHeader__socialMedia li {
          padding-right: 1.6875rem; }
          header .headerNav .upperHeader__socialMedia li a {
            display: flex;
            font-size: 0.75rem;
            text-decoration: none;
            color: #b0b0b0;
            transition: all 200ms ease; }
            header .headerNav .upperHeader__socialMedia li a:hover {
              color: #8a633e; }
            header .headerNav .upperHeader__socialMedia li a span {
              color: inherit; }
              header .headerNav .upperHeader__socialMedia li a span::before {
                color: inherit; }
            header .headerNav .upperHeader__socialMedia li a svg {
              fill: #b0b0b0; }
              header .headerNav .upperHeader__socialMedia li a svg:hover {
                fill: #8a633e; }
    header .headerNav nav {
      padding: 0;
      flex-direction: column;
      background: #bf9255; }
      @media screen and (max-width: 991.98px) {
        header .headerNav nav {
          padding: 9px;
          background: #ffffff; } }
      @media screen and (max-width: 991.98px) {
        header .headerNav nav .container {
          padding: 0 0.9375rem;
          position: relative; } }
      header .headerNav nav .navbar-brand {
        margin-right: -12px;
        position: absolute;
        padding: 0;
        top: -2.5rem;
        background: #fff; }
        header .headerNav nav .navbar-brand img {
          transition: all 200ms ease;
          width: 110px;
          padding: 10px; }
        @media screen and (max-width: 991.98px) {
          header .headerNav nav .navbar-brand {
            top: -0.625rem; }
            header .headerNav nav .navbar-brand img {
              width: 105px; } }
      header .headerNav nav .mainMenu {
        margin-left: 1rem;
        margin-right: calc(((100% - 1140px) / 2) + 15px + 110px + 24px);
        padding: 0; }
        @media (max-width: 1199.98px) {
          header .headerNav nav .mainMenu {
            margin-right: calc(((100% - 960px) / 2) + 15px + 110px + 24px); } }
        @media (max-width: 991.98px) {
          header .headerNav nav .mainMenu {
            margin-right: auto; } }
        header .headerNav nav .mainMenu li.nav-item a {
          color: #ffffff;
          padding: 1.5rem 2rem;
          display: inline-block;
          position: relative;
          transition: all 200ms ease;
          white-space: nowrap; }
          @media screen and (max-width: 1199.98px) {
            header .headerNav nav .mainMenu li.nav-item a {
              padding: 1.375rem 0.75rem; } }
          @media screen and (max-width: 991.98px) {
            header .headerNav nav .mainMenu li.nav-item a {
              padding: 0.625rem 0rem;
              color: #7c7c7b; }
              header .headerNav nav .mainMenu li.nav-item a::before {
                display: none; } }
          header .headerNav nav .mainMenu li.nav-item a.active, header .headerNav nav .mainMenu li.nav-item a:hover {
            color: #8a633e !important; }
          header .headerNav nav .mainMenu li.nav-item a .activeArrow {
            position: absolute;
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid #8a633e;
            border-left: 15px solid transparent;
            bottom: 0;
            right: calc(50% - 15px);
            display: none; }
        header .headerNav nav .mainMenu li.nav-item .subMenu {
          display: none;
          position: absolute;
          background: #8a633e;
          left: 0;
          bottom: 0;
          transform: translateY(100%);
          padding: 0;
          margin-left: 0;
          margin-right: auto;
          width: calc(100% - calc(((100% - 1170px) / 2) + 110px + 54px)); }
          @media (min-width: 992px) and (max-width: 1199.98px) {
            header .headerNav nav .mainMenu li.nav-item .subMenu {
              width: calc( 100% - calc(((100% - 960px + 30px) / 2) + 110px + 24px)); } }
          @media (min-width: 768px) and (max-width: 991.98px) {
            header .headerNav nav .mainMenu li.nav-item .subMenu {
              width: calc( 100% - calc(((100% - 720px + 30px) / 2) + 110px + 60px)); } }
          @media screen and (max-width: 991.98px) {
            header .headerNav nav .mainMenu li.nav-item .subMenu {
              position: static;
              transform: none; } }
          header .headerNav nav .mainMenu li.nav-item .subMenu li {
            display: inline;
            white-space: nowrap; }
            @media screen and (max-width: 991.98px) {
              header .headerNav nav .mainMenu li.nav-item .subMenu li {
                display: block; } }
            header .headerNav nav .mainMenu li.nav-item .subMenu li a {
              color: #ffffff;
              padding: 1.25rem; }
              header .headerNav nav .mainMenu li.nav-item .subMenu li a::before {
                display: none; }
              header .headerNav nav .mainMenu li.nav-item .subMenu li a:hover {
                color: #fff !important; }
        @media screen and (min-width: 768px) {
          header .headerNav nav .mainMenu li.nav-item:hover > .subMenu {
            display: flex; }
          header .headerNav nav .mainMenu li.nav-item:hover a .activeArrow {
            display: block; } }
        @media screen and (max-width: 991.98px) {
          header .headerNav nav .mainMenu li.nav-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap; } }
        header .headerNav nav .mainMenu li.nav-item:last-child a {
          padding-left: 0; }
          header .headerNav nav .mainMenu li.nav-item:last-child a .activeArrow {
            right: 50%; }
        header .headerNav nav .mainMenu li.nav-item:first-child a {
          padding-right: 0; }
          header .headerNav nav .mainMenu li.nav-item:first-child a::before {
            display: none; }
          header .headerNav nav .mainMenu li.nav-item:first-child a::after {
            right: calc(50% - 30px); }
          header .headerNav nav .mainMenu li.nav-item:first-child a:hover::after {
            display: none; }
      header .headerNav nav .menu-part {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center; }
      header .headerNav nav .dropdown {
        display: flex;
        margin-right: 0; }
        header .headerNav nav .dropdown .dropdown-toggle {
          padding: 0 1.125rem 0 0; }
          header .headerNav nav .dropdown .dropdown-toggle#cartDropdown {
            padding: 0 2.1875rem 0 0; }
          header .headerNav nav .dropdown .dropdown-toggle:hover {
            text-decoration: none; }
          header .headerNav nav .dropdown .dropdown-toggle span {
            font-size: 1.375rem; }
            header .headerNav nav .dropdown .dropdown-toggle span.icon-shopping-cart1 {
              font-size: 2rem; }
          header .headerNav nav .dropdown .dropdown-toggle::after {
            display: none !important; }
        @media screen and (max-width: 991px) {
          header .headerNav nav .dropdown {
            margin-right: 2rem; }
            header .headerNav nav .dropdown .dropdown-toggle {
              padding: 0.8rem 0 0.7rem; } }
        @media (min-width: 1200px) {
          header .headerNav nav .dropdown {
            margin-left: 1rem; } }
      header .headerNav nav .navbar-toggler {
        margin: auto auto 0 0; }
        @media screen and (max-width: 991.98px) {
          header .headerNav nav .navbar-toggler {
            border-color: #7c7c7b;
            background: #7c7c7b; } }
      header .headerNav nav::after {
        content: "";
        background-image: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0));
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: -1;
        pointer-events: none; }
      @media (min-width: 1200px) {
        header .headerNav nav .mainMenu {
          margin-left: 3rem; } }
    header .headerNav .navbar.fixed {
      position: fixed;
      top: 0;
      width: 100%;
      background: #bf9255; }
      header .headerNav .navbar.fixed .navbar-brand {
        top: 0;
        padding: 0; }
        @media (max-width: 991.98px) {
          header .headerNav .navbar.fixed .navbar-brand {
            top: -0.625rem; } }
        header .headerNav .navbar.fixed .navbar-brand img {
          width: 100px;
          padding: 10px; }
      header .headerNav .navbar.fixed .mainMenu {
        padding: 0; }
        header .headerNav .navbar.fixed .mainMenu li.nav-item a {
          padding: 1.25rem 1.25rem;
          color: #ffffff; }
          @media screen and (max-width: 991.98px) {
            header .headerNav .navbar.fixed .mainMenu li.nav-item a {
              padding: 0.625rem 0rem; } }
        header .headerNav .navbar.fixed .mainMenu li.nav-item .subMenu {
          margin-left: 0;
          margin-right: auto;
          width: calc( 100% - calc(((100% - 1140px + 30px) / 2) + 85px + 143px)); }
          header .headerNav .navbar.fixed .mainMenu li.nav-item .subMenu li a {
            color: #ffffff;
            padding: 1.25rem; }
          @media (min-width: 992px) and (max-width: 1199.98px) {
            header .headerNav .navbar.fixed .mainMenu li.nav-item .subMenu {
              width: calc( 100% - calc(((100% - 960px + 30px) / 2) + 85px + 143px)); } }
          @media (min-width: 768px) and (max-width: 991.98px) {
            header .headerNav .navbar.fixed .mainMenu li.nav-item .subMenu {
              width: calc( 100% - calc(((100% - 720px + 30px) / 2) + 85px + 143px)); } }
        header .headerNav .navbar.fixed .mainMenu li.nav-item:first-child a {
          padding-right: 0; }
        header .headerNav .navbar.fixed .mainMenu li.nav-item:last-child a {
          padding-left: 0; }
      header .headerNav .navbar.fixed .login {
        color: #ffffff !important; }
      header .headerNav .navbar.fixed .icon-shopping-cart1::before,
      header .headerNav .navbar.fixed .icon-user-down::before {
        color: #ffffff !important; }
      header .headerNav .navbar.fixed::after {
        transform: translateY(100%); }
    header .headerNav a.login {
      margin-bottom: 0;
      color: #ffffff;
      cursor: pointer;
      text-decoration: none;
      white-space: nowrap;
      font-size: 15px; }
      @media (max-width: 991.98px) {
        header .headerNav a.login {
          color: #7c7c7b; } }
      header .headerNav a.login:hover {
        color: #fbfaf8; }
        @media (max-width: 991.98px) {
          header .headerNav a.login:hover {
            color: #7c7c7b; } }
    @media (max-width: 991.98px) {
      header .headerNav a.sub-menu-login {
        display: none; } }
  header .cartHeader {
    min-width: 25rem;
    background: #7c7c7b; }
    header .cartHeader * {
      background: #7c7c7b;
      color: #fff; }
    @media (max-width: 991.98px) {
      header .cartHeader {
        min-width: 100%; } }
    @media screen and (max-width: 500px) {
      header .cartHeader {
        min-width: unset;
        width: 15rem; } }
    header .cartHeader__items {
      height: 8.125rem;
      position: relative;
      direction: ltr;
      overflow: auto; }
      @media (max-width: 991.98px) {
        header .cartHeader__items .dropdown-item {
          padding: 0.25rem 0.625rem; } }
    header .cartHeader__item img {
      max-width: 4.0625rem; }
      @media (max-width: 991.98px) {
        header .cartHeader__item img {
          width: 3.125rem; } }
    header .cartHeader__item__title {
      margin: 0 1.25rem;
      white-space: normal;
      min-width: 9.375rem;
      flex-shrink: 1; }
      @media (max-width: 991.98px) {
        header .cartHeader__item__title {
          min-width: unset;
          margin: 0 0.625rem; } }
    header .cartHeader .button {
      margin: 0.3125rem 0;
      padding: 0.7rem; }
      header .cartHeader .button::after {
        display: none; }
      header .cartHeader .button.disabled {
        pointer-events: unset; }
        header .cartHeader .button.disabled:hover {
          cursor: not-allowed; }
    header .cartHeader__count {
      position: absolute;
      color: #fff;
      background: #8c4b4b;
      width: 1.5625rem;
      height: 1.5625rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0.3rem;
      left: 0.9rem; }
  header .menuContainer {
    flex-grow: 1; }
  header .dropdown-item.active,
  header .dropdown-item:active,
  header .dropdown-item:hover,
  header .dropdown-item:focus {
    background: none;
    outline: none; }

.navbar.fixed + .content {
  padding-top: 100px; }

.navArrow {
  color: #ffffff;
  font-size: 0.5625rem;
  margin: 5px; }
  .navArrow:hover {
    cursor: pointer; }
  @media screen and (min-width: 768px) {
    .navArrow {
      display: none !important; } }

.dropdownNav {
  padding: 0; }
  @media screen and (max-width: 991.98px) {
    .dropdownNav {
      padding: 0;
      padding-right: 20px; }
      .dropdownNav button {
        padding-right: 0 !important; } }
  @media screen and (max-width: 991.98px) {
    .dropdownNav {
      position: absolute;
      left: 4.75rem;
      top: -5px; }
      .dropdownNav .nav-item.dropdown .dropdown-toggle .icon-shopping-card-new::before,
      .dropdownNav .nav-item.dropdown .dropdown-toggle .icon-user-down::before {
        color: #7c7c7b; } }
  @media (max-width: 575.98px) {
    .dropdownNav {
      left: calc(15px + 56px); } }
  .dropdownNav .dropdown-menu {
    position: absolute; }
    @media (max-width: 991.98px) {
      .dropdownNav .dropdown-menu .dropdown-item {
        padding: 0.25rem 0.625rem;
        font-size: 0.9375rem; } }

.navbar.fixed .dropdownNav .nav-item.dropdown .dropdown-toggle .icon-shopping-card-new::before, .navbar.fixed .dropdownNav .nav-item.dropdown .dropdown-toggle .icon-user-down::before {
  color: #ffffff; }

@media (max-width: 991px) {
  .navbar.fixed .mainMenu li.nav-item a::before {
    display: none; } }

.btn.button.designYourThoobBtn {
  padding: 5px 16px;
  border-radius: 8px;
  font-size: 16px;
  margin: 0 0.5rem;
  white-space: nowrap;
  color: #8a633e !important;
  background: #fff; }
  @media (max-width: 991.98px) {
    .btn.button.designYourThoobBtn {
      font-size: 14px;
      margin: 5px;
      padding: 5px 10px; } }

.navbar-brand .cls-1 {
  fill: #7c7c7b; }

.navbar-brand .cls-1,
.navbar-brand .cls-2 {
  fill-rule: evenodd; }

.navbar-brand .cls-2 {
  fill: #8a633e; }

.page__en header .headerNav nav .navbar-toggler {
  margin: 0;
  position: absolute;
  right: 10px;
  top: 0px; }

@media screen and (max-width: 992px) {
  .page__en header .headerNav nav {
    min-height: 60px; }
    .page__en header .headerNav nav .dropdown {
      margin-right: 0;
      margin-left: 2rem; } }

.page__en header .headerNav .navbar.fixed ul.navbar-nav.mainMenu {
  padding: 2.75rem 0 0 0; }
  .page__en header .headerNav .navbar.fixed ul.navbar-nav.mainMenu li.nav-item a.nav-link::before {
    left: -3.5px; }
  @media screen and (max-width: 991.98px) {
    .page__en header .headerNav .navbar.fixed ul.navbar-nav.mainMenu li.nav-item a.nav-link.active {
      color: #8a633e !important; } }
  .page__en header .headerNav .navbar.fixed ul.navbar-nav.mainMenu li.nav-item .subMenu {
    right: 0;
    left: unset !important; }
  @media screen and (min-width: 992px) {
    .page__en header .headerNav .navbar.fixed ul.navbar-nav.mainMenu {
      padding: 0 0 0 6.875rem; }
      .page__en header .headerNav .navbar.fixed ul.navbar-nav.mainMenu li:last-child a {
        padding-left: 2rem; }
      .page__en header .headerNav .navbar.fixed ul.navbar-nav.mainMenu li:first-child a {
        padding-right: 2rem; } }

.page__en header .designYourThoobBtn {
  margin-right: 2.5rem !important; }

.page__en header .upperHeader__ContactUs__internalLinks {
  margin: 0 0 0 auto; }
  .page__en header .upperHeader__ContactUs__internalLinks .lang {
    padding-left: 1.875rem;
    padding-right: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 0 !important; }
    .page__en header .upperHeader__ContactUs__internalLinks .lang .button {
      padding-left: 0.3125rem !important;
      padding-right: 0 !important; }

.page__en header .upperHeader::after {
  left: 0;
  margin-left: 0 !important;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 0;
  width: calc((((100% - 1140px) / 2) - 7px)); }

.page__en header .upperHeader::before {
  right: 0;
  left: unset; }

.page__en header .upperHeader__socialMedia {
  margin-right: 0;
  margin-left: 8.375rem;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: none; }
  .page__en header .upperHeader__socialMedia li {
    padding-right: 0;
    padding-left: 1.6875rem; }

.page__en header ul.navbar-nav.mainMenu {
  margin-right: 0 !important;
  margin-left: auto !important;
  padding: 2.75rem 0 0 0; }
  .page__en header ul.navbar-nav.mainMenu li.nav-item a.nav-link::before {
    left: -3.5px; }
  .page__en header ul.navbar-nav.mainMenu li.nav-item .subMenu {
    right: 0;
    left: unset !important; }
  @media screen and (min-width: 992px) {
    .page__en header ul.navbar-nav.mainMenu {
      padding: 0 0 0 6.875rem; }
      .page__en header ul.navbar-nav.mainMenu li:last-child a {
        padding-left: 2rem; }
      .page__en header ul.navbar-nav.mainMenu li:first-child a {
        padding-right: 2rem; } }

@media screen and (max-width: 991.98px) {
  .page__en header .dropdownNav {
    right: 85px;
    left: auto; } }

@media screen and (max-width: 991.98px) {
  .page__en header .dropdownNav {
    padding-right: 0 !important; } }

@media screen and (max-width: 991.98px) {
  .page__en header .dropdownNav .dropdown-menu {
    right: -75px;
    left: unset !important; } }

.page__en header .dropdownNav .dropdown button#cartDropdown, .page__en header .dropdownNav .dropdown button#profileDropdown {
  transform: scalex(-1); }

.page__en header .dropdownNav .dropdown button .cartHeader__count {
  right: 0.1875rem;
  left: auto !important;
  transform: scalex(-1); }
  @media screen and (max-width: 991.98px) {
    .page__en header .dropdownNav .dropdown button .cartHeader__count {
      right: -1.375rem; } }

.navbar-brand div svg {
  width: 5.3125rem;
  height: 9.625rem; }
  @media (max-width: 991.98px) {
    .navbar-brand div svg {
      width: 3.125rem;
      height: 5.875rem; } }

.withInnerHeader {
  min-height: 100vh; }
  .withInnerHeader header {
    position: static;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 70%, white 100%), url("images/BG.jpg");
    padding-bottom: 6.25rem;
    background-repeat: no-repeat; }
    .withInnerHeader header .headerNav .upperHeader::before {
      background: #fff; }
    .withInnerHeader header .headerNav .upperHeader::after {
      background: #fff; }
    .withInnerHeader header .headerNav .upperHeader__socialMedia, .withInnerHeader header .headerNav .upperHeader__ContactUs {
      background: #fff; }
      .withInnerHeader header .headerNav .upperHeader__socialMedia li a, .withInnerHeader header .headerNav .upperHeader__ContactUs li a {
        color: #000 !important; }
        .withInnerHeader header .headerNav .upperHeader__socialMedia li a span::before, .withInnerHeader header .headerNav .upperHeader__ContactUs li a span::before {
          color: #7c7c7b; }
        .withInnerHeader header .headerNav .upperHeader__socialMedia li a svg:hover, .withInnerHeader header .headerNav .upperHeader__ContactUs li a svg:hover {
          fill: #7c7c7b; }
      .withInnerHeader header .headerNav .upperHeader__socialMedia li.lang button, .withInnerHeader header .headerNav .upperHeader__ContactUs li.lang button {
        color: #7c7c7b !important; }
    .withInnerHeader header .headerNav .mainMenu li.nav-item a {
      color: #fff; }
      .withInnerHeader header .headerNav .mainMenu li.nav-item a:before {
        background: #8a633e;
        opacity: 1; }
    .withInnerHeader header .headerNav .dropdown .dropdown-toggle .icon-user-down:before,
    .withInnerHeader header .headerNav .dropdown .dropdown-toggle .icon-shopping-cart1:before,
    .withInnerHeader header .headerNav a.login {
      color: #7c7c7b; }
  .withInnerHeader .content {
    margin-top: -3.125rem; }
    @media (max-width: 767.98px) {
      .withInnerHeader .content {
        margin-top: 0.5rem; } }

@media screen and (max-width: 991.98px) {
  .navbar-collapse {
    padding-top: 2.75rem; } }

.banner {
  margin-bottom: 1.5625rem; }
  @media (max-width: 767.98px) {
    .banner {
      padding-top: 50px; } }
  .banner .slider {
    top: 0;
    right: 0;
    width: 100%; }
    .banner .slider .carousel-inner .carousel-item {
      -webkit-transition: -webkit-transform 1s ease-in-out !important;
      -o-transition: -o-transform 1s ease-in-out !important;
      transition: transform 1s ease-in-out !important;
      height: 70vh; }
      .banner .slider .carousel-inner .carousel-item img {
        height: 100%;
        width: 100%; }
      .banner .slider .carousel-inner .carousel-item .carousel-caption {
        right: 0;
        text-align: right;
        top: 30%;
        left: auto;
        width: 100%;
        margin-bottom: -2.1875rem; }
        .banner .slider .carousel-inner .carousel-item .carousel-caption span {
          font-size: 1.25rem;
          opacity: 0.8;
          margin-bottom: 1.5625rem;
          display: block; }
        .banner .slider .carousel-inner .carousel-item .carousel-caption h5 {
          font-size: 3.2rem;
          font-weight: normal;
          margin-bottom: 1.75rem;
          min-height: 25px; }
          @media (max-width: 1199.98px) {
            .banner .slider .carousel-inner .carousel-item .carousel-caption h5 {
              font-size: 3.125rem; } }
        .banner .slider .carousel-inner .carousel-item .carousel-caption p {
          font-size: 1.5rem;
          margin-bottom: 5.625rem;
          min-height: 25px; }
        .banner .slider .carousel-inner .carousel-item .carousel-caption .cartBtn {
          background: #7c7c7b;
          color: #ffffff;
          padding: 0.9375rem 4.6875rem;
          font-size: 1.25rem;
          margin-left: 1.25rem; }
          .banner .slider .carousel-inner .carousel-item .carousel-caption .cartBtn span {
            display: inline-block;
            margin-left: 1.125rem; }
        .banner .slider .carousel-inner .carousel-item .carousel-caption .customBtn {
          padding: 0.9375rem 4.6875rem;
          color: #ffffff;
          border: 0.0625rem solid #ffffff; }
          .banner .slider .carousel-inner .carousel-item .carousel-caption .customBtn span {
            display: inline-block;
            margin-left: 1.125rem; }
        .banner .slider .carousel-inner .carousel-item .carousel-caption .button {
          font-size: 1.25rem;
          height: 3.75rem;
          padding: 0 1.875rem;
          background: #fff;
          color: #8a633e !important; }
        @media (max-width: 991.98px) {
          .banner .slider .carousel-inner .carousel-item .carousel-caption .col-12 {
            max-width: 90%; } }
      @media (max-width: 767.98px) {
        .banner .slider .carousel-inner .carousel-item {
          height: 33vh; }
          .banner .slider .carousel-inner .carousel-item .carousel-caption span {
            font-size: 0.9375rem;
            margin-bottom: 0.9375rem; }
          .banner .slider .carousel-inner .carousel-item .carousel-caption h5 {
            font-size: 1rem;
            margin-bottom: 0.875rem;
            min-height: 20px; }
          .banner .slider .carousel-inner .carousel-item .carousel-caption p {
            font-size: 0.875rem;
            margin-bottom: 1.875rem;
            min-height: 20px; }
          .banner .slider .carousel-inner .carousel-item .carousel-caption .cartBtn {
            padding: 0 1;
            font-size: 0.625rem;
            margin-left: 1.25rem; }
          .banner .slider .carousel-inner .carousel-item .carousel-caption .customBtn {
            padding: 0 1; }
            .banner .slider .carousel-inner .carousel-item .carousel-caption .customBtn span {
              margin-left: 0.625rem; }
          .banner .slider .carousel-inner .carousel-item .carousel-caption .button {
            font-size: 0.875rem;
            height: 2.5rem;
            padding: 0 1.25rem; } }
    .banner .slider .carousel-indicators {
      bottom: 55%;
      left: 0;
      right: auto;
      flex-direction: column;
      margin-bottom: -5rem; }
      .banner .slider .carousel-indicators li {
        width: 0.8125rem;
        height: 0.8125rem;
        border-radius: 50%;
        position: relative;
        transform: rotate(45deg);
        margin: 1.25rem 0;
        border: none; }
      .banner .slider .carousel-indicators .active {
        box-shadow: 0px 0px 2px 3px rgba(255, 255, 255, 0.25); }
      .banner .slider .carousel-indicators li::after {
        content: "";
        height: 1.25rem;
        width: 0.0625rem;
        background: rgba(255, 255, 255, 0.25);
        position: absolute;
        top: 0;
        left: 1.5625rem;
        margin-top: 1rem;
        transform: rotate(-45deg); }
      .banner .slider .carousel-indicators li:last-child::after {
        display: none; }
      @media (max-width: 991.98px) {
        .banner .slider .carousel-indicators {
          margin-right: 10%;
          margin-left: 10%; } }
      @media (max-width: 991.98px) {
        .banner .slider .carousel-indicators {
          display: none; } }
  .banner .carousel-control-prev,
  .banner .carousel-control-next {
    z-index: 12;
    width: auto; }
    .banner .carousel-control-prev-icon,
    .banner .carousel-control-next-icon {
      display: block;
      position: relative; }
  .banner .carousel-control-prev-icon::before {
    content: "";
    background: #7c7c7b;
    width: 35px;
    height: 60px;
    z-index: -1;
    position: absolute;
    top: -20px;
    left: 0;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px; }
  .banner .carousel-control-next-icon::before {
    content: "";
    background: #7c7c7b;
    width: 35px;
    height: 60px;
    z-index: -1;
    position: absolute;
    top: -20px;
    right: 0;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px; }

.page__en .banner .slider .carousel-indicators {
  right: 0;
  left: auto; }

.page__en .banner .slider .carousel-caption button::after {
  right: -46px;
  left: unset;
  transform: rotate(180deg); }
  @media (max-width: 991.98px) {
    .page__en .banner .slider .carousel-caption button::after {
      right: -45px; } }

footer {
  background: #7c7c7b;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%; }
  footer .innerFooter {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
    footer .innerFooter .footerLogo {
      width: 7.375rem;
      height: 3.4375rem;
      margin-left: 2.3125rem; }
      footer .innerFooter .footerLogo .cls-1 {
        fill: #b88b1c;
        fill-rule: evenodd; }
      footer .innerFooter .footerLogo img {
        position: absolute;
        right: 50px;
        top: -15px;
        width: 90px; }
    footer .innerFooter .about {
      color: #fff;
      font-size: 0.8125rem; }
      @media (max-width: 767.98px) {
        footer .innerFooter .about {
          text-align: center; } }
    footer .innerFooter .footerList {
      justify-content: center;
      align-items: center; }
      @media (max-width: 767.98px) {
        footer .innerFooter .footerList {
          margin-top: 2rem;
          padding-right: 0;
          justify-content: center; } }
      footer .innerFooter .footerList li {
        margin-right: 0.625rem;
        color: #ffffff; }
        @media (max-width: 767.98px) {
          footer .innerFooter .footerList li:first-of-type {
            margin-right: 0; } }
        footer .innerFooter .footerList li a {
          text-decoration: none;
          height: 2.1875rem;
          width: 2.1875rem;
          display: inline-block;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.875rem; }
          footer .innerFooter .footerList li a svg {
            fill: #fff; }
      footer .innerFooter .footerList li.lang {
        color: #fff;
        padding-right: 2.875rem; }
        footer .innerFooter .footerList li.lang button {
          color: #fff !important;
          padding: 0.5rem 0.875rem;
          margin-right: 0.3125rem;
          border-radius: 0; }
          @media (max-width: 767.98px) {
            footer .innerFooter .footerList li.lang button {
              padding: 0.5rem; } }
          footer .innerFooter .footerList li.lang button::after {
            display: none; }
        @media (max-width: 767.98px) {
          footer .innerFooter .footerList li.lang {
            padding-right: 0; } }
  footer #whatsapp_link {
    position: fixed;
    bottom: 5%;
    right: 5%;
    width: 100px;
    height: 100px;
    display: block; }
  footer .copyright {
    background: #060606;
    padding: 0.625rem; }
    footer .copyright p {
      margin: 0 auto;
      color: #8a633e;
      text-align: center; }

.page__en .innerFooter .footerLogo {
  margin-right: 2.3125rem;
  margin-left: 0 !important; }
  .page__en .innerFooter .footerLogo img {
    right: 0;
    left: 50px; }

.page__en .innerFooter .footerList li {
  margin-left: 0.625rem;
  margin-right: 0 !important; }
  @media (max-width: 767.98px) {
    .page__en .innerFooter .footerList li:first-of-type {
      margin-left: 0; } }
  .page__en .innerFooter .footerList li.lang {
    padding-left: 2.875rem;
    padding-right: 0 !important; }
    @media (max-width: 767.98px) {
      .page__en .innerFooter .footerList li.lang {
        padding-left: 0;
        margin-left: 1.875rem; } }
    .page__en .innerFooter .footerList li.lang button {
      margin-left: 0.3125rem;
      margin-right: 0 !important; }

@media (max-width: 767.98px) {
  .footerAbout {
    flex-direction: column;
    margin-top: 25px; } }

.newsBtn {
  border-radius: 8px;
  cursor: pointer;
  background: #8a633e;
  margin: 20px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 8px !important;
  font-size: 1em;
  color: #fff !important;
  max-width: 200px; }
  @media (max-width: 767.98px) {
    .newsBtn {
      margin: 10px;
      padding: 6px !important;
      font-size: .8em;
      font-size: .8em; } }

.newsSubTitle {
  color: #8a633e; }
  @media (max-width: 767.98px) {
    .newsSubTitle {
      font-size: .8em; } }

.newsTitle {
  color: #7c7c7b; }
  @media (max-width: 767.98px) {
    .newsTitle {
      font-size: 1em; } }

.sideFilterContainer {
  text-align: right; }
  .sideFilterContainer__sideFilter {
    padding-right: 0rem; }
    .sideFilterContainer__sideFilter__mainMenu__Title {
      font-size: 2.8125rem;
      font-weight: bold;
      display: block;
      margin-bottom: 2.5rem; }
      @media screen and (max-width: 500px) {
        .sideFilterContainer__sideFilter__mainMenu__Title {
          font-size: 1.5625rem; } }
    .sideFilterContainer__sideFilter__mainMenu__subMenu {
      padding-right: 0rem;
      position: relative; }
      @media (max-width: 767.98px) {
        .sideFilterContainer__sideFilter__mainMenu__subMenu {
          display: flex;
          flex-flow: row;
          height: 50px;
          width: 100%;
          overflow-x: scroll; } }
      .sideFilterContainer__sideFilter__mainMenu__subMenu__link {
        color: #8e8e8e;
        font-size: 0.9375rem;
        font-weight: normal;
        margin-bottom: 1.25rem;
        margin-left: 1.25rem;
        display: inline-block;
        padding: 0.3125rem 1.5625rem;
        background: #e7e5e5;
        border-radius: 30px; }
        .sideFilterContainer__sideFilter__mainMenu__subMenu__link:hover {
          text-decoration: none; }
      .sideFilterContainer__sideFilter__mainMenu__subMenu__link.active {
        border-radius: 30px;
        background-color: #7c7c7b;
        margin: 0.0625rem;
        padding: 0.9375rem 3.75rem;
        color: white; }
        @media (max-width: 767.98px) {
          .sideFilterContainer__sideFilter__mainMenu__subMenu__link.active {
            padding: 0.9375rem 0.625rem; } }
      .sideFilterContainer__sideFilter__mainMenu__subMenu li {
        display: inline-block;
        margin-bottom: 30px; }
        @media (max-width: 767.98px) {
          .sideFilterContainer__sideFilter__mainMenu__subMenu li {
            margin: 0px 0px 0.625rem 0.625rem !important;
            text-align: center; } }
        .sideFilterContainer__sideFilter__mainMenu__subMenu li a {
          text-align: center;
          margin: 0.0625rem;
          padding: 0.9375rem 3.75rem;
          background: #ffffff;
          color: #8e8e8e;
          font-size: 0.9375rem;
          font-weight: normal;
          margin-bottom: 1.25rem;
          display: inline-block;
          border-radius: 30px; }
          @media (max-width: 767.98px) {
            .sideFilterContainer__sideFilter__mainMenu__subMenu li a {
              margin-bottom: 0;
              display: inline-block;
              width: 100px !important;
              padding: 8px !important;
              border-radius: 15px;
              font-size: .8em; } }
          .sideFilterContainer__sideFilter__mainMenu__subMenu li a:hover {
            text-decoration: none; }
        .sideFilterContainer__sideFilter__mainMenu__subMenu li .active {
          color: #7c7c7b; }
      @media (max-width: 767.98px) {
        .sideFilterContainer__sideFilter__mainMenu__subMenu__link {
          display: flex !important;
          align-items: center;
          justify-content: center;
          font-size: .8em;
          width: 100px; } }

.productListPage .w-100s {
  width: 100%; }

.productListPage .sideFilterContainer__sideFilter__mainMenu {
  background-color: transparent;
  color: #ffffff !important; }

.productListPage .breadcrumb-item {
  color: #ffffff; }

.productListPage .breadcrumb {
  background-color: transparent !important;
  font-size: 0.9375rem; }
  .productListPage .breadcrumb a {
    color: #ffffff; }

.productListPage .productList {
  position: relative; }
  .productListPage .productList .productImg .button {
    padding: 0.625rem; }

.productListPage .bannerWrapper {
  color: #ffffff;
  position: relative; }
  .productListPage .bannerWrapper img {
    min-height: 22.5rem;
    object-fit: cover; }
    @media (max-width: 991.98px) {
      .productListPage .bannerWrapper img {
        min-height: 13.5rem;
        margin-top: 57px; } }
  .productListPage .bannerWrapper__content {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0; }

.productListPage .content {
  margin-top: 0rem;
  padding-top: 5.625rem;
  position: relative; }
  @media (max-width: 767.98px) {
    .productListPage .content {
      padding-top: 0.625rem; } }
  .productListPage .content::after {
    content: "";
    background-image: url("images/newPatern.png");
    height: 16.25rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-position: top center;
    opacity: .6; }

.productListPage .scrollingLoader {
  margin-top: 5.625rem;
  margin-bottom: 15.625rem;
  color: #7c7c7b;
  font-size: 1.25rem;
  padding: 0;
  border: none;
  border-radius: unset;
  background: none;
  text-align: center; }
  .productListPage .scrollingLoader img {
    padding-left: 1.25rem; }

.breadcrumb {
  text-align: right;
  padding: 0 !important;
  background-color: transparent !important; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0 !important; }

.breadcrumb-item + .breadcrumb-item::before {
  padding-left: 0.5rem; }

.breadcrumb-item {
  color: #888888; }
  .breadcrumb-item a {
    color: #888888; }
  .breadcrumb-item.active {
    color: #7c7c7b;
    font-weight: 500; }
  .breadcrumb-item + .breadcrumb-item::before {
    content: "|";
    padding-left: 0.3125rem;
    padding-right: 0.3125rem; }

.productListPage .breadcrumb-item + .breadcrumb-item::before {
  color: #ffffff; }

.product {
  display: flex;
  height: 100%;
  width: 100%; }
  .product * {
    border-radius: 0; }
  .product a:hover {
    text-decoration: none; }
  .product .card {
    width: 100%;
    margin-bottom: 1.875rem; }
    .product .card a {
      width: 100%;
      display: block;
      font-size: 1rem; }
    .product .card .productImg {
      position: relative;
      margin-bottom: 1.25rem; }
      .product .card .productImg .product-img-wrapper {
        position: relative;
        overflow: hidden; }
        .product .card .productImg .product-img-wrapper img.card-img-top {
          display: none;
          width: calc(100% + 1px);
          height: auto; }
        .product .card .productImg .product-img-wrapper img.card-img-top.default {
          display: block; }
        .product .card .productImg .product-img-wrapper .overlayOnHover {
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          background: rgba(0, 0, 0, 0.5);
          padding: 0.625rem;
          height: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          z-index: 2; }
          .product .card .productImg .product-img-wrapper .overlayOnHover a {
            width: calc(100% - 20px);
            height: 3.125rem;
            text-align: center;
            background: #ffffff;
            color: #7c7c7b;
            font-weight: 300;
            font-size: 1.125rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Tajawal";
            border-radius: 30px;
            margin-bottom: 0.3125rem; }
            .product .card .productImg .product-img-wrapper .overlayOnHover a::after {
              display: none; }
          @media (max-width: 767.98px) {
            .product .card .productImg .product-img-wrapper .overlayOnHover a {
              height: 1.875rem;
              font-size: 0.75rem;
              border-radius: 15px; } }
        .product .card .productImg .product-img-wrapper .out-of-stock {
          position: absolute;
          width: 100%;
          height: 40px;
          font-size: 18px;
          line-height: 40px;
          bottom: 15px;
          text-align: center;
          background: rgba(255, 255, 255, 0.8);
          color: #E72113; }
        .product .card .productImg .product-img-wrapper:hover > div.overlayOnHover {
          display: flex; }
      .product .card .productImg .product-img-wrapper.has-out-of-stock-product img.card-img-top {
        opacity: 0.7; }
    .product .card .card-body {
      padding: 0; }
      .product .card .card-body .upperDetails {
        margin-bottom: 0.3125rem;
        height: auto; }
        .product .card .card-body .upperDetails p {
          color: #8e8e8e; }
          .product .card .card-body .upperDetails p .season {
            padding-left: 0.3125rem;
            font-size: 0.75rem;
            color: #8c8c8c; }
            .product .card .card-body .upperDetails p .season::after {
              content: "/";
              padding-right: 0.3125rem; }
          .product .card .card-body .upperDetails p .type {
            font-size: 0.75rem;
            color: #8c8c8c; }
        .product .card .card-body .upperDetails .priceBeforeDiscount {
          color: #c5c5c5;
          text-decoration: line-through;
          font-size: 0.8125rem; }
      .product .card .card-body .titleAndPrice {
        height: auto; }
        .product .card .card-body .titleAndPrice a {
          margin: 0; }
        .product .card .card-body .titleAndPrice .card-title {
          margin: 0 0 0.3125rem 0;
          color: #7c7c7b;
          font-size: 1.125rem;
          font-weight: 300; }
        .product .card .card-body .titleAndPrice .card-text {
          color: #8a633e;
          font-size: 1.125rem;
          font-weight: 300; }
        @media (max-width: 767.98px) {
          .product .card .card-body .titleAndPrice {
            flex-direction: column; }
            .product .card .card-body .titleAndPrice .card-title {
              font-size: 0.875rem; } }
      .product .card .card-body hr {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
        border-top: 1px solid #ebebeb; }
      .product .card .card-body .desc {
        color: #909090;
        font-size: 0.8125rem;
        margin-bottom: 0.9375rem;
        position: relative; }
      .product .card .card-body .colors {
        text-align: right;
        display: block; }
        .product .card .card-body .colors img {
          border-radius: 100%;
          border: none;
          height: 1.3125rem;
          width: 1.3125rem;
          margin-left: 0.3125rem;
          display: inline-block;
          padding: 0; }
    .product .card .commercialCard {
      padding: 0.625rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      background: #e7e5e5; }
      .product .card .commercialCard a {
        width: calc(100% - 46px); }
      .product .card .commercialCard p {
        text-align: center;
        margin-bottom: 1.875rem;
        font-weight: bold; }

.productTags {
  position: absolute;
  left: 0;
  top: 1.875rem;
  z-index: 2;
  padding: 0; }
  .productTags .tagSpan {
    text-align: center;
    background-color: #8a633e;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.3125rem;
    position: relative;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    font-size: 0.68rem;
    min-width: 60px;
    padding: 5px 7px; }
  .productTags .tagSpan.discount {
    background-color: #b95838;
    font-size: 0.9375rem; }
  .productTags .tagSpan.recommended {
    background-color: #47655b; }

.page__en .productTags .tagSpan {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px; }
  @media (max-width: 991.98px) {
    .page__en .productTags .tagSpan::after {
      left: -4px; } }

.page__en .titleAndPrice,
.page__en .upperDetails {
  flex-direction: row-reverse; }

.page__en .productList .titleAndPrice,
.page__en .productList .upperDetails {
  flex-direction: row; }

.page__en .productList .upperDetails span.season {
  padding-left: 0 !important;
  padding-right: 0.3125rem; }

.page__en .productList .upperDetails span.type::before {
  display: inline-block;
  padding-right: 0.3125rem; }

.page__en .productList .upperDetails span.type::after {
  display: none; }

.page__en .product .card .commercialCard {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .page__en .product .card .commercialCard a {
    margin-left: -1.875rem; }
    .page__en .product .card .commercialCard a::after {
      right: -46px;
      left: unset !important;
      transform: rotate(180deg); }
      @media (max-width: 991.98px) {
        .page__en .product .card .commercialCard a::after {
          right: -45px; } }

.page__en .product .card-body .upperDetails span.season {
  padding-left: 0; }
  .page__en .product .card-body .upperDetails span.season::after {
    padding-left: 0.3125rem; }

.page__en .product .card-body .upperDetails span.type::before {
  display: none; }

.page__en .product .card-body .colors {
  text-align: left; }

.homeCategory__products .titleAndPrice.titleAndPriceHome {
  height: 70px !important; }

.homeCategory__products .colors.colorsHome, .homeCategory__products .desc.descHome {
  display: none !important; }

.home {
  /* Special styles for specific categories */ }
  .home .athwabProducts {
    padding-bottom: 5.625rem; }
  .home .athwabProducts .product .card .productImg img.card-img-top,
  .home .recommendedProducts .product .card .productImg img.card-img-top {
    height: auto; }
  @media (max-width: 767.98px) {
    .home .athwabProducts,
    .home .recommendedProducts {
      padding-top: 0.9375rem;
      padding-bottom: 1.875rem; } }
  .home .recommendedProducts {
    padding-top: 5.3125rem;
    padding-bottom: 5.625rem; }
    @media (max-width: 767.98px) {
      .home .recommendedProducts {
        padding-top: 0.9375rem;
        padding-bottom: 1.875rem; } }
  .home .homeSections {
    text-align: right; }
  .home .firstSection .firstSectionInner {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 767.98px) {
      .home .firstSection .firstSectionInner {
        flex-direction: column-reverse; } }
    .home .firstSection .firstSectionInner .rightContent {
      width: 55%;
      padding-right: calc((100% - 1110px) / 2);
      padding-left: 1rem;
      padding-top: 5.625rem; }
      @media (max-width: 1199.98px) {
        .home .firstSection .firstSectionInner .rightContent {
          padding-right: calc((100% - 930px) / 2); } }
      @media (max-width: 991.98px) {
        .home .firstSection .firstSectionInner .rightContent {
          padding-right: calc((100% - 690px) / 2); } }
      @media (max-width: 767.98px) {
        .home .firstSection .firstSectionInner .rightContent {
          padding-right: calc((100% - 510px) / 2);
          width: 100%; } }
      @media (max-width: 575.98px) {
        .home .firstSection .firstSectionInner .rightContent {
          width: 100%;
          padding-right: 15px;
          padding-left: 15px; } }
      .home .firstSection .firstSectionInner .rightContent h2 {
        margin-bottom: 0.3125rem;
        color: #7c7c7b;
        font-size: 2.625rem;
        font-weight: 300;
        margin-bottom: 2.5rem; }
      .home .firstSection .firstSectionInner .rightContent p {
        color: #7c7c7b;
        margin-bottom: 0.625rem;
        font-size: 1.125rem;
        margin-bottom: 3.625rem; }
      .home .firstSection .firstSectionInner .rightContent ul {
        width: 90%;
        list-style: none;
        padding: 0; }
        .home .firstSection .firstSectionInner .rightContent ul li {
          margin-bottom: 1.25rem;
          padding: 1.25rem 1.6875rem;
          display: flex;
          align-items: center;
          background: #f5f5f5;
          border-radius: 30px; }
          .home .firstSection .firstSectionInner .rightContent ul li div h4 {
            font-size: 1.875rem;
            font-weight: 300;
            color: #7c7c7b;
            margin-bottom: 0.9375rem; }
          .home .firstSection .firstSectionInner .rightContent ul li div p {
            margin-bottom: 0.3125rem;
            font-size: 0.8125rem;
            color: #505050; }
          .home .firstSection .firstSectionInner .rightContent ul li span {
            background: #7c7c7b;
            color: #ffffff;
            font-size: 1.5625rem;
            margin-left: 1.875rem;
            display: inline-block;
            width: 2.75rem;
            height: 2.75rem;
            line-height: 44px;
            text-align: center;
            flex-shrink: 0;
            position: relative;
            border-radius: 50%; }
            .home .firstSection .firstSectionInner .rightContent ul li span::after {
              content: "";
              position: absolute;
              top: 58px;
              left: 45%;
              width: 0.3125rem;
              height: 5.3125rem;
              background: url("images/dashed-border.png");
              background-repeat: no-repeat; }
          .home .firstSection .firstSectionInner .rightContent ul li:last-child span::after {
            display: none; }
          @media (max-width: 991.98px) {
            .home .firstSection .firstSectionInner .rightContent ul li {
              align-items: flex-start; } }
      .home .firstSection .firstSectionInner .rightContent button,
      .home .firstSection .firstSectionInner .rightContent a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin-bottom: 5.625rem;
        height: 3.75rem;
        padding: 0 1.875rem;
        border-radius: 30px; }
      @media (max-width: 575.98px) {
        .home .firstSection .firstSectionInner .rightContent {
          padding: 30px 15px !important; }
          .home .firstSection .firstSectionInner .rightContent h2 {
            font-size: 1.625rem;
            font-weight: 500;
            margin-bottom: 1.5rem; }
          .home .firstSection .firstSectionInner .rightContent ul li {
            margin-bottom: 0.25rem;
            padding: 0.25rem 0.6875rem;
            border-radius: 8px; }
            .home .firstSection .firstSectionInner .rightContent ul li div h4 {
              font-size: 1.2rem;
              font-weight: 500;
              margin-bottom: 0.5375rem; }
            .home .firstSection .firstSectionInner .rightContent ul li span {
              font-size: 1rem;
              margin-left: 0.875rem;
              width: 1.75rem;
              height: 1.75rem;
              line-height: 27px; }
              .home .firstSection .firstSectionInner .rightContent ul li span::after {
                top: 30px;
                left: 45%;
                width: 0.3125rem;
                height: 3.3125rem; }
          .home .firstSection .firstSectionInner .rightContent a {
            margin: 1rem !important;
            height: 2.75rem;
            padding: 0 1.875rem; } }
    .home .firstSection .firstSectionInner .leftContent {
      width: 50%;
      align-self: stretch; }
      @media (max-width: 767.98px) {
        .home .firstSection .firstSectionInner .leftContent {
          width: 100%; } }
      .home .firstSection .firstSectionInner .leftContent img {
        height: 100%; }
        @media (max-width: 767.98px) {
          .home .firstSection .firstSectionInner .leftContent img {
            width: 100%;
            height: 45vh !important; } }
  .home .sharedSection {
    position: relative;
    color: #ffffff; }
    .home .sharedSection img {
      min-height: 25rem;
      object-fit: cover; }
    .home .sharedSection__overlay {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.35); }
      .home .sharedSection__overlay h2 {
        color: #ffffff;
        font-size: 4.6875rem;
        margin-bottom: 1.875rem; }
        @media (max-width: 1199.98px) {
          .home .sharedSection__overlay h2 {
            font-size: 3.125rem; } }
        @media (max-width: 575.98px) {
          .home .sharedSection__overlay h2 {
            font-size: 1.25rem; } }
      .home .sharedSection__overlay p {
        font-size: 1.5rem;
        margin-bottom: 4.6875rem;
        max-width: 50%; }
        @media (max-width: 767.98px) {
          .home .sharedSection__overlay p {
            max-width: unset; } }
        @media (max-width: 575.98px) {
          .home .sharedSection__overlay p {
            font-size: 1rem; } }
  .home .sharedSection.secondSection {
    text-align: right; }
  .home .sharedSection.thirdSection {
    text-align: left; }
    .home .sharedSection.thirdSection h2,
    .home .sharedSection.thirdSection p {
      margin-left: 0;
      margin-right: auto;
      width: 100%;
      max-width: unset; }
  .home .fourthSection {
    padding-top: 12.5rem;
    margin-top: -12.5rem;
    background: url("images/Pattern.png");
    background-position-y: bottom;
    background-position-x: center;
    background-size: contain; }
    .home .fourthSection .outterContainer {
      background: #f8f8f8;
      padding-top: 5.9375rem;
      padding-bottom: 3.75rem; }
      @media (max-width: 767.98px) {
        .home .fourthSection .outterContainer {
          width: 100%; } }
      .home .fourthSection .outterContainer .homeSections {
        /* Categories Layout */ }
        .home .fourthSection .outterContainer .homeSections .one-row-column {
          max-height: 700px; }
        .home .fourthSection .outterContainer .homeSections .two-row-column .has-one {
          max-height: 350px; }
        .home .fourthSection .outterContainer .homeSections .two-row-column .has-two .box {
          max-height: 350px; }
        .home .fourthSection .outterContainer .homeSections .categoryBlock {
          cursor: pointer; }
          @media (max-width: 767.98px) {
            .home .fourthSection .outterContainer .homeSections .categoryBlock {
              height: 325px; }
              .home .fourthSection .outterContainer .homeSections .categoryBlock img {
                width: 100%;
                object-fit: cover; } }
          .home .fourthSection .outterContainer .homeSections .categoryBlock .sharedSection__overlay a h3 {
            position: relative;
            z-index: 1; }
            @media (max-width: 1199.98px) {
              .home .fourthSection .outterContainer .homeSections .categoryBlock .sharedSection__overlay a h3 {
                font-size: 2.5rem; } }
            @media (max-width: 991.98px) {
              .home .fourthSection .outterContainer .homeSections .categoryBlock .sharedSection__overlay a h3 {
                font-size: 1.75rem; } }
            @media (max-width: 767.98px) {
              .home .fourthSection .outterContainer .homeSections .categoryBlock .sharedSection__overlay a h3 {
                font-size: 3.4375rem; } }
            .home .fourthSection .outterContainer .homeSections .categoryBlock .sharedSection__overlay a h3::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 1.0625rem;
              background: #7c7c7b;
              bottom: 10px;
              left: 0;
              transition: all 200ms ease;
              z-index: -1;
              opacity: 0; }
          .home .fourthSection .outterContainer .homeSections .categoryBlock:hover .sharedSection__overlay a h3::after {
            opacity: 1; }
        @media (max-width: 767.98px) {
          .home .fourthSection .outterContainer .homeSections .categoryBlock.clothes img {
            object-fit: fill; } }
        .home .fourthSection .outterContainer .homeSections h2 {
          font-size: 3.125rem;
          text-align: center;
          color: #7c7c7b;
          margin-bottom: 1.75rem; }
        .home .fourthSection .outterContainer .homeSections p {
          color: #464646;
          margin-bottom: 3.125rem;
          font-size: 1.25rem;
          text-align: center; }
  .home .categoryBlock {
    margin-bottom: 1.875rem;
    position: relative;
    height: calc(100% - 30px); }
    .home .categoryBlock img {
      width: 100%;
      height: 100%; }
    .home .categoryBlock .sharedSection__overlay {
      height: 100%;
      width: 100%;
      left: 0;
      top: 0; }
      .home .categoryBlock .sharedSection__overlay h3 {
        color: #ffffff;
        font-size: 2.5rem; }
        .home .categoryBlock .sharedSection__overlay h3 a {
          color: #ffffff; }
    .home .categoryBlock a {
      color: #ffffff; }
    @media (max-width: 767.98px) {
      .home .categoryBlock {
        overflow: hidden; } }
  .home .clothesProducts {
    background: url("images/newPatern.png");
    padding-top: 4.6875rem;
    padding-bottom: 5.625rem; }
    .home .clothesProducts .product .card .card-body {
      padding: 0 0.625rem; }
    @media (max-width: 767.98px) {
      .home .clothesProducts {
        padding-top: 1.5625rem;
        padding-bottom: 1.5625rem; } }
  .home .accessoriesProducts {
    background: url("images/newPatern.png");
    padding-top: 4.6875rem;
    padding-bottom: 5.625rem;
    margin-bottom: 0; }
    .home .accessoriesProducts .product .card .card-body {
      padding: 0 0.625rem; }
    @media (max-width: 767.98px) {
      .home .accessoriesProducts {
        padding-top: 1.5625rem;
        padding-bottom: 1.5625rem; } }
  .home .shoesProducts {
    position: relative;
    padding-top: 5.3125rem;
    padding-bottom: 5.625rem; }
  .home .clothesProducts .productImg img,
  .home .accessoriesProducts .productImg img,
  .home .shoesProducts .productImg img {
    height: 200px; }
  .home .accessoriesProducts .card-body,
  .home .shoesProducts .card-body {
    margin-bottom: 1.25rem; }
  .home .recommendedProducts .product .card .productImg img.card-img-top {
    max-height: unset;
    height: 220px; }

.page__en .homeSections.firstSection .rightContent {
  padding-left: calc((100% - 1110px) / 2);
  padding-right: 1rem; }
  @media (max-width: 1199.98px) {
    .page__en .homeSections.firstSection .rightContent {
      padding-right: 0;
      padding-left: calc((100% - 930px) / 2); } }
  @media (max-width: 991.98px) {
    .page__en .homeSections.firstSection .rightContent {
      padding-left: calc((100% - 690px) / 2);
      padding-right: 0; } }
  @media (max-width: 767.98px) {
    .page__en .homeSections.firstSection .rightContent {
      padding-left: calc((100% - 510px) / 2);
      padding-right: 0; } }
  @media (max-width: 575.98px) {
    .page__en .homeSections.firstSection .rightContent {
      padding-right: 15px;
      padding-left: 15px; } }
  .page__en .homeSections.firstSection .rightContent ul li span {
    margin-left: 0 !important;
    margin-right: 1.875rem; }
  .page__en .homeSections.firstSection .rightContent a.button::after {
    right: -46px;
    left: unset !important;
    transform: rotate(180deg); }
    @media (max-width: 991.98px) {
      .page__en .homeSections.firstSection .rightContent a.button::after {
        right: -45px; } }

.page__en .secondSection .sharedSection__overlay,
.page__en .thirdSection .sharedSection__overlay {
  text-align: left; }
  .page__en .secondSection .sharedSection__overlay button,
  .page__en .thirdSection .sharedSection__overlay button {
    margin-left: 0; }
    .page__en .secondSection .sharedSection__overlay button::after,
    .page__en .thirdSection .sharedSection__overlay button::after {
      right: -46px;
      left: unset !important;
      transform: rotate(180deg); }
      @media (max-width: 991.98px) {
        .page__en .secondSection .sharedSection__overlay button::after,
        .page__en .thirdSection .sharedSection__overlay button::after {
          right: -45px; } }

.homeCategory {
  max-width: 100%; }
  .homeCategory__categoryTitle {
    margin-bottom: 0.9375rem;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .homeCategory__categoryTitle span {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 2.5rem;
      color: #1a1516; }
      @media (max-width: 991.98px) {
        .homeCategory__categoryTitle span {
          font-size: 1.375rem; } }
      @media (max-width: 767.98px) {
        .homeCategory__categoryTitle span {
          font-size: 1rem; } }
      .homeCategory__categoryTitle span::before {
        content: "";
        margin-left: 15px;
        margin-top: 10px;
        background-image: none;
        background: #b78b1e;
        border-radius: 50%;
        width: 10px;
        height: 10px; }
    .homeCategory__categoryTitle a {
      position: relative;
      font-size: 1rem;
      color: #7c7c7b;
      padding-bottom: 0.625rem;
      display: inline-block;
      text-decoration: none;
      text-align: left; }
      @media (max-width: 991.98px) {
        .homeCategory__categoryTitle a {
          font-size: 0.75rem; } }
      .homeCategory__categoryTitle a::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 3.375rem;
        height: 0.0625rem;
        background: #7c7c7b;
        transition: all 300ms ease; }
        @media (max-width: 575.98px) {
          .homeCategory__categoryTitle a::after {
            left: 0;
            right: auto; } }
      .homeCategory__categoryTitle a:hover:after {
        width: 100%; }

.page__en .homeCategory__categoryTitle span::before {
  margin-right: 0.9375rem;
  margin-left: unset !important;
  transform: rotate(180deg); }

.page__en .homeCategory__categoryTitle a::after {
  left: 0;
  right: auto; }

.page__en .productSlider .slick-track {
  margin-left: 0;
  margin-right: auto; }

.productSlider {
  direction: ltr; }
  .productSlider .product {
    height: 100%; }
    .productSlider .product .card {
      margin: 0 0.9375rem;
      width: calc(100% - 30px) !important;
      direction: rtl; }
  .productSlider .slick-arrow {
    background: #8a633e;
    width: 2.1875rem;
    height: 3.75rem;
    z-index: 99;
    opacity: 0.7;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 60px;
    height: 60px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: #ffffff;
    border: none;
    outline: none;
    border-radius: 50%;
    line-height: 60px;
    font-size: 15px;
    text-align: center;
    opacity: 0.8; }
    @media (max-width: 600px) {
      .productSlider .slick-arrow {
        width: 1.875rem;
        height: 1.875rem;
        line-height: 30px; } }
    .productSlider .slick-arrow:hover {
      opacity: 1; }
  .productSlider .slick-prev,
  .productSlider .icon-arrow-left {
    left: -95px; }
    @media screen and (max-width: 1346px) {
      .productSlider .slick-prev,
      .productSlider .icon-arrow-left {
        left: 15px; } }
  .productSlider .slick-next,
  .productSlider .icon-arrow-right {
    left: auto;
    right: -95px; }
    @media screen and (max-width: 1346px) {
      .productSlider .slick-next,
      .productSlider .icon-arrow-right {
        right: 15px; } }
  .productSlider .slick-slide {
    height: auto; }
    .productSlider .slick-slide:focus {
      outline: 0; }
    .productSlider .slick-slide div {
      height: 100%;
      width: 100%; }
  .productSlider .slick-track {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: stretch;
    margin-left: auto;
    margin-right: 0; }

.productDetails .productTitle {
  margin-right: 1.25rem;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid #ededed;
  padding-bottom: 2.8125rem;
  align-items: baseline !important; }
  @media (max-width: 991.98px) {
    .productDetails .productTitle {
      margin-right: 0;
      padding-bottom: 5px;
      margin-bottom: 10px; } }
  .productDetails .productTitle h3 {
    margin-bottom: 1.875rem;
    font-size: 2.8125rem;
    color: #7c7c7b; }
    @media (max-width: 991.98px) {
      .productDetails .productTitle h3 {
        font-size: 1.375rem; } }
  .productDetails .productTitle p {
    font-size: 0.875rem;
    color: #787878; }
  .productDetails .productTitle .price p {
    font-size: 2.8125rem;
    color: #8a633e; }
    @media (max-width: 991.98px) {
      .productDetails .productTitle .price p {
        font-size: 1.375rem; } }
    .productDetails .productTitle .price p span {
      font-size: 1.375rem;
      color: #7c7c7b; }
  .productDetails .productTitle .price .priceBeforeDiscount {
    padding: 0 0.9375rem;
    font-size: 2.1875rem;
    text-decoration: line-through;
    color: #b7b7b7; }
    @media (max-width: 991.98px) {
      .productDetails .productTitle .price .priceBeforeDiscount {
        font-size: 1.125rem; } }
    .productDetails .productTitle .price .priceBeforeDiscount span {
      font-size: 2.5rem;
      text-decoration: line-through;
      color: #b7b7b7; }
      @media (max-width: 991.98px) {
        .productDetails .productTitle .price .priceBeforeDiscount span {
          font-size: 1.125rem; } }

.productDetails .fabric-main-img .productImages__main img {
  height: auto; }

.productDetails .orderDetails {
  margin-right: 1.25rem; }
  .productDetails .orderDetails .measurementsCustom,
  .productDetails .orderDetails .colors {
    display: flex;
    align-items: center; }
  .productDetails .orderDetails .measurementsCustom {
    margin-bottom: 2.25rem; }
    .productDetails .orderDetails .measurementsCustom .title {
      color: #7c7c7b;
      font-size: 0.875rem;
      font-weight: 300;
      padding-left: 1.5625rem; }
      .productDetails .orderDetails .measurementsCustom .title span {
        display: block;
        padding-right: 0;
        padding-left: 1.5625rem;
        font-size: 0.75rem;
        font-weight: normal; }
        .productDetails .orderDetails .measurementsCustom .title span a {
          text-decoration: none;
          border-bottom: 1px solid #8a633e; }
    .productDetails .orderDetails .measurementsCustom .contentItem {
      padding: 0.3125rem;
      border: 1px solid #f2f2f2; }
      .productDetails .orderDetails .measurementsCustom .contentItem p {
        width: 1.375rem;
        height: 1.375rem;
        background: #e8e8e8;
        color: #7c7c7b;
        padding: 0;
        text-align: center;
        line-height: 1.375rem; }
        .productDetails .orderDetails .measurementsCustom .contentItem p::selection {
          background: transparent; }
        .productDetails .orderDetails .measurementsCustom .contentItem p:hover {
          background: #8a633e;
          color: #ffffff; }
        .productDetails .orderDetails .measurementsCustom .contentItem p.quantityDisabled:hover {
          background: #e8e8e8;
          color: #7c7c7b;
          cursor: not-allowed; }
      .productDetails .orderDetails .measurementsCustom .contentItem label {
        width: 1.375rem;
        height: 1.375rem;
        line-height: 1.375rem;
        text-align: center;
        padding: 0;
        border: none;
        background: none; }
      .productDetails .orderDetails .measurementsCustom .contentItem div {
        margin-left: 0.625rem;
        padding: 0.9375rem;
        border: 1px solid #f2f2f2;
        text-align: center; }
        .productDetails .orderDetails .measurementsCustom .contentItem div label {
          padding: 0;
          margin: 0;
          display: block; }
        .productDetails .orderDetails .measurementsCustom .contentItem div.item-selected {
          border: 1px solid #8a633e; }
          .productDetails .orderDetails .measurementsCustom .contentItem div.item-selected label {
            color: #8a633e; }
          .productDetails .orderDetails .measurementsCustom .contentItem div.item-selected .tickCircle {
            display: none; }
      .productDetails .orderDetails .measurementsCustom .contentItem.p-0 {
        border: none; }
    @media screen and (max-width: 991.98px) {
      .productDetails .orderDetails .measurementsCustom .dropdownContainer {
        flex-direction: column !important;
        align-items: flex-start !important; } }
    .productDetails .orderDetails .measurementsCustom .dropdownForm {
      width: unset;
      margin-left: 0.625rem; }
      .productDetails .orderDetails .measurementsCustom .dropdownForm .dropdown {
        padding: 0.9375rem 0; }
        .productDetails .orderDetails .measurementsCustom .dropdownForm .dropdown .dropdownHeaderCopy {
          padding: 0 0.9375rem 0 2.1875rem;
          position: relative; }
          .productDetails .orderDetails .measurementsCustom .dropdownForm .dropdown .dropdownHeaderCopy::after {
            content: ">";
            transform: rotate(-90deg);
            display: inline-block;
            position: absolute;
            left: 0.9375rem;
            font-size: 1.125rem; }
    .productDetails .orderDetails .measurementsCustom .radioBtn input {
      margin-left: 0.625rem; }
    .productDetails .orderDetails .measurementsCustom .showMeasurementTable {
      display: block;
      padding-right: 0;
      font-size: 0.75rem;
      font-weight: normal;
      margin-right: 1.25rem;
      color: #767676;
      font-family: "Tajawal"; }
      .productDetails .orderDetails .measurementsCustom .showMeasurementTable a {
        text-decoration: none;
        border-bottom: 1px solid #767676; }
    .productDetails .orderDetails .measurementsCustom.sizeSection {
      margin-bottom: 1.75rem;
      align-items: baseline; }
  .productDetails .orderDetails .colors {
    margin-bottom: 1.875rem; }
    .productDetails .orderDetails .colors label {
      font-family: "Tajawal";
      color: #7c7c7b;
      font-size: 0.875rem;
      font-weight: 300;
      padding-left: 1.5625rem;
      margin-bottom: 0; }
    .productDetails .orderDetails .colors img {
      border-radius: unset;
      margin-right: 0.625rem;
      width: 40px;
      height: 40px;
      padding: 6px;
      border: 1px solid #9e9e9e; }
      .productDetails .orderDetails .colors img.active {
        border: 1px solid #8a633e; }
  .productDetails .orderDetails .costums h4 {
    font-weight: 300;
    font-size: 0.875rem;
    color: #7c7c7b;
    margin-bottom: 1.25rem; }
  .productDetails .orderDetails .costums .customsBlock {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem; }
    @media (max-width: 991.98px) {
      .productDetails .orderDetails .costums .customsBlock {
        flex-direction: column;
        align-items: flex-start; } }
    .productDetails .orderDetails .costums .customsBlock label {
      margin-bottom: 0;
      margin-left: 1.5625rem;
      font-size: 0.75rem;
      font-weight: 300;
      position: relative;
      font-family: "Tajawal";
      display: block;
      padding-right: 15px; }
      @media (max-width: 991.98px) {
        .productDetails .orderDetails .costums .customsBlock label {
          margin-bottom: 0.3125rem;
          padding-right: 0; } }
      .productDetails .orderDetails .costums .customsBlock label::after {
        content: "";
        width: 0.3125rem;
        height: 0.3125rem;
        background: #7c7c7b;
        border-radius: 100%; }
    .productDetails .orderDetails .costums .customsBlock .images .image img {
      padding: 0.25rem;
      border: none; }
      .productDetails .orderDetails .costums .customsBlock .images .image img.active {
        border: 1px solid #8a633e !important; }
    .productDetails .orderDetails .costums .customsBlock .images .image .tickCircle {
      display: none; }

.productDetails__cart {
  display: flex;
  justify-content: center;
  margin-top: 50px; }
  @media (max-width: 767.98px) {
    .productDetails__cart {
      margin-top: 8px; } }
  .productDetails__cart .button {
    font-size: 1.1rem;
    display: inline-flex;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #8a633e;
    font-family: "Tajawal";
    border-radius: 50px;
    box-shadow: 1px 1px 15px 2px #7c7c7b69;
    transition: all 200ms ease; }
    @media (max-width: 767px) {
      .productDetails__cart .button {
        padding: 8px 12px; } }
    .productDetails__cart .button span {
      font-size: 2rem;
      padding-left: 0.5rem;
      vertical-align: text-top; }
    .productDetails__cart .button:hover {
      background: #7c7c7b !important; }

.productDetails .homeCategory {
  margin-bottom: 5.625rem;
  width: 100%; }

.productDetails .measurementsCustom.sizeSection {
  flex-wrap: wrap; }

.productDetails #tabbyPromo {
  justify-content: center;
  padding: 10px;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px; }
  .productDetails #tabbyPromo a {
    margin: 0 3px;
    text-decoration: none;
    color: #47655b; }
  .productDetails #tabbyPromo p {
    margin: 0;
    padding: 0; }

.productImages {
  position: relative; }
  .productImages.isLoading:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 2; }
  .productImages.isLoading:after {
    content: "";
    display: block;
    position: absolute;
    border: 2px rgba(0, 0, 0, 0.5) solid;
    border-right: 0;
    border-radius: 50%;
    z-index: 3;
    width: 3.125rem;
    height: 3.125rem;
    top: calc(50% - 1.5625rem);
    left: calc(50% - 1.5625rem);
    -webkit-animation: spin 0.8s linear infinite;
    animation: spin 0.8s linear infinite; }
  .productImages__main {
    line-height: 0; }
    .productImages__main img {
      min-width: 100%;
      height: auto; }
  .productImages__thumbs {
    display: flex;
    margin: 0 -0.1875rem; }
    .productImages__thumbs > a {
      margin: 0.375rem 0.1875rem;
      border: 1px transparent solid;
      position: relative;
      width: 100%; }
      .productImages__thumbs > a img {
        width: 100%; }
      .productImages__thumbs > a.active::after {
        content: "+";
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
        line-height: 40px;
        background: rgba(255, 255, 255, 0.7);
        color: #ffffff;
        text-align: center;
        font-size: 2.125rem; }

.productShare {
  position: absolute;
  z-index: 2;
  margin: 1.25rem;
  z-index: 999; }
  .productShare .dropdown-menu {
    padding: 0;
    border: 0;
    min-width: 0; }
  .productShare [class*="icon-"]:before {
    color: #7c7c7b;
    font-size: 1rem; }
  .productShare a {
    display: block;
    padding: 0.4375rem 0.625rem;
    background: #ffffff;
    color: #7c7c7b;
    text-decoration: none;
    text-align: center;
    font-size: 1rem; }
  .productShare .dropdown-item {
    text-align: center; }
  .productShare .dropdown .dropdown-toggle:after {
    display: none; }

.policies {
  border-top: 1px solid #eaeaea;
  padding-top: 3.125rem;
  margin-bottom: 4.375rem; }
  .policies .card {
    margin-bottom: 1.5625rem; }
    .policies .card .card-header {
      padding: 0;
      border: none !important; }
      .policies .card .card-header .btn {
        padding: 0;
        color: #7c7c7b;
        font-weight: bold; }
        .policies .card .card-header .btn span {
          color: #adadad;
          display: inline-block;
          transform: rotate(-90deg); }
        .policies .card .card-header .btn.collapsed span {
          transform: rotate(0deg); }
    .policies .card .card-body {
      font-size: 0.875rem;
      padding: 1.25rem 0;
      color: #808080;
      font-family: "Tajawal"; }
    .policies .card .card-title h5 {
      margin-bottom: 0; }

.magnify {
  width: 100%; }
  .magnify img {
    width: 100%; }

.measurementsLink {
  padding: 0.9375rem 0.625rem;
  border: #f2f2f2;
  background: #7c7c7b;
  margin-left: 0.625rem; }
  .measurementsLink a {
    color: #ffffff; }
    @media (min-width: 768px) {
      .measurementsLink a:hover {
        color: #8a633e;
        text-decoration: none; } }

.measurementsCustom,
.colors {
  display: flex;
  align-items: center; }

.measurementsCustom {
  margin-bottom: 2.25rem; }
  .measurementsCustom .title {
    color: #7c7c7b;
    font-size: 0.875rem;
    font-weight: 300;
    padding-left: 1.5625rem; }
    .measurementsCustom .title span {
      display: block;
      padding-right: 0;
      padding-left: 1.5625rem;
      font-size: 0.75rem;
      font-weight: normal; }
      .measurementsCustom .title span a {
        text-decoration: none;
        border-bottom: 1px solid #8a633e; }
  .measurementsCustom .contentItem {
    padding: 0.3125rem;
    border: 1px solid #f2f2f2; }
    .measurementsCustom .contentItem p {
      width: 1.375rem;
      height: 1.375rem;
      background: #e8e8e8;
      color: #7c7c7b;
      padding: 0;
      text-align: center;
      line-height: 1.375rem; }
      .measurementsCustom .contentItem p::selection {
        background: transparent; }
      .measurementsCustom .contentItem p:hover {
        background: #8a633e;
        color: #ffffff; }
      .measurementsCustom .contentItem p.quantityDisabled:hover {
        background: #e8e8e8;
        color: #7c7c7b;
        cursor: not-allowed; }
    .measurementsCustom .contentItem label {
      width: 1.375rem;
      height: 1.375rem;
      line-height: 1.375rem;
      text-align: center;
      padding: 0;
      border: none;
      background: none; }
    .measurementsCustom .contentItem div {
      margin-left: 0.625rem;
      padding: 0.9375rem;
      border: 1px solid #f2f2f2;
      text-align: center; }
      .measurementsCustom .contentItem div label {
        padding: 0;
        margin: 0;
        display: block; }
      .measurementsCustom .contentItem div.item-selected {
        border: 1px solid #8a633e; }
        .measurementsCustom .contentItem div.item-selected label {
          color: #8a633e; }
        .measurementsCustom .contentItem div.item-selected .tickCircle {
          display: none; }
    .measurementsCustom .contentItem.p-0 {
      border: none; }
  @media screen and (max-width: 991.98px) {
    .measurementsCustom .dropdownContainer {
      flex-direction: column !important;
      align-items: flex-start !important; } }
  .measurementsCustom .dropdownForm {
    width: unset;
    margin-left: 0.625rem; }
    .measurementsCustom .dropdownForm .dropdown {
      padding: 0.9375rem 0; }
      .measurementsCustom .dropdownForm .dropdown .dropdownHeaderCopy {
        padding: 0 0.9375rem 0 2.1875rem;
        position: relative; }
        .measurementsCustom .dropdownForm .dropdown .dropdownHeaderCopy::after {
          content: ">";
          transform: rotate(-90deg);
          display: inline-block;
          position: absolute;
          left: 0.9375rem;
          font-size: 1.125rem; }
  .measurementsCustom .radioBtn input {
    margin-left: 0.625rem; }
  .measurementsCustom .showMeasurementTable {
    display: block;
    padding-right: 0;
    font-size: 0.75rem;
    font-weight: normal;
    margin-right: 1.25rem;
    color: #767676;
    font-family: "Tajawal"; }
    .measurementsCustom .showMeasurementTable a {
      text-decoration: none;
      border-bottom: 1px solid #767676; }
  .measurementsCustom.sizeSection {
    margin-bottom: 1.75rem;
    align-items: baseline; }
    .measurementsCustom.sizeSection .errMsg {
      font-size: 0.75rem;
      text-align: right;
      margin-top: 1.5625rem;
      color: #e62323 !important;
      flex-basis: 100%; }

.colors {
  margin-bottom: 1.875rem; }
  .colors label {
    font-family: "Tajawal";
    color: #7c7c7b;
    font-size: 0.875rem;
    font-weight: 300;
    padding-left: 1.5625rem;
    margin-bottom: 0; }
  .colors img {
    border-radius: unset;
    margin-right: 0.625rem;
    padding: 0.25rem;
    border: 1px solid #9e9e9e; }
    .colors img.active {
      border: 1px solid #8a633e; }

.costums h4 {
  font-weight: 300;
  font-size: 0.875rem;
  color: #7c7c7b;
  margin-bottom: 1.25rem; }

.costums .customsBlock {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem; }
  .costums .customsBlock label {
    margin-bottom: 0;
    margin-left: 1.5625rem;
    font-size: 0.75rem;
    font-weight: 300;
    position: relative;
    font-family: "Tajawal";
    display: block;
    padding-right: 15px; }
    .costums .customsBlock label::after {
      content: "";
      width: 0.3125rem;
      height: 0.3125rem;
      background: #7c7c7b;
      border-radius: 100%; }
  .costums .customsBlock .images .image img {
    padding: 0.25rem;
    border: 1px solid #9e9e9e; }
    .costums .customsBlock .images .image img.active {
      border: 1px solid #8a633e; }
  .costums .customsBlock .images .image .tickCircle {
    display: none; }

.page__en .orderDetails .colors label {
  padding-left: 0;
  padding-right: 1.5625rem; }

.page__en .policies .card-header button span {
  transform: rotate(90deg) !important; }

.page__en .productDetails__cart::before {
  left: 0;
  right: auto; }

.page__en .productDetails__cart .button::after {
  right: -70px;
  left: unset !important;
  transform: rotate(180deg); }

.page__en .productDetails__cart .button span {
  padding-right: 1.25rem;
  padding-left: 1rem;
  padding-left: 0 !important; }

.page__en .measurementsCustom .radioBtn input {
  margin-right: 0.625rem; }

.page__en .measurementsCustom .title {
  padding-right: 1.5625rem;
  padding-left: 0 !important; }

.page__en .measurementsCustom .dropdownList {
  max-height: 190px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 200px;
  right: auto;
  left: 0; }

.page__en .measurementsCustom .dropdownForm .dropdownHeaderCopy {
  padding-right: 2.1875rem !important;
  padding-left: 0.9375rem !important; }
  .page__en .measurementsCustom .dropdownForm .dropdownHeaderCopy::after {
    right: 0.9375rem;
    left: unset !important;
    transform: rotate(90deg) !important; }

.page__en .measurementsCustom .showMeasurementTable {
  margin-left: 0.4375rem;
  margin-right: 0; }

.stock-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .stock-options .active {
    border: 1px solid goldenrod !important; }
  .stock-options .stock-item {
    width: 40px;
    height: 40px; }
  .stock-options .Sizes-stock {
    flex-wrap: wrap; }
  .stock-options .colors-stock {
    flex-wrap: wrap; }
  .stock-options .fabrics-stock {
    flex-wrap: wrap; }
    .stock-options .fabrics-stock .stock-item span {
      font-size: 0.9em; }

.stock-alert {
  display: none;
  font-size: .7em; }

.costums h4 {
  margin-bottom: 0.9375rem; }

.costums .customsBlock label {
  display: block;
  margin-bottom: 0.625rem;
  width: 25%; }

.costums .customsBlock .images {
  width: 75%;
  display: flex;
  flex-wrap: wrap; }
  .costums .customsBlock .images .image {
    max-width: 5rem;
    margin-left: 0.625rem;
    margin-bottom: 0.625rem;
    display: inline-block; }
    .costums .customsBlock .images .image img {
      height: auto;
      width: 4.375rem;
      height: 4.375rem;
      object-fit: cover;
      display: block; }
    .costums .customsBlock .images .image .tickCircle {
      transform: translateY(-13px);
      text-align: center;
      margin-bottom: -13px; }
      .costums .customsBlock .images .image .tickCircle .icon-tick {
        color: #fdd4a8;
        border: 1px solid;
        background: #fdd4a8;
        border-radius: 100%; }
        .costums .customsBlock .images .image .tickCircle .icon-tick .path2 {
          margin-left: -8px; }

.colors label {
  display: block;
  margin-bottom: 0.5rem; }

.colors img {
  border: 1px solid #9e9e9e;
  height: 1.375rem;
  width: 1.375rem;
  margin-left: 0.3125rem;
  display: inline-block; }

.colors img.active {
  border: 3px solid #8a633e; }

.aboutPage__whyIthoob {
  padding-top: 6.25rem;
  padding-bottom: 5.625rem;
  position: relative; }
  @media (max-width: 767.98px) {
    .aboutPage__whyIthoob {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem; } }
  .aboutPage__whyIthoob::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: calc(100% - 75px);
    width: 100%;
    background-image: url("images/why-ithoob-bg.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    z-index: -1; }
  .aboutPage__whyIthoob h2 {
    text-align: center;
    font-size: 3.125rem;
    color: #7c7c7b;
    margin-bottom: 3.125rem; }
    @media (max-width: 991.98px) {
      .aboutPage__whyIthoob h2 {
        font-size: 2.1875rem; } }
  .aboutPage__whyIthoob__items__item {
    padding-top: 0; }
    .aboutPage__whyIthoob__items__item:nth-child(1) .aboutPage__whyIthoob__items__item__img img {
      right: -9px;
      bottom: -17px; }
    .aboutPage__whyIthoob__items__item:nth-child(2) .aboutPage__whyIthoob__items__item__img img {
      right: -12px;
      bottom: 10px; }
    .aboutPage__whyIthoob__items__item:nth-child(3) .aboutPage__whyIthoob__items__item__img img {
      right: -10px;
      bottom: -10px; }
    .aboutPage__whyIthoob__items__item__img {
      width: 80px;
      height: 80px;
      margin: 0 auto 20px;
      background: white;
      background: linear-gradient(280deg, white 0%, #fcfcfc 100%);
      overflow: hidden;
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 30px; }
      .aboutPage__whyIthoob__items__item__img img {
        position: absolute; }
    .aboutPage__whyIthoob__items__item h3 {
      text-align: center;
      font-size: 1.5625rem;
      font-weight: 300;
      color: #7c7c7b;
      margin-bottom: 1.25rem; }
    .aboutPage__whyIthoob__items__item__content p {
      text-align: center;
      font-size: 0.875rem;
      color: #464646;
      margin-bottom: 3.125rem; }

.aboutPage__aboutIthoob__video {
  margin-bottom: 90px; }

@media screen and (max-width: 991.98px) {
  .aboutPage__aboutIthoob__content {
    flex-flow: column-reverse !important;
    margin-bottom: 5.625rem; } }

.aboutPage__aboutIthoob__content .img img {
  width: 100%; }

.aboutPage__aboutIthoob__content .aboutContent {
  padding-right: 4.375rem;
  padding-left: 2.1875rem; }
  @media (max-width: 991.98px) {
    .aboutPage__aboutIthoob__content .aboutContent {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .aboutPage__aboutIthoob__content .aboutContent h2 {
    padding-bottom: 2.8125rem;
    font-size: 3.125rem;
    font-weight: 300; }
    @media (max-width: 991.98px) {
      .aboutPage__aboutIthoob__content .aboutContent h2 {
        font-size: 2.1875rem;
        padding-bottom: 1.25rem; } }
  .aboutPage__aboutIthoob__content .aboutContent p {
    font-size: 0.9375rem; }

@media screen and (min-width: 992px) {
  .aboutPage__aboutIthoob__content:nth-of-type(even) {
    flex-flow: row-reverse; } }

.aboutPage__aboutIthoob__content:nth-of-type(even) .aboutContent {
  padding-right: 2.1875rem;
  padding-left: 4.375rem; }
  @media (max-width: 991.98px) {
    .aboutPage__aboutIthoob__content:nth-of-type(even) .aboutContent {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.aboutPage__aboutIthoob .privacy_polic .btn {
  background: brown;
  color: #fff; }

.aboutPage header {
  position: absolute;
  background: none; }
  .aboutPage header .upperHeader::before, .aboutPage header .upperHeader::after {
    background: none !important; }
  .aboutPage header .upperHeader .upperHeader__socialMedia {
    background: none; }
  .aboutPage header .upperHeader .upperHeader__ContactUs {
    background: none; }
    .aboutPage header .upperHeader .upperHeader__ContactUs button.button.btn {
      color: #eaeaea !important; }
    .aboutPage header .upperHeader .upperHeader__ContactUs a.nav-link:hover {
      color: #8a633e !important; }
  .aboutPage header .headerNav .stickyNavbar .navbar-collapse .mainMenu li a {
    color: #ffffff; }
  .aboutPage header .headerNav .navbar.fixed .mainMenu li.nav-item a {
    color: #ffffff; }
  .aboutPage header .headerNav .stickyNavbar .navbar-collapse .dropdownNav .dropdown button span::before {
    color: #ffffff; }
  .aboutPage header .headerNav .stickyNavbar .navbar-collapse .login {
    color: #ffffff; }
  .aboutPage header .headerNav .navbar.fixed .dropdownNav .dropdown button span::before {
    color: #ffffff; }
  @media screen and (max-width: 991.98px) {
    .aboutPage header .headerNav .stickyNavbar .navbar-collapse .mainMenu li a {
      color: #7c7c7b; } }

.page__en .aboutPage__aboutIthoob__content .aboutContent {
  padding-left: 4.375rem;
  padding-right: 2.1875rem; }
  @media (max-width: 991.98px) {
    .page__en .aboutPage__aboutIthoob__content .aboutContent {
      padding-right: 0.9375rem;
      padding-left: 1.25rem; } }

@media screen and (min-width: 992px) {
  .page__en .aboutPage__aboutIthoob__content:nth-of-type(even) {
    flex-flow: row-reverse; } }

.page__en .aboutPage__aboutIthoob__content:nth-of-type(even) .aboutContent {
  padding-left: 2.1875rem;
  padding-right: 4.375rem; }
  @media (max-width: 991.98px) {
    .page__en .aboutPage__aboutIthoob__content:nth-of-type(even) .aboutContent {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.customizations {
  height: 100%;
  min-height: 100%;
  background-color: #ffffff !important;
  background-image: url("images/customizations-bg.png") !important;
  background-repeat-x: no-repeat !important;
  background-position: right top;
  height: 100%; }
  @media (max-width: 991.98px) {
    .customizations {
      background-image: unset !important; } }
  .customizations .customsPage {
    margin-left: 0;
    margin-right: 0;
    background-color: #f8f8f8; }
    .customizations .customsPage .sidebarContainer {
      padding-right: 0;
      padding-left: 1.875rem;
      background-color: #f8f8f8; }
      @media (max-width: 991.98px) {
        .customizations .customsPage .sidebarContainer {
          padding-left: 0; } }
      .customizations .customsPage .sidebarContainer .sideBar {
        background-color: #f8f8f8;
        position: relative;
        z-index: 99; }
        .customizations .customsPage .sidebarContainer .sideBar:before {
          position: absolute;
          content: "";
          width: 20px;
          left: -20px;
          color: #f8f8f8; }
        .customizations .customsPage .sidebarContainer .sideBar .productImages__main {
          height: auto !important;
          max-height: none; }
          .customizations .customsPage .sidebarContainer .sideBar .productImages__main img {
            height: auto; }
    .customizations .customsPage .customsContainer {
      padding-left: 0;
      padding-right: 0;
      position: relative;
      height: 100%; }
      .customizations .customsPage .customsContainer #accordion {
        box-shadow: 171px 11px 0px #f8f8f8;
        border: 1px solid #ededed;
        border-top: 0;
        z-index: -1;
        background: #f8f8f8;
        padding-bottom: 6.875rem; }

.customizationsContent {
  border-right: 1px solid #ededed;
  background-color: #f8f8f8;
  height: 100vh; }
  .customizationsContent .fabricChildrens .firstChild .subShape,
  .customizationsContent .fabricChildrens .firstChild .items,
  .customizationsContent .fabricChildrens .firstChild .subitems,
  .customizationsContent .fabricChildrens .secondChild .subShape,
  .customizationsContent .fabricChildrens .secondChild .items,
  .customizationsContent .fabricChildrens .secondChild .subitems,
  .customizationsContent .fabricChildrens .thirdChild .subShape,
  .customizationsContent .fabricChildrens .thirdChild .items,
  .customizationsContent .fabricChildrens .thirdChild .subitems,
  .customizationsContent .fabricChildrens .secondChild__Sub .subShape,
  .customizationsContent .fabricChildrens .secondChild__Sub .items,
  .customizationsContent .fabricChildrens .secondChild__Sub .subitems {
    display: none; }
  .customizationsContent .fabricChildrens .firstChild p,
  .customizationsContent .fabricChildrens .secondChild p,
  .customizationsContent .fabricChildrens .thirdChild p,
  .customizationsContent .fabricChildrens .secondChild__Sub p {
    margin-top: -0.625rem; }
    .customizationsContent .fabricChildrens .firstChild p.tick,
    .customizationsContent .fabricChildrens .secondChild p.tick,
    .customizationsContent .fabricChildrens .thirdChild p.tick,
    .customizationsContent .fabricChildrens .secondChild__Sub p.tick {
      position: relative;
      z-index: 999;
      margin-top: -1.5rem; }
    .customizationsContent .fabricChildrens .firstChild p.title,
    .customizationsContent .fabricChildrens .secondChild p.title,
    .customizationsContent .fabricChildrens .thirdChild p.title,
    .customizationsContent .fabricChildrens .secondChild__Sub p.title {
      padding: 0.625rem 0;
      position: relative;
      margin-right: 0.9375rem; }
      .customizationsContent .fabricChildrens .firstChild p.title::after,
      .customizationsContent .fabricChildrens .secondChild p.title::after,
      .customizationsContent .fabricChildrens .thirdChild p.title::after,
      .customizationsContent .fabricChildrens .secondChild__Sub p.title::after {
        content: "";
        width: 0.3125rem;
        height: 0.3125rem;
        border-radius: 100%;
        background: #bbb39b;
        position: absolute;
        right: 0;
        top: 1.25rem;
        margin-right: -0.875rem; }
      .customizationsContent .fabricChildrens .firstChild p.title span,
      .customizationsContent .fabricChildrens .secondChild p.title span,
      .customizationsContent .fabricChildrens .thirdChild p.title span,
      .customizationsContent .fabricChildrens .secondChild__Sub p.title span {
        margin-right: 0.3125rem; }
        .customizationsContent .fabricChildrens .firstChild p.title span.moreinfo,
        .customizationsContent .fabricChildrens .secondChild p.title span.moreinfo,
        .customizationsContent .fabricChildrens .thirdChild p.title span.moreinfo,
        .customizationsContent .fabricChildrens .secondChild__Sub p.title span.moreinfo {
          padding: 0.125rem 0.3125rem;
          cursor: pointer;
          border-radius: 10px;
          border: 1px solid #ededed; }
  .customizationsContent .fabricChildrens .secondChild .images,
  .customizationsContent .fabricChildrens .thirdChild .images,
  .customizationsContent .fabricChildrens .secondChild__Sub .images {
    margin-right: 1.875rem; }
    @media (max-width: 991.98px) {
      .customizationsContent .fabricChildrens .secondChild .images,
      .customizationsContent .fabricChildrens .thirdChild .images,
      .customizationsContent .fabricChildrens .secondChild__Sub .images {
        margin-right: 0.9375rem; } }
  .customizationsContent .fabricChildrens .secondChild p.title,
  .customizationsContent .fabricChildrens .thirdChild p.title,
  .customizationsContent .fabricChildrens .secondChild__Sub p.title {
    margin-right: 1.875rem; }
    @media (max-width: 991.98px) {
      .customizationsContent .fabricChildrens .secondChild p.title,
      .customizationsContent .fabricChildrens .thirdChild p.title,
      .customizationsContent .fabricChildrens .secondChild__Sub p.title {
        margin-right: 0.9375rem; } }
    .customizationsContent .fabricChildrens .secondChild p.title::after,
    .customizationsContent .fabricChildrens .thirdChild p.title::after,
    .customizationsContent .fabricChildrens .secondChild__Sub p.title::after {
      content: none; }
  .customizationsContent__actionBar {
    font-size: 1.5625rem;
    padding: 3rem 0 3.375rem;
    line-height: 30px;
    background: #ffffff;
    border-right: 3px solid #ededed;
    border-left: 3px solid #ededed; }
    @media (max-width: 991.98px) {
      .customizationsContent__actionBar {
        border-right: 0; } }
    .customizationsContent__actionBar ul {
      align-items: center;
      padding: 0; }
      .customizationsContent__actionBar ul li {
        list-style: none; }
        .customizationsContent__actionBar ul li a {
          text-decoration: none;
          margin-right: 0.625rem;
          color: #7c7c7b; }
        .customizationsContent__actionBar ul li span {
          font-size: 1.0625rem; }
          .customizationsContent__actionBar ul li span.icon-close {
            font-size: 0.875rem; }
          .customizationsContent__actionBar ul li span.icon-redo, .customizationsContent__actionBar ul li span.icon-redo1, .customizationsContent__actionBar ul li span.icon-undo, .customizationsContent__actionBar ul li span.icon-undo1 {
            cursor: pointer;
            color: #dcdcdc;
            margin-right: 1.25rem;
            font-size: 1.25rem; }
            .customizationsContent__actionBar ul li span.icon-redo.active, .customizationsContent__actionBar ul li span.icon-redo1.active, .customizationsContent__actionBar ul li span.icon-undo.active, .customizationsContent__actionBar ul li span.icon-undo1.active {
              color: #7c7c7b; }
        .customizationsContent__actionBar ul li span,
        .customizationsContent__actionBar ul li a {
          vertical-align: middle; }
        .customizationsContent__actionBar ul li .close {
          padding-right: 1.875rem;
          padding-left: 1.875rem;
          margin-right: 1.875rem;
          border-right: 1px solid #ecebe6;
          font-size: 1.75rem;
          color: #7c7c7b;
          opacity: 1;
          cursor: pointer; }
  .customizationsContent .other-options {
    position: relative; }
    .customizationsContent .other-options .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0%;
      left: 0;
      background: #ffffff99;
      opacity: 1;
      z-index: 1; }
      .customizationsContent .other-options .overlay .not-available {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 10px;
        background: #151515;
        color: #ffffff; }
  .customizationsContent .card {
    border: 0;
    border-radius: 0;
    background-color: #ffffff;
    width: 100%; }
    .customizationsContent .card .types,
    .customizationsContent .card .images {
      list-style-type: none;
      padding: 0;
      direction: rtl; }
      .customizationsContent .card .types .contentItem,
      .customizationsContent .card .images .contentItem {
        width: auto !important;
        position: relative;
        margin-right: 1px;
        margin-left: 1.25rem; }
        .customizationsContent .card .types .contentItem label *,
        .customizationsContent .card .types .contentItem .image *,
        .customizationsContent .card .images .contentItem label *,
        .customizationsContent .card .images .contentItem .image * {
          cursor: pointer; }
        .customizationsContent .card .types .contentItem label__with__desc,
        .customizationsContent .card .types .contentItem .image__with__desc,
        .customizationsContent .card .images .contentItem label__with__desc,
        .customizationsContent .card .images .contentItem .image__with__desc {
          min-width: 19.8125rem;
          background: #ffffff;
          border: 1px solid #ededed; }
          @media (max-width: 991.98px) {
            .customizationsContent .card .types .contentItem label__with__desc,
            .customizationsContent .card .types .contentItem .image__with__desc,
            .customizationsContent .card .images .contentItem label__with__desc,
            .customizationsContent .card .images .contentItem .image__with__desc {
              min-width: font-remm(272); } }
          .customizationsContent .card .types .contentItem label__with__desc .image__img,
          .customizationsContent .card .types .contentItem .image__with__desc .image__img,
          .customizationsContent .card .images .contentItem label__with__desc .image__img,
          .customizationsContent .card .images .contentItem .image__with__desc .image__img {
            background: #ffffff; }
          .customizationsContent .card .types .contentItem label__with__desc img,
          .customizationsContent .card .types .contentItem .image__with__desc img,
          .customizationsContent .card .images .contentItem label__with__desc img,
          .customizationsContent .card .images .contentItem .image__with__desc img {
            border: 0;
            margin: 0 auto; }
        .customizationsContent .card .types .contentItem p,
        .customizationsContent .card .images .contentItem p {
          visibility: hidden; }
          .customizationsContent .card .types .contentItem p span.icon-selected,
          .customizationsContent .card .images .contentItem p span.icon-selected {
            position: absolute;
            left: auto;
            right: auto;
            bottom: 12px; }
        .customizationsContent .card .types .contentItem.item .icon-selected,
        .customizationsContent .card .images .contentItem.item .icon-selected {
          bottom: -7px; }
        .customizationsContent .card .types .contentItem:hover .image__img,
        .customizationsContent .card .images .contentItem:hover .image__img {
          cursor: pointer; }
        .customizationsContent .card .types .contentItem:hover .image__desc,
        .customizationsContent .card .images .contentItem:hover .image__desc {
          cursor: initial; }
        .customizationsContent .card .types .contentItem.hoverEffect,
        .customizationsContent .card .images .contentItem.hoverEffect {
          height: 8.125rem !important; }
          .customizationsContent .card .types .contentItem.hoverEffect .image,
          .customizationsContent .card .images .contentItem.hoverEffect .image {
            height: 100%; }
            .customizationsContent .card .types .contentItem.hoverEffect .image__img,
            .customizationsContent .card .images .contentItem.hoverEffect .image__img {
              height: 100%; }
              .customizationsContent .card .types .contentItem.hoverEffect .image__img img,
              .customizationsContent .card .images .contentItem.hoverEffect .image__img img {
                height: 100%; }
          .customizationsContent .card .types .contentItem.hoverEffect:hover .image,
          .customizationsContent .card .images .contentItem.hoverEffect:hover .image {
            border: 1px solid #ededed;
            background-color: #fcfcfc; }
            .customizationsContent .card .types .contentItem.hoverEffect:hover .image *,
            .customizationsContent .card .images .contentItem.hoverEffect:hover .image * {
              cursor: pointer; }
            .customizationsContent .card .types .contentItem.hoverEffect:hover .image__desc,
            .customizationsContent .card .images .contentItem.hoverEffect:hover .image__desc {
              max-width: 100%;
              left: 0;
              top: 30px;
              width: 100%;
              height: calc(100% - 30px);
              position: absolute;
              background: #fcfcfc; }
              .customizationsContent .card .types .contentItem.hoverEffect:hover .image__desc .text,
              .customizationsContent .card .images .contentItem.hoverEffect:hover .image__desc .text {
                top: 10px;
                bottom: auto;
                padding: 0 0.625rem;
                background: #fcfcfc; }
                .customizationsContent .card .types .contentItem.hoverEffect:hover .image__desc .text .desc,
                .customizationsContent .card .types .contentItem.hoverEffect:hover .image__desc .text .cost,
                .customizationsContent .card .images .contentItem.hoverEffect:hover .image__desc .text .desc,
                .customizationsContent .card .images .contentItem.hoverEffect:hover .image__desc .text .cost {
                  font-family: "Tajawal";
                  font-size: 0.875rem; }
              .customizationsContent .card .types .contentItem.hoverEffect:hover .image__desc .description,
              .customizationsContent .card .images .contentItem.hoverEffect:hover .image__desc .description {
                display: block !important;
                bottom: 15px; }
                .customizationsContent .card .types .contentItem.hoverEffect:hover .image__desc .description .desc,
                .customizationsContent .card .images .contentItem.hoverEffect:hover .image__desc .description .desc {
                  font-family: "Tajawal";
                  font-size: 0.75rem;
                  color: #7b7765; }
        .customizationsContent .card .types .contentItem.hoverEffect.item-selected .image,
        .customizationsContent .card .images .contentItem.hoverEffect.item-selected .image {
          border: 1px solid #8a633e; }
          .customizationsContent .card .types .contentItem.hoverEffect.item-selected .image img,
          .customizationsContent .card .images .contentItem.hoverEffect.item-selected .image img {
            border: none !important;
            border: 0px solid transparent; }
        .customizationsContent .card .types .contentItem.hoverEffect.item-selected .text,
        .customizationsContent .card .images .contentItem.hoverEffect.item-selected .text {
          width: 100%;
          left: 0; }
        .customizationsContent .card .types .contentItem .image,
        .customizationsContent .card .images .contentItem .image {
          z-index: 1;
          border: 1px solid transparent;
          overflow: hidden;
          margin-bottom: 0.9375rem;
          position: relative; }
          .customizationsContent .card .types .contentItem .image__img,
          .customizationsContent .card .images .contentItem .image__img {
            max-width: 100%;
            min-width: 100%; }
          .customizationsContent .card .types .contentItem .image__recommend,
          .customizationsContent .card .images .contentItem .image__recommend {
            position: absolute;
            left: 0.625rem;
            top: 0.625rem;
            cursor: initial;
            text-align: center;
            background-color: #7c7c7b;
            color: #ffffff;
            width: 100%;
            width: 3.125rem;
            height: 3.125rem;
            border-radius: 100%;
            font-size: 0.75rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.3125rem; }
          .customizationsContent .card .types .contentItem .image__desc,
          .customizationsContent .card .images .contentItem .image__desc {
            position: relative; }
            .customizationsContent .card .types .contentItem .image__desc .text,
            .customizationsContent .card .images .contentItem .image__desc .text {
              position: absolute;
              top: auto;
              bottom: 0;
              left: 0;
              width: 100%;
              padding: 10px;
              background: #ffffff; }
              .customizationsContent .card .types .contentItem .image__desc .text .desc,
              .customizationsContent .card .images .contentItem .image__desc .text .desc {
                font-family: "Tajawal";
                font-size: 0.9375rem;
                margin: 0 5px; }
            .customizationsContent .card .types .contentItem .image__desc .description,
            .customizationsContent .card .images .contentItem .image__desc .description {
              display: none !important;
              position: absolute;
              right: 10px;
              left: 10px;
              font-family: "Tajawal";
              font-size: 0.9375rem; }
              .customizationsContent .card .types .contentItem .image__desc .description .desc,
              .customizationsContent .card .images .contentItem .image__desc .description .desc {
                font-size: 0.9375rem; }
          .customizationsContent .card .types .contentItem .image__no-desc,
          .customizationsContent .card .images .contentItem .image__no-desc {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 10px;
            background: #ffffff; }
        .customizationsContent .card .types .contentItem img,
        .customizationsContent .card .images .contentItem img {
          border: 1px solid #ededed;
          cursor: pointer; }
      .customizationsContent .card .types label,
      .customizationsContent .card .images label {
        border: 1px solid #ededed;
        padding: 0.9375rem;
        margin-bottom: 0;
        background: #ffffff;
        cursor: pointer; }
      .customizationsContent .card .types .contentItem.item-selected label,
      .customizationsContent .card .images .contentItem.item-selected label {
        background: #fcfcfc;
        border: 1px solid #8a633e; }
      .customizationsContent .card .types .contentItem.item-selected .tickCircle .icon-tick .path1::before,
      .customizationsContent .card .images .contentItem.item-selected .tickCircle .icon-tick .path1::before {
        color: #8a633e !important; }
    .customizationsContent .card .card-header {
      border-right: 3px solid #ededed !important;
      cursor: pointer; }
      .customizationsContent .card .card-header-items {
        height: 3.125rem;
        overflow: hidden;
        align-items: center;
        margin-right: 1.5625rem;
        margin-left: auto; }
        @media (min-width: 992px) and (max-width: 1199.98px) {
          .customizationsContent .card .card-header-items {
            margin-right: 0.3125rem; } }
        @media (max-width: 991.98px) {
          .customizationsContent .card .card-header-items {
            margin-right: 0.3125rem; } }
        @media (max-width: 991.98px) {
          .customizationsContent .card .card-header-items > div {
            display: none; }
            .customizationsContent .card .card-header-items > div:first-child {
              display: block; } }
        .customizationsContent .card .card-header-items img {
          margin-right: 0.1875rem;
          max-width: none;
          width: 85px;
          height: 55px;
          cursor: default; }
        .customizationsContent .card .card-header-items p.moreItems {
          cursor: pointer;
          font-weight: bold; }
        @media (max-width: 991.98px) {
          .customizationsContent .card .card-header-items p.mr-3.ml-3 {
            margin-right: 0.5rem !important;
            margin-left: 0.5rem !important; } }
      .customizationsContent .card .card-header h5 button.btn {
        padding: 0;
        border: none; }
        .customizationsContent .card .card-header h5 button.btn .collapserTitle .icon-arrow {
          color: #8a633e !important;
          transform: rotate(90deg);
          display: inline-block; }
          .customizationsContent .card .card-header h5 button.btn .collapserTitle .icon-arrow::before {
            color: #8a633e !important; }
        @media (max-width: 991.98px) {
          .customizationsContent .card .card-header h5 button.btn .collapserTitle {
            width: max-content; } }
      .customizationsContent .card .card-header h5 label {
        border: 1px solid #ededed;
        padding: 0.9375rem;
        margin-bottom: 0;
        font-size: 1rem;
        background-color: #fcfcfc; }
    .customizationsContent .card .card-body .notLoggedIn {
      display: none; }
      .customizationsContent .card .card-body .notLoggedIn span {
        text-decoration: underline;
        cursor: pointer;
        color: blue; }
    .customizationsContent .card .card-body .notes label,
    .customizationsContent .card .card-body .notes textarea {
      display: block;
      width: 100%; }
    .customizationsContent .card .card-body .notes__input {
      position: relative; }
      .customizationsContent .card .card-body .notes__input p {
        position: absolute;
        left: 0.3125rem;
        top: 0.9375rem;
        cursor: pointer; }
    .customizationsContent .card .card-body .attachments {
      display: none; }
    .customizationsContent .card .card-body .attachmentsList p span {
      color: #e62323;
      cursor: pointer; }
  .customizationsContent .card.active .card-header {
    background-color: #ffffff !important; }
    .customizationsContent .card.active .card-header h5 button.btn .collapserTitle .icon-arrow {
      color: #bbb39b !important;
      transform: rotate(0deg);
      display: inline-block; }
      .customizationsContent .card.active .card-header h5 button.btn .collapserTitle .icon-arrow::before {
        color: #bbb39b !important; }
    .customizationsContent .card.active .card-header h5 label {
      border: 1px solid #ededed;
      padding: 0.9375rem;
      margin-bottom: 0;
      font-size: 1rem;
      background-color: #fcfcfc; }
  .customizationsContent .imagespopUp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(242, 242, 242, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 99999; }
    .customizationsContent .imagespopUp__imagesContent {
      width: 50%;
      margin: 0 auto;
      background-color: #ffffff;
      padding: 2.5rem;
      display: flex;
      align-items: start; }
      .customizationsContent .imagespopUp__imagesContent.closeIcon {
        cursor: pointer;
        display: inline-flex !important;
        justify-content: flex-start; }
      @media (max-width: 991.98px) {
        .customizationsContent .imagespopUp__imagesContent {
          width: 98%;
          padding: 1.25rem; } }
      .customizationsContent .imagespopUp__imagesContent__title {
        font-size: 2.1875rem; }
        @media (max-width: 991.98px) {
          .customizationsContent .imagespopUp__imagesContent__title {
            font-size: 1.5625rem; } }
      .customizationsContent .imagespopUp__imagesContent__cost {
        font-size: 1.5625rem; }
        @media (max-width: 991.98px) {
          .customizationsContent .imagespopUp__imagesContent__cost {
            font-size: 1.25rem; } }
      .customizationsContent .imagespopUp__imagesContent p,
      .customizationsContent .imagespopUp__imagesContent img {
        padding: 0.625rem;
        width: 20%; }
        @media (max-width: 991.98px) {
          .customizationsContent .imagespopUp__imagesContent p,
          .customizationsContent .imagespopUp__imagesContent img {
            padding: 0.625rem; } }
  .customizationsContent .sliderArrowTxt {
    position: absolute;
    bottom: -33px;
    left: 135px;
    display: inline-block;
    color: #848380;
    font-weight: bold;
    font-size: 0.875rem; }

.measurementsCustom {
  margin-bottom: 2.25rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .measurementsCustom.sizeSection {
    margin-bottom: 1.75rem;
    align-items: baseline; }
  .measurementsCustom .title {
    color: #7c7c7b;
    font-size: 0.875rem;
    font-weight: 300;
    padding-left: 1.5625rem; }
    .measurementsCustom .title span {
      display: block;
      padding-right: 0;
      padding-left: 1.5625rem;
      font-size: 0.75rem;
      font-weight: normal; }
      .measurementsCustom .title span a {
        text-decoration: none;
        border-bottom: 1px solid #8a633e; }
  .measurementsCustom .showMeasurementTable {
    display: block;
    padding-right: 0;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: normal;
    margin-right: 0.4375rem;
    color: #767676;
    font-family: "Tajawal"; }
    .measurementsCustom .showMeasurementTable a {
      text-decoration: none;
      border-bottom: 1px solid #767676; }
  .measurementsCustom .radioBtn input {
    margin-left: 0.625rem;
    margin-bottom: 0.3125rem;
    cursor: pointer;
    background-color: #ffffff !important; }
  .measurementsCustom .radioBtn label {
    font-size: 0.75rem;
    width: 100%;
    display: block;
    padding: 0.875rem 0;
    color: #333333; }
    .measurementsCustom .radioBtn label span {
      color: #8a8a8a;
      font-size: 0.625rem; }
  .measurementsCustom .contentItem {
    padding: 0.3125rem;
    border: 1px solid #f2f2f2; }
    .measurementsCustom .contentItem label {
      padding: 0.9375rem;
      margin-left: 0.625rem;
      margin-bottom: 0;
      cursor: pointer; }
      .measurementsCustom .contentItem label.quantityValue {
        cursor: default;
        white-space: nowrap;
        border: none;
        padding: 0.3125rem;
        margin-right: 0.625rem; }
    .measurementsCustom .contentItem .item-selected label {
      background: #fcfcfc;
      color: #8a633e; }
    .measurementsCustom .contentItem p {
      cursor: pointer;
      padding: 0.625rem; }
      .measurementsCustom .contentItem p.inc {
        width: 1.375rem;
        height: 1.375rem;
        background: #e8e8e8;
        color: #7c7c7b;
        padding: 0;
        text-align: center;
        line-height: 1.375rem; }
        .measurementsCustom .contentItem p.inc:hover {
          background: #8a633e;
          color: #ffffff; }
        .measurementsCustom .contentItem p.inc.quantityDisabled {
          background: #e8e8e8;
          color: #8a8a8a; }
          .measurementsCustom .contentItem p.inc.quantityDisabled:hover {
            cursor: not-allowed; }
      .measurementsCustom .contentItem p.tickCircle {
        margin-top: -1.25rem;
        visibility: hidden;
        padding-bottom: 0;
        display: none; }
    .measurementsCustom .contentItem.p-0 {
      border: none;
      margin: 0.625rem 0; }
  .measurementsCustom .dropdownForm {
    position: relative;
    display: inline-block;
    width: auto;
    z-index: 99; }
    @media (max-width: 991.98px) {
      .measurementsCustom .dropdownForm {
        width: 100%; } }
    .measurementsCustom .dropdownForm .dropdown {
      white-space: nowrap;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0.625rem 0;
      border: 1px solid #ededed;
      padding: 0.9375rem 0;
      cursor: pointer;
      pointer-events: none;
      width: 100%; }
      .measurementsCustom .dropdownForm .dropdown p {
        color: #333333;
        margin: 0;
        padding: 0 0.625rem;
        pointer-events: auto;
        margin-left: 10px; }
      .measurementsCustom .dropdownForm .dropdown.active {
        background: #ededed;
        border: 1px solid #8a633e; }
        .measurementsCustom .dropdownForm .dropdown.active p {
          color: #7c7c7b;
          margin: 0;
          pointer-events: auto; }
          .measurementsCustom .dropdownForm .dropdown.active p.dropdownHeaderCopy {
            background: #ededed;
            white-space: nowrap; }
  .measurementsCustom .dropdownList {
    display: none;
    position: absolute;
    top: 3.125rem;
    right: 0;
    left: auto;
    width: auto;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ededed;
    max-height: 190px;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 200px; }
    .measurementsCustom .dropdownList p {
      cursor: pointer;
      margin: 0;
      padding: 0.3125rem 0.625rem;
      color: #7c7c7b;
      border-bottom: 1px solid #ededed; }
      .measurementsCustom .dropdownList p a {
        color: #333333; }
        .measurementsCustom .dropdownList p a:hover {
          color: #7c7c7b; }
      .measurementsCustom .dropdownList p.uncomplete {
        white-space: normal;
        font-size: 0.5rem;
        color: #333333;
        padding: 0 0.3125rem; }
    .measurementsCustom .dropdownList__item {
      white-space: nowrap;
      padding: 0.3125rem 0;
      border: 1px solid #ededed;
      background-color: #ffffff; }
      .measurementsCustom .dropdownList__item:not(:first-of-type) {
        border-top: 0; }

.requiredNote [class^="icon-"] {
  font-size: 0.75rem;
  margin: 0 0.3125rem; }

.requiredNote__txt {
  display: none;
  font-size: 0.75rem;
  max-width: 116px;
  margin: 0 0.4rem; }

.requiredNote:hover .requiredNote__txt {
  display: inline-block; }

.page__ar .sliderArrowTxt {
  font-family: "Swissra-Normal"; }

.page__en {
  background-position: left top; }

.requestTailorPart {
  margin-top: -275px; }
  @media (max-width: 767.98px) {
    .requestTailorPart {
      margin-top: -170px; } }
  .requestTailorPart .requestTailor_content {
    width: 70% !important;
    margin: 50px auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto; }
    .requestTailorPart .requestTailor_content .signupPopup__content__form {
      background: #fff !important; }
    @media (max-width: 767.98px) {
      .requestTailorPart .requestTailor_content {
        width: 90% !important;
        margin: 50px auto; } }

.sideBar {
  position: fixed;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
  padding-bottom: 3rem; }
  .sideBar .customizationsContent__actionBar {
    background-color: #f8f8f8;
    padding-left: 0.9375rem; }
  @media (min-width: 576px) {
    .sideBar {
      width: calc(191.998px - 50px); } }
  @media (min-width: 768px) {
    .sideBar {
      width: calc(255.997px - 50px); } }
  @media (min-width: 992px) {
    .sideBar {
      width: calc(330.66336px - 50px); } }
  @media (min-width: 1200px) {
    .sideBar {
      width: calc( 399.996px - 50px); } }
  @media (max-width: 767.98px) {
    .sideBar {
      position: relative;
      width: 100%; } }
  .sideBar__header {
    text-align: right;
    font-family: "Tajawal";
    margin-left: 1.25rem;
    font-size: 1.5625rem;
    padding: 3rem 0 3rem; }
  .sideBar__productImages .warningNote {
    display: none;
    color: #e62323; }
  .sideBar__productImages__productDesc {
    flex-direction: row-reverse; }
    .sideBar__productImages__productDesc.onScroll {
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 99;
      background-color: #ffffff;
      padding: 20px;
      right: 0;
      background-color: #f8f8f8; }
    .sideBar__productImages__productDesc__btn {
      display: inline-flex;
      width: 65%;
      margin-left: 5%; }
      .sideBar__productImages__productDesc__btn p {
        font-size: 0.75rem;
        padding: 0.625rem 0;
        text-align: center; }
      .sideBar__productImages__productDesc__btn button {
        width: 100%;
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
        padding: 0.625rem 1.5625rem;
        border: 0;
        border-radius: 30px;
        background-color: #8a8a8a;
        cursor: auto; }
        @media (max-width: 991.98px) {
          .sideBar__productImages__productDesc__btn button {
            padding: 0.625rem 0.9375rem; } }
        .sideBar__productImages__productDesc__btn button.active {
          background-color: #7c7c7b;
          cursor: pointer; }
        .sideBar__productImages__productDesc__btn button p {
          color: #ffffff;
          font-size: 1.25rem;
          padding: 0; }
          @media (max-width: 991.98px) {
            .sideBar__productImages__productDesc__btn button p {
              font-size: 1rem; } }
        .sideBar__productImages__productDesc__btn button .icon-shopping-cart:before {
          color: #ffffff; }
        .sideBar__productImages__productDesc__btn button span {
          font-size: 30px;
          padding-left: 0.625rem;
          border-left: 1px solid #7c7c7b;
          margin-left: 0.625rem; }
    .sideBar__productImages__productDesc__cost {
      margin-top: 0.625rem;
      margin-bottom: 0.625rem;
      display: inline-flex;
      width: 30%; }
      .sideBar__productImages__productDesc__cost p {
        color: #7c7c7b;
        margin: auto;
        font-size: 1.5625rem;
        font-weight: bold; }
        @media (max-width: 991.98px) {
          .sideBar__productImages__productDesc__cost p {
            font-size: 1.25rem; } }
      .sideBar__productImages__productDesc__cost span {
        padding-right: 0.3125rem;
        color: #7c7c7b;
        margin: auto;
        font-size: 1.5625rem;
        font-weight: bold; }
        @media (max-width: 991.98px) {
          .sideBar__productImages__productDesc__cost span {
            font-size: 1.25rem; } }
  .sideBar__productImages__thumbnials {
    margin-top: 1.25rem; }
    .sideBar__productImages__thumbnials img {
      display: block; }
  .sideBar__productImages__mainImage img {
    width: 100%; }
  .sideBar .productImages__main {
    max-height: 430px;
    overflow: hidden; }
  .sideBar .productImages__thumbs > a.active::after {
    line-height: 56px; }

.customizations.page_en {
  background-position: left top !important; }

.page__en .measurementsCustom .title {
  padding-right: 1.5625rem;
  padding-left: 0; }

.page__en .measurementsCustom .radioBtn input {
  margin-right: 0.625rem; }

.page__en .measurementsCustom .showMeasurementTable {
  margin-left: 0.4375rem;
  margin-right: 0; }

.page__en .measurementsCustom .dropdownForm .dropdownHeaderCopy {
  padding-right: 2.1875rem !important;
  padding-left: 0.9375rem !important; }
  .page__en .measurementsCustom .dropdownForm .dropdownHeaderCopy::after {
    right: 0.9375rem;
    left: unset !important;
    transform: rotate(90deg) !important; }

.page__en .measurementsCustom .dropdownList {
  right: auto;
  left: 0; }

.page__en .customsPage .customsContainer #accordion {
  box-shadow: none; }

.page__en .customsPage .sidebarContainer {
  padding-left: 0;
  padding-right: 1.875rem; }

.page__en .sideBar__header {
  text-align: left;
  margin-right: 1.25rem;
  margin-left: 0; }

.page__en .sideBar__productImages__productDesc__btn {
  margin-right: 5%;
  margin-left: 0; }
  .page__en .sideBar__productImages__productDesc__btn button span {
    border-right: 1px solid #7c7c7b;
    border-left: 0;
    padding-left: 0;
    padding-right: 0.625rem;
    margin-left: 0; }
  .page__en .sideBar__productImages__productDesc__btn button p {
    padding-left: 0.625rem; }

.page__en .customizationsContent__actionBar .close {
  padding-left: 1.875rem;
  padding-right: 1.25rem;
  border-left: 1px solid #ecebe6;
  margin-right: 0 !important;
  border-right: 0; }

.page__en .customizationsContent .collapserTitle span.icon-arrow {
  margin-left: 0;
  margin-right: 0.9375rem;
  transform: rotate(-90deg) !important; }

.page__en .customizationsContent .card .card-header-items {
  margin-right: auto !important;
  margin-left: 2.375rem; }

.page__en .customizationsContent .card .types, .page__en .customizationsContent .card .images {
  direction: ltr !important; }

.page__en .customizationsContent .card-header {
  border-left: 3px solid #ededed !important; }

.page__en .customizationsContent .card-body .custom {
  text-align: left !important; }
  .page__en .customizationsContent .card-body .custom .title::after {
    left: 0;
    margin-left: -10px;
    right: unset; }

.page__en .customizationsContent .card-body .notes__input p {
  right: 5px;
  left: auto !important; }

.page__en .customizationsContent .card.active .collapserTitle span.icon-arrow {
  transform: rotate(0deg) !important; }

.closePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(242, 242, 242, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99999; }
  .closePopup__content {
    width: 33% !important;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 2.8125rem;
    padding-top: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 991.98px) {
      .closePopup__content {
        width: 95%; } }
    .closePopup__content p.discount {
      font-size: 2.1875rem;
      font-weight: 500; }
    .closePopup__content.subMsg {
      padding: 2.8125rem 0; }
      .closePopup__content.subMsg .messagePopup__content__btns {
        width: 100%; }
        .closePopup__content.subMsg .messagePopup__content__btns .Btn,
        .closePopup__content.subMsg .messagePopup__content__btns .oppBtn,
        .closePopup__content.subMsg .messagePopup__content__btns .goOut {
          padding: 0.9375rem; }
          .closePopup__content.subMsg .messagePopup__content__btns .Btn button,
          .closePopup__content.subMsg .messagePopup__content__btns .Btn a,
          .closePopup__content.subMsg .messagePopup__content__btns .oppBtn button,
          .closePopup__content.subMsg .messagePopup__content__btns .oppBtn a,
          .closePopup__content.subMsg .messagePopup__content__btns .goOut button,
          .closePopup__content.subMsg .messagePopup__content__btns .goOut a {
            padding: 0.625rem 1.875rem !important;
            font-size: 0.9375rem; }
        .closePopup__content.subMsg .messagePopup__content__btns .oppBtn button {
          color: #7c7c7b !important;
          background-color: #ffffff !important;
          border: 1px solid #7c7c7b; }
        .closePopup__content.subMsg .messagePopup__content__btns .goOut a {
          background-color: #ffffff !important;
          border: 1px solid #7c7c7b !important;
          color: #7c7c7b !important; }
    .closePopup__content img {
      max-width: 20%; }
    .closePopup__content p {
      padding: 0.9375rem; }
    .closePopup__content h3 {
      font-size: 1.5625rem; }
    .closePopup__content__closeIcon {
      display: inline-flex;
      align-items: flex-start;
      cursor: pointer;
      align-self: flex-end;
      margin: 1.25rem;
      margin-top: 0; }
    .closePopup__content.cancelOrder {
      background: red; }
      .closePopup__content.cancelOrder__cancelTips h5 {
        margin-bottom: 1.25rem; }
      .closePopup__content.cancelOrder__cancelTips h6 {
        font-weight: 600; }
      .closePopup__content.cancelOrder .whiteBg {
        padding: 0.625rem 2.5rem !important; }
    .closePopup__content.orderModify {
      width: 40%;
      margin: 0; }
      .closePopup__content.orderModify img {
        max-width: 100% !important; }
      .closePopup__content.orderModify .messagePopup__content__closeIcon {
        margin-left: 0; }
        .closePopup__content.orderModify .messagePopup__content__closeIcon p {
          font-size: 1.25rem;
          padding-left: 0;
          padding-right: 0; }
          .closePopup__content.orderModify .messagePopup__content__closeIcon p:last-child {
            color: #8e8e8e; }
            .closePopup__content.orderModify .messagePopup__content__closeIcon p:last-child:hover {
              color: #7c7c7b; }
          .closePopup__content.orderModify .messagePopup__content__closeIcon p span {
            color: #8e8e8e;
            font-size: 0.875rem; }
      .closePopup__content.orderModify .orderModify__custom {
        width: 100%;
        border: 1px solid #8e8e8e;
        padding: 0.9375rem; }
        @media (max-width: 991.98px) {
          .closePopup__content.orderModify .orderModify__custom {
            padding: 0.625rem 0.3125rem; } }
        .closePopup__content.orderModify .orderModify__custom__titleimages h3 {
          font-size: 1.25rem;
          font-family: "TheSans-Plain";
          color: #7c7c7b;
          margin-left: 1.25rem; }
        .closePopup__content.orderModify .orderModify__custom__titleimages img {
          margin-right: 0.1875rem;
          max-width: none;
          width: 85px;
          height: 75px;
          border: 1px solid #8e8e8e; }
        .closePopup__content.orderModify .orderModify__custom__titleimages label {
          border: 1px solid #8e8e8e;
          padding: 0.9375rem;
          margin-bottom: 0; }
        .closePopup__content.orderModify .orderModify__custom__price p {
          font-size: 1.125rem;
          font-family: "TheSans-Plain";
          color: #7c7c7b; }
      .closePopup__content.orderModify .customsBlock {
        text-align: right;
        margin-bottom: 1.25rem; }

.orderModify__custom__titleimages {
  height: 3.125rem;
  overflow: hidden;
  align-items: center;
  font-size: small; }
  .orderModify__custom__titleimages div.imageRow img,
  .orderModify__custom__titleimages div.imageRow label {
    margin-right: 0.1875rem;
    max-width: 75px;
    height: 55px; }
    @media (max-width: 991.98px) {
      .orderModify__custom__titleimages div.imageRow img,
      .orderModify__custom__titleimages div.imageRow label {
        font-size: 0.9375rem;
        max-width: 3.125rem;
        margin-bottom: 0.625rem; } }
    .orderModify__custom__titleimages div.imageRow img:first-child,
    .orderModify__custom__titleimages div.imageRow label:first-child {
      margin-right: 0.625rem; }
    .orderModify__custom__titleimages div.imageRow img:last-child,
    .orderModify__custom__titleimages div.imageRow label:last-child {
      margin-left: 0.625rem !important; }
  @media (max-width: 991.98px) {
    .orderModify__custom__titleimages div.imageRow {
      flex-wrap: wrap; } }
  .orderModify__custom__titleimages h3 {
    font-size: 1.375rem; }
    @media (max-width: 991.98px) {
      .orderModify__custom__titleimages h3 {
        font-size: 0.875rem; } }
  @media (max-width: 991.98px) {
    .orderModify__custom__titleimages {
      height: auto; } }
  @media (max-width: 991.98px) {
    .orderModify__custom__titleimages .messagePopup__content__body {
      padding-right: 0.625rem !important;
      padding-left: 0.625rem !important; } }

.page__en .orderModify__custom__titleimages {
  height: 3.125rem;
  overflow: hidden;
  align-items: center; }
  .page__en .orderModify__custom__titleimages div.imageRow img,
  .page__en .orderModify__custom__titleimages div.imageRow label {
    margin-left: 0.1875rem;
    margin-right: unset !important; }
    .page__en .orderModify__custom__titleimages div.imageRow img:first-child,
    .page__en .orderModify__custom__titleimages div.imageRow label:first-child {
      margin-left: 0.9375rem;
      margin-right: unset !important; }
  @media (max-width: 991.98px) {
    .page__en .orderModify__custom__titleimages {
      height: auto; } }

.orderModify__custom {
  padding: 0.9375rem 0.625rem;
  border: 1px solid #ededed; }
  @media (max-width: 991.98px) {
    .orderModify__custom {
      padding: 0.625rem 0.3125rem; } }

.orderModify .messagePopup__content__body {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important; }

.measurementQuide {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(242, 242, 242, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99999; }
  .measurementQuide img {
    width: 50%;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 2.8125rem;
    padding-top: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100% - 2.8125rem); }
    @media (max-width: 991.98px) {
      .measurementQuide img {
        padding: 0;
        width: 90%; } }
  .measurementQuide__closeIcon {
    cursor: pointer;
    display: inline-block; }

.hashwapopUp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(242, 242, 242, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99999; }
  .hashwapopUp__content {
    width: auto;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 2.8125rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .hashwapopUp__content__okIcon {
      margin-top: 1.25rem;
      cursor: pointer;
      display: inline-block;
      border-radius: 5;
      border: 1px solid #ededed;
      padding: 0.3125rem 0.9375rem; }

.faqPage h2.noMatch,
.ordersPage h2.noMatch {
  display: none; }

.faqPage .title,
.ordersPage .title {
  font-size: 2.8125rem;
  font-weight: 300;
  margin-bottom: 3.125rem;
  padding: 0; }

.faqPage h2,
.faqPage h5,
.ordersPage h2,
.ordersPage h5 {
  font-size: 1.5625rem;
  padding: 1.25rem 0 0.625rem; }

.faqPage h3,
.ordersPage h3 {
  font-size: 0.75rem;
  padding-bottom: 1.25rem;
  color: #8e8e8e; }

.faqPage__search,
.ordersPage__search {
  position: relative; }
  .faqPage__search input,
  .ordersPage__search input {
    border: 0;
    border-bottom: 1px dotted #ededed;
    margin-bottom: 3.125rem;
    width: 100%;
    font-size: 0.75rem;
    padding: 0 0 0.4375rem 0; }
    .faqPage__search input::placeholder,
    .ordersPage__search input::placeholder {
      color: #b0b0b0;
      font-size: 0.875rem; }
    .faqPage__search input:focus,
    .ordersPage__search input:focus {
      box-shadow: none;
      outline: none; }
      @media (max-width: 767.98px) {
        .faqPage__search input:focus,
        .ordersPage__search input:focus {
          font-size: 16px; } }
  .faqPage__search .icon-Search,
  .ordersPage__search .icon-Search {
    color: #7c7c7b;
    position: absolute;
    left: 0;
    border-bottom: 5px solid #7c7c7b;
    padding-bottom: 0.625rem;
    width: 1.5625rem;
    text-align: center; }

.faqPage .card,
.ordersPage .card {
  border-bottom: 0;
  background: none; }
  .faqPage .card:last-child,
  .ordersPage .card:last-child {
    margin-bottom: 2.1875rem; }
  .faqPage .card .card-header,
  .ordersPage .card .card-header {
    padding: 0;
    border-bottom: 0 !important; }
    .faqPage .card .card-header h5 button,
    .ordersPage .card .card-header h5 button {
      padding: 0;
      border: none; }
      .faqPage .card .card-header h5 button p .icon-arrow,
      .ordersPage .card .card-header h5 button p .icon-arrow {
        margin: 0; }
      .faqPage .card .card-header h5 button p .question,
      .ordersPage .card .card-header h5 button p .question {
        margin-right: 0.9375rem; }
      .faqPage .card .card-header h5 button.collapsed .icon-arrow,
      .ordersPage .card .card-header h5 button.collapsed .icon-arrow {
        margin: 0;
        transform: rotate(90deg);
        display: inline-block; }
  .faqPage .card .collapse,
  .ordersPage .card .collapse {
    border-right: 1px solid #e4e4e4;
    border-left: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4; }
  .faqPage .card .collapse .card-body, .faqPage .card .show .card-body, .faqPage .card .collapsing .card-body,
  .ordersPage .card .collapse .card-body,
  .ordersPage .card .show .card-body,
  .ordersPage .card .collapsing .card-body {
    padding: 0 !important;
    transition: none; }
    .faqPage .card .collapse .card-body h5, .faqPage .card .show .card-body h5, .faqPage .card .collapsing .card-body h5,
    .ordersPage .card .collapse .card-body h5,
    .ordersPage .card .show .card-body h5,
    .ordersPage .card .collapsing .card-body h5 {
      padding: 1.25rem; }
    .faqPage .card .collapse .card-body .collapserContent, .faqPage .card .show .card-body .collapserContent, .faqPage .card .collapsing .card-body .collapserContent,
    .ordersPage .card .collapse .card-body .collapserContent,
    .ordersPage .card .show .card-body .collapserContent,
    .ordersPage .card .collapsing .card-body .collapserContent {
      padding-right: 2.5rem; }
      .faqPage .card .collapse .card-body .collapserContent span.marker, .faqPage .card .show .card-body .collapserContent span.marker, .faqPage .card .collapsing .card-body .collapserContent span.marker,
      .ordersPage .card .collapse .card-body .collapserContent span.marker,
      .ordersPage .card .show .card-body .collapserContent span.marker,
      .ordersPage .card .collapsing .card-body .collapserContent span.marker {
        background-color: #f2f2f2; }

.faqPage .collapserContent {
  padding-right: 0 !important; }

.page__en .faqPage .card-body .collapserContent {
  padding-right: 0; }

.page__en .faqPage .card.active .collapserTitle span.icon-arrow {
  transform: rotate(0deg) !important; }

.page__en .faqPage .card-header .collapserTitle span.icon-arrow {
  margin-left: 0;
  transform: rotate(-90deg) !important; }

.page__en .faqPage__search .icon-Search {
  right: 0;
  left: unset !important; }

.faqPage .card .collapse {
  border: none; }
  .faqPage .card .collapse .collapserContent {
    color: #6f6f6f;
    padding: 0 !important; }

.faqPage .card.active:last-of-type .show {
  margin-bottom: 25px; }

.faqPage footer {
  margin-top: 1.875rem !important; }

.contactPage * {
  font-family: "Tajawal"; }

.contactPage .title {
  font-size: 2.8125rem;
  font-weight: 300;
  color: #7c7c7b;
  font-family: "Tajawal";
  margin-bottom: 3.125rem;
  padding: 0; }

.contactPage__branchInfo h2 {
  font-size: 1.125rem;
  margin-bottom: 0.75rem; }

.contactPage__branchInfo p {
  font-size: 0.875rem;
  color: #6f6f6f;
  margin-bottom: 1.25rem;
  padding: 0; }

.contactPage__branchInfo__branchName {
  padding-bottom: 1.25rem;
  font-size: 1.5625rem;
  font-weight: bold; }

.contactPage__branchInfo__map {
  margin-bottom: 1.5625rem; }

.contactPage__branchInfo__logos {
  margin-bottom: 5.625rem; }
  .contactPage__branchInfo__logos a {
    text-decoration: none;
    width: 2.1875rem;
    height: 2.1875rem;
    text-align: center;
    background: #7c7c7b;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.9375rem; }
    .contactPage__branchInfo__logos a span::before {
      color: #8a633e;
      font-size: 14px; }
    .contactPage__branchInfo__logos a svg {
      fill: #8a633e; }

.contactPage__contactUs {
  background: #f9f9f9;
  padding: 0 2.5rem 2.5rem;
  margin-bottom: 2.5rem;
  position: relative; }
  @media (max-width: 991.98px) {
    .contactPage__contactUs {
      padding: 0 1.25rem;
      padding-top: 1.25rem; } }
  .contactPage__contactUs::before {
    content: "";
    position: absolute;
    left: 0;
    height: 6.25rem;
    width: 100%;
    top: 0;
    background-image: -moz-linear-gradient(rgba(0, 0, 0, 0), #f9f9f9);
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0), #f9f9f9);
    background-image: -o-linear-gradient(rgba(0, 0, 0, 0), #f9f9f9);
    background-image: -ms-linear-gradient(rgba(0, 0, 0, 0), #f9f9f9);
    background-image: linear-gradient(rgba(0, 0, 0, 0), #f9f9f9);
    transform: translateY(-100%); }
    @media (max-width: 767.98px) {
      .contactPage__contactUs::before {
        display: none; } }
  .contactPage__contactUs h2 {
    font-size: 1.5625rem;
    font-weight: bold;
    margin-bottom: 2.5rem; }
  .contactPage__contactUs__form .invalidInput__name, .contactPage__contactUs__form .invalidInput__email, .contactPage__contactUs__form .invalidInput__mobile, .contactPage__contactUs__form .invalidInput__msg {
    font-size: 0.75rem;
    display: none;
    text-align: right;
    color: #e62323 !important; }
  .contactPage__contactUs__form__btnLink {
    position: relative; }
    .contactPage__contactUs__form__btnLink button {
      width: 100%; }
  .contactPage__contactUs__form__field {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 0.625rem;
    min-height: 130.4px; }
    .contactPage__contactUs__form__field input,
    .contactPage__contactUs__form__field label,
    .contactPage__contactUs__form__field textarea {
      max-width: 100%;
      width: 100%; }
    .contactPage__contactUs__form__field label {
      font-size: 0.6875rem;
      color: #b0b0b0;
      margin-bottom: 0.625rem;
      transform: none;
      transition: all 350ms ease-in-out; }
    .contactPage__contactUs__form__field input,
    .contactPage__contactUs__form__field textarea {
      padding: 0 0 0.625rem;
      border: none;
      border-bottom: 1px solid #cbcbcb;
      background: #f9f9f9;
      margin-bottom: 1.875rem; }
    .contactPage__contactUs__form__field input {
      padding: 0.25rem 0; }
    .contactPage__contactUs__form__field.notFocused label {
      transform: translateY(40px); }
    .contactPage__contactUs__form__field.notFocused.msg label {
      transform: none; }
    .contactPage__contactUs__form__field label.pwTitleForget__forget {
      text-align: left;
      text-decoration: underline; }

.contactPage .loader {
  position: absolute;
  padding: 0;
  left: 0;
  z-index: 9; }
  .contactPage .loader__load {
    border: 3px solid #ffffff;
    border-radius: 50%;
    width: 1.875rem;
    height: 1.875rem;
    border-right: 1px solid #ffffff;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: auto 0; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.page__en .contactPage__contactUs__form .invalidInput__name, .page__en .contactPage__contactUs__form .invalidInput__email, .page__en .contactPage__contactUs__form .invalidInput__mobile, .page__en .contactPage__contactUs__form .invalidInput__msg {
  text-align: left; }

.page__en .contactPage__contactUs__form__btnLink.btnStyle button::after {
  right: -46px;
  left: unset !important;
  background-position: center;
  transform: rotate(180deg); }
  @media (max-width: 991.98px) {
    .page__en .contactPage__contactUs__form__btnLink.btnStyle button::after {
      right: -45px; } }

.page__en .contactPage__branchInfo__logos a {
  margin-left: 0 !important;
  margin-right: 0.9375rem; }

.loginPopup,
.fpwPopup,
.changePwPopup,
.signupPopup,
.messagePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(242, 242, 242, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99999;
  overflow: hidden; }
  .loginPopup *,
  .fpwPopup *,
  .changePwPopup *,
  .signupPopup *,
  .messagePopup * {
    font-family: "Tajawal"; }
  .loginPopup__content,
  .fpwPopup__content,
  .changePwPopup__content,
  .signupPopup__content,
  .messagePopup__content {
    width: 25%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto; }
    @media screen and (min-width: 768px) {
      .loginPopup__content,
      .fpwPopup__content,
      .changePwPopup__content,
      .signupPopup__content,
      .messagePopup__content {
        width: 29.375rem; } }
    @media (max-width: 991.98px) {
      .loginPopup__content,
      .fpwPopup__content,
      .changePwPopup__content,
      .signupPopup__content,
      .messagePopup__content {
        width: 95%; } }
    .loginPopup__content__header,
    .fpwPopup__content__header,
    .changePwPopup__content__header,
    .signupPopup__content__header,
    .messagePopup__content__header {
      padding: 1.5625rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: 0;
      border-bottom: 1.5px solid #ededed;
      background: #7c7c7b;
      color: #ffffff; }
      .loginPopup__content__header p,
      .fpwPopup__content__header p,
      .changePwPopup__content__header p,
      .signupPopup__content__header p,
      .messagePopup__content__header p {
        margin: auto;
        flex-grow: 1;
        text-align: center;
        font-size: 1.25rem;
        margin-bottom: 0 !important;
        margin-top: 0 !important; }
        .loginPopup__content__header p:nth-child(2),
        .fpwPopup__content__header p:nth-child(2),
        .changePwPopup__content__header p:nth-child(2),
        .signupPopup__content__header p:nth-child(2),
        .messagePopup__content__header p:nth-child(2) {
          margin-left: 0;
          margin-right: auto;
          flex-grow: 0;
          font-size: 0.75rem; }
    .loginPopup__content__mediaBtns,
    .fpwPopup__content__mediaBtns,
    .changePwPopup__content__mediaBtns,
    .signupPopup__content__mediaBtns,
    .messagePopup__content__mediaBtns {
      text-align: center;
      padding: 1.875rem 2.5rem;
      width: 100%; }
      .loginPopup__content__mediaBtns img,
      .fpwPopup__content__mediaBtns img,
      .changePwPopup__content__mediaBtns img,
      .signupPopup__content__mediaBtns img,
      .messagePopup__content__mediaBtns img {
        max-width: 95%; }
      .loginPopup__content__mediaBtns__google a, .loginPopup__content__mediaBtns__facebook a, .loginPopup__content__mediaBtns__twitter a,
      .fpwPopup__content__mediaBtns__google a,
      .fpwPopup__content__mediaBtns__facebook a,
      .fpwPopup__content__mediaBtns__twitter a,
      .changePwPopup__content__mediaBtns__google a,
      .changePwPopup__content__mediaBtns__facebook a,
      .changePwPopup__content__mediaBtns__twitter a,
      .signupPopup__content__mediaBtns__google a,
      .signupPopup__content__mediaBtns__facebook a,
      .signupPopup__content__mediaBtns__twitter a,
      .messagePopup__content__mediaBtns__google a,
      .messagePopup__content__mediaBtns__facebook a,
      .messagePopup__content__mediaBtns__twitter a {
        /**
           * TEMPORARY DISABLE
           */
        cursor: pointer;
        pointer-events: none !important;
        opacity: 0.3;
        padding: 0;
        border: none;
        font-size: 0.75rem;
        font-family: "Tajawal";
        display: flex;
        align-items: center;
        padding-left: 1.25rem;
        border-left: 1px solid #e6e6e6;
        border-radius: 0; }
        .loginPopup__content__mediaBtns__google a span, .loginPopup__content__mediaBtns__facebook a span, .loginPopup__content__mediaBtns__twitter a span,
        .fpwPopup__content__mediaBtns__google a span,
        .fpwPopup__content__mediaBtns__facebook a span,
        .fpwPopup__content__mediaBtns__twitter a span,
        .changePwPopup__content__mediaBtns__google a span,
        .changePwPopup__content__mediaBtns__facebook a span,
        .changePwPopup__content__mediaBtns__twitter a span,
        .signupPopup__content__mediaBtns__google a span,
        .signupPopup__content__mediaBtns__facebook a span,
        .signupPopup__content__mediaBtns__twitter a span,
        .messagePopup__content__mediaBtns__google a span,
        .messagePopup__content__mediaBtns__facebook a span,
        .messagePopup__content__mediaBtns__twitter a span {
          font-size: 1.125rem;
          padding-left: 0.625rem; }
      .loginPopup__content__mediaBtns__google,
      .fpwPopup__content__mediaBtns__google,
      .changePwPopup__content__mediaBtns__google,
      .signupPopup__content__mediaBtns__google,
      .messagePopup__content__mediaBtns__google {
        color: #df4930; }
        .loginPopup__content__mediaBtns__google a,
        .fpwPopup__content__mediaBtns__google a,
        .changePwPopup__content__mediaBtns__google a,
        .signupPopup__content__mediaBtns__google a,
        .messagePopup__content__mediaBtns__google a {
          color: inherit; }
          .loginPopup__content__mediaBtns__google a span,
          .fpwPopup__content__mediaBtns__google a span,
          .changePwPopup__content__mediaBtns__google a span,
          .signupPopup__content__mediaBtns__google a span,
          .messagePopup__content__mediaBtns__google a span {
            color: inherit; }
            .loginPopup__content__mediaBtns__google a span::before,
            .fpwPopup__content__mediaBtns__google a span::before,
            .changePwPopup__content__mediaBtns__google a span::before,
            .signupPopup__content__mediaBtns__google a span::before,
            .messagePopup__content__mediaBtns__google a span::before {
              color: inherit; }
      .loginPopup__content__mediaBtns__facebook,
      .fpwPopup__content__mediaBtns__facebook,
      .changePwPopup__content__mediaBtns__facebook,
      .signupPopup__content__mediaBtns__facebook,
      .messagePopup__content__mediaBtns__facebook {
        color: #507dc0; }
        .loginPopup__content__mediaBtns__facebook a,
        .fpwPopup__content__mediaBtns__facebook a,
        .changePwPopup__content__mediaBtns__facebook a,
        .signupPopup__content__mediaBtns__facebook a,
        .messagePopup__content__mediaBtns__facebook a {
          color: inherit; }
          .loginPopup__content__mediaBtns__facebook a span,
          .fpwPopup__content__mediaBtns__facebook a span,
          .changePwPopup__content__mediaBtns__facebook a span,
          .signupPopup__content__mediaBtns__facebook a span,
          .messagePopup__content__mediaBtns__facebook a span {
            color: inherit; }
            .loginPopup__content__mediaBtns__facebook a span::before,
            .fpwPopup__content__mediaBtns__facebook a span::before,
            .changePwPopup__content__mediaBtns__facebook a span::before,
            .signupPopup__content__mediaBtns__facebook a span::before,
            .messagePopup__content__mediaBtns__facebook a span::before {
              color: inherit; }
      .loginPopup__content__mediaBtns__twitter,
      .fpwPopup__content__mediaBtns__twitter,
      .changePwPopup__content__mediaBtns__twitter,
      .signupPopup__content__mediaBtns__twitter,
      .messagePopup__content__mediaBtns__twitter {
        color: #64ccf1; }
        .loginPopup__content__mediaBtns__twitter a,
        .fpwPopup__content__mediaBtns__twitter a,
        .changePwPopup__content__mediaBtns__twitter a,
        .signupPopup__content__mediaBtns__twitter a,
        .messagePopup__content__mediaBtns__twitter a {
          color: inherit;
          margin-left: 0;
          padding-left: 0;
          border-left: none; }
          .loginPopup__content__mediaBtns__twitter a span,
          .fpwPopup__content__mediaBtns__twitter a span,
          .changePwPopup__content__mediaBtns__twitter a span,
          .signupPopup__content__mediaBtns__twitter a span,
          .messagePopup__content__mediaBtns__twitter a span {
            color: inherit; }
            .loginPopup__content__mediaBtns__twitter a span::before,
            .fpwPopup__content__mediaBtns__twitter a span::before,
            .changePwPopup__content__mediaBtns__twitter a span::before,
            .signupPopup__content__mediaBtns__twitter a span::before,
            .messagePopup__content__mediaBtns__twitter a span::before {
              color: inherit; }
    .loginPopup__content__or,
    .fpwPopup__content__or,
    .changePwPopup__content__or,
    .signupPopup__content__or,
    .messagePopup__content__or {
      width: 100%;
      align-items: center; }
      .loginPopup__content__or .line,
      .fpwPopup__content__or .line,
      .changePwPopup__content__or .line,
      .signupPopup__content__or .line,
      .messagePopup__content__or .line {
        border-bottom: 1px solid #e6e6e6; }
      .loginPopup__content__or div,
      .fpwPopup__content__or div,
      .changePwPopup__content__or div,
      .signupPopup__content__or div,
      .messagePopup__content__or div {
        padding: 0; }
        .loginPopup__content__or div p,
        .fpwPopup__content__or div p,
        .changePwPopup__content__or div p,
        .signupPopup__content__or div p,
        .messagePopup__content__or div p {
          text-align: center;
          color: #e6e6e6; }
    .loginPopup__content__fail, .loginPopup__content__success,
    .fpwPopup__content__fail,
    .fpwPopup__content__success,
    .changePwPopup__content__fail,
    .changePwPopup__content__success,
    .signupPopup__content__fail,
    .signupPopup__content__success,
    .messagePopup__content__fail,
    .messagePopup__content__success {
      width: 100%;
      padding: 0 1.25rem;
      margin-top: 0.625rem; }
      .loginPopup__content__fail__content, .loginPopup__content__success__content,
      .fpwPopup__content__fail__content,
      .fpwPopup__content__success__content,
      .changePwPopup__content__fail__content,
      .changePwPopup__content__success__content,
      .signupPopup__content__fail__content,
      .signupPopup__content__success__content,
      .messagePopup__content__fail__content,
      .messagePopup__content__success__content {
        padding: 0.625rem 0;
        background-color: #f2f2f2; }
        .loginPopup__content__fail__content p span, .loginPopup__content__success__content p span,
        .fpwPopup__content__fail__content p span,
        .fpwPopup__content__success__content p span,
        .changePwPopup__content__fail__content p span,
        .changePwPopup__content__success__content p span,
        .signupPopup__content__fail__content p span,
        .signupPopup__content__success__content p span,
        .messagePopup__content__fail__content p span,
        .messagePopup__content__success__content p span {
          cursor: pointer;
          text-decoration: underline;
          color: blue; }
        .loginPopup__content__fail__content img, .loginPopup__content__success__content img,
        .fpwPopup__content__fail__content img,
        .fpwPopup__content__success__content img,
        .changePwPopup__content__fail__content img,
        .changePwPopup__content__success__content img,
        .signupPopup__content__fail__content img,
        .signupPopup__content__success__content img,
        .messagePopup__content__fail__content img,
        .messagePopup__content__success__content img {
          margin: 0 0.625rem;
          max-width: 10%;
          max-height: 35px; }
    .loginPopup__content__form__place,
    .fpwPopup__content__form__place,
    .changePwPopup__content__form__place,
    .signupPopup__content__form__place,
    .messagePopup__content__form__place {
      padding-top: 1.875rem; }
    .loginPopup__content__form, .loginPopup__content__body,
    .fpwPopup__content__form,
    .fpwPopup__content__body,
    .changePwPopup__content__form,
    .changePwPopup__content__body,
    .signupPopup__content__form,
    .signupPopup__content__body,
    .messagePopup__content__form,
    .messagePopup__content__body {
      padding: 1.5625rem 2.5rem 1.5625rem;
      width: 100%; }
      .loginPopup__content__form p.checkoutLink, .loginPopup__content__body p.checkoutLink,
      .fpwPopup__content__form p.checkoutLink,
      .fpwPopup__content__body p.checkoutLink,
      .changePwPopup__content__form p.checkoutLink,
      .changePwPopup__content__body p.checkoutLink,
      .signupPopup__content__form p.checkoutLink,
      .signupPopup__content__body p.checkoutLink,
      .messagePopup__content__form p.checkoutLink,
      .messagePopup__content__body p.checkoutLink {
        margin-top: 1.25rem; }
      .loginPopup__content__form p a.darkRedBtn, .loginPopup__content__body p a.darkRedBtn,
      .fpwPopup__content__form p a.darkRedBtn,
      .fpwPopup__content__body p a.darkRedBtn,
      .changePwPopup__content__form p a.darkRedBtn,
      .changePwPopup__content__body p a.darkRedBtn,
      .signupPopup__content__form p a.darkRedBtn,
      .signupPopup__content__body p a.darkRedBtn,
      .messagePopup__content__form p a.darkRedBtn,
      .messagePopup__content__body p a.darkRedBtn {
        text-decoration: none;
        cursor: pointer;
        background-color: #7c7c7b;
        color: #ffffff;
        padding: 0.625rem 6.25rem; }
        @media (max-width: 991.98px) {
          .loginPopup__content__form p a.darkRedBtn, .loginPopup__content__body p a.darkRedBtn,
          .fpwPopup__content__form p a.darkRedBtn,
          .fpwPopup__content__body p a.darkRedBtn,
          .changePwPopup__content__form p a.darkRedBtn,
          .changePwPopup__content__body p a.darkRedBtn,
          .signupPopup__content__form p a.darkRedBtn,
          .signupPopup__content__body p a.darkRedBtn,
          .messagePopup__content__form p a.darkRedBtn,
          .messagePopup__content__body p a.darkRedBtn {
            padding: 0.625rem 2.5rem; } }
      .loginPopup__content__form .boldP, .loginPopup__content__body .boldP,
      .fpwPopup__content__form .boldP,
      .fpwPopup__content__body .boldP,
      .changePwPopup__content__form .boldP,
      .changePwPopup__content__body .boldP,
      .signupPopup__content__form .boldP,
      .signupPopup__content__body .boldP,
      .messagePopup__content__form .boldP,
      .messagePopup__content__body .boldP {
        font-weight: 600; }
      .loginPopup__content__form .invalidInput__password, .loginPopup__content__form .invalidInput__email, .loginPopup__content__form .invalidInput__code, .loginPopup__content__form .invalidInput__mobile, .loginPopup__content__form .invalidInput__name, .loginPopup__content__form .invalidInput__address, .loginPopup__content__body .invalidInput__password, .loginPopup__content__body .invalidInput__email, .loginPopup__content__body .invalidInput__code, .loginPopup__content__body .invalidInput__mobile, .loginPopup__content__body .invalidInput__name, .loginPopup__content__body .invalidInput__address,
      .fpwPopup__content__form .invalidInput__password,
      .fpwPopup__content__form .invalidInput__email,
      .fpwPopup__content__form .invalidInput__code,
      .fpwPopup__content__form .invalidInput__mobile,
      .fpwPopup__content__form .invalidInput__name,
      .fpwPopup__content__form .invalidInput__address,
      .fpwPopup__content__body .invalidInput__password,
      .fpwPopup__content__body .invalidInput__email,
      .fpwPopup__content__body .invalidInput__code,
      .fpwPopup__content__body .invalidInput__mobile,
      .fpwPopup__content__body .invalidInput__name,
      .fpwPopup__content__body .invalidInput__address,
      .changePwPopup__content__form .invalidInput__password,
      .changePwPopup__content__form .invalidInput__email,
      .changePwPopup__content__form .invalidInput__code,
      .changePwPopup__content__form .invalidInput__mobile,
      .changePwPopup__content__form .invalidInput__name,
      .changePwPopup__content__form .invalidInput__address,
      .changePwPopup__content__body .invalidInput__password,
      .changePwPopup__content__body .invalidInput__email,
      .changePwPopup__content__body .invalidInput__code,
      .changePwPopup__content__body .invalidInput__mobile,
      .changePwPopup__content__body .invalidInput__name,
      .changePwPopup__content__body .invalidInput__address,
      .signupPopup__content__form .invalidInput__password,
      .signupPopup__content__form .invalidInput__email,
      .signupPopup__content__form .invalidInput__code,
      .signupPopup__content__form .invalidInput__mobile,
      .signupPopup__content__form .invalidInput__name,
      .signupPopup__content__form .invalidInput__address,
      .signupPopup__content__body .invalidInput__password,
      .signupPopup__content__body .invalidInput__email,
      .signupPopup__content__body .invalidInput__code,
      .signupPopup__content__body .invalidInput__mobile,
      .signupPopup__content__body .invalidInput__name,
      .signupPopup__content__body .invalidInput__address,
      .messagePopup__content__form .invalidInput__password,
      .messagePopup__content__form .invalidInput__email,
      .messagePopup__content__form .invalidInput__code,
      .messagePopup__content__form .invalidInput__mobile,
      .messagePopup__content__form .invalidInput__name,
      .messagePopup__content__form .invalidInput__address,
      .messagePopup__content__body .invalidInput__password,
      .messagePopup__content__body .invalidInput__email,
      .messagePopup__content__body .invalidInput__code,
      .messagePopup__content__body .invalidInput__mobile,
      .messagePopup__content__body .invalidInput__name,
      .messagePopup__content__body .invalidInput__address {
        font-size: 0.75rem;
        display: none;
        text-align: right;
        margin-top: 0;
        margin-bottom: 0;
        color: #e62323 !important; }
      .loginPopup__content__form .btnStyle, .loginPopup__content__body .btnStyle,
      .fpwPopup__content__form .btnStyle,
      .fpwPopup__content__body .btnStyle,
      .changePwPopup__content__form .btnStyle,
      .changePwPopup__content__body .btnStyle,
      .signupPopup__content__form .btnStyle,
      .signupPopup__content__body .btnStyle,
      .messagePopup__content__form .btnStyle,
      .messagePopup__content__body .btnStyle {
        width: 100%; }
        .loginPopup__content__form .btnStyle button, .loginPopup__content__body .btnStyle button,
        .fpwPopup__content__form .btnStyle button,
        .fpwPopup__content__body .btnStyle button,
        .changePwPopup__content__form .btnStyle button,
        .changePwPopup__content__body .btnStyle button,
        .signupPopup__content__form .btnStyle button,
        .signupPopup__content__body .btnStyle button,
        .messagePopup__content__form .btnStyle button,
        .messagePopup__content__body .btnStyle button {
          padding: 0.9375rem;
          width: 100%;
          margin-top: 0.9375rem; }
      .loginPopup__content__form__field, .loginPopup__content__body__field,
      .fpwPopup__content__form__field,
      .fpwPopup__content__body__field,
      .changePwPopup__content__form__field,
      .changePwPopup__content__body__field,
      .signupPopup__content__form__field,
      .signupPopup__content__body__field,
      .messagePopup__content__form__field,
      .messagePopup__content__body__field {
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap; }
        .loginPopup__content__form__field.address, .loginPopup__content__body__field.address,
        .fpwPopup__content__form__field.address,
        .fpwPopup__content__body__field.address,
        .changePwPopup__content__form__field.address,
        .changePwPopup__content__body__field.address,
        .signupPopup__content__form__field.address,
        .signupPopup__content__body__field.address,
        .messagePopup__content__form__field.address,
        .messagePopup__content__body__field.address {
          padding-bottom: 1.875rem; }
        .loginPopup__content__form__field input,
        .loginPopup__content__form__field label,
        .loginPopup__content__form__field textarea, .loginPopup__content__body__field input,
        .loginPopup__content__body__field label,
        .loginPopup__content__body__field textarea,
        .fpwPopup__content__form__field input,
        .fpwPopup__content__form__field label,
        .fpwPopup__content__form__field textarea,
        .fpwPopup__content__body__field input,
        .fpwPopup__content__body__field label,
        .fpwPopup__content__body__field textarea,
        .changePwPopup__content__form__field input,
        .changePwPopup__content__form__field label,
        .changePwPopup__content__form__field textarea,
        .changePwPopup__content__body__field input,
        .changePwPopup__content__body__field label,
        .changePwPopup__content__body__field textarea,
        .signupPopup__content__form__field input,
        .signupPopup__content__form__field label,
        .signupPopup__content__form__field textarea,
        .signupPopup__content__body__field input,
        .signupPopup__content__body__field label,
        .signupPopup__content__body__field textarea,
        .messagePopup__content__form__field input,
        .messagePopup__content__form__field label,
        .messagePopup__content__form__field textarea,
        .messagePopup__content__body__field input,
        .messagePopup__content__body__field label,
        .messagePopup__content__body__field textarea {
          max-width: 100%;
          width: 100%; }
        .loginPopup__content__form__field label, .loginPopup__content__body__field label,
        .fpwPopup__content__form__field label,
        .fpwPopup__content__body__field label,
        .changePwPopup__content__form__field label,
        .changePwPopup__content__body__field label,
        .signupPopup__content__form__field label,
        .signupPopup__content__body__field label,
        .messagePopup__content__form__field label,
        .messagePopup__content__body__field label {
          font-size: 0.6875rem;
          color: #b0b0b0;
          margin-bottom: 0.625rem;
          transform: none;
          transition: all 350ms ease-in-out; }
          .loginPopup__content__form__field label.sendCode__forget, .loginPopup__content__body__field label.sendCode__forget,
          .fpwPopup__content__form__field label.sendCode__forget,
          .fpwPopup__content__body__field label.sendCode__forget,
          .changePwPopup__content__form__field label.sendCode__forget,
          .changePwPopup__content__body__field label.sendCode__forget,
          .signupPopup__content__form__field label.sendCode__forget,
          .signupPopup__content__body__field label.sendCode__forget,
          .messagePopup__content__form__field label.sendCode__forget,
          .messagePopup__content__body__field label.sendCode__forget {
            color: #7c7c7b;
            max-height: 32px; }
            @media (max-width: 991.98px) {
              .loginPopup__content__form__field label.sendCode__forget, .loginPopup__content__body__field label.sendCode__forget,
              .fpwPopup__content__form__field label.sendCode__forget,
              .fpwPopup__content__body__field label.sendCode__forget,
              .changePwPopup__content__form__field label.sendCode__forget,
              .changePwPopup__content__body__field label.sendCode__forget,
              .signupPopup__content__form__field label.sendCode__forget,
              .signupPopup__content__body__field label.sendCode__forget,
              .messagePopup__content__form__field label.sendCode__forget,
              .messagePopup__content__body__field label.sendCode__forget {
                min-width: fit-content; } }
        .loginPopup__content__form__field input,
        .loginPopup__content__form__field textarea, .loginPopup__content__body__field input,
        .loginPopup__content__body__field textarea,
        .fpwPopup__content__form__field input,
        .fpwPopup__content__form__field textarea,
        .fpwPopup__content__body__field input,
        .fpwPopup__content__body__field textarea,
        .changePwPopup__content__form__field input,
        .changePwPopup__content__form__field textarea,
        .changePwPopup__content__body__field input,
        .changePwPopup__content__body__field textarea,
        .signupPopup__content__form__field input,
        .signupPopup__content__form__field textarea,
        .signupPopup__content__body__field input,
        .signupPopup__content__body__field textarea,
        .messagePopup__content__form__field input,
        .messagePopup__content__form__field textarea,
        .messagePopup__content__body__field input,
        .messagePopup__content__body__field textarea {
          padding: 0 0 0.625rem;
          border: none;
          border-bottom: 1px solid #cbcbcb;
          background: #f9f9f9;
          margin-bottom: 0;
          background: none; }
        .loginPopup__content__form__field.notFocused label, .loginPopup__content__body__field.notFocused label,
        .fpwPopup__content__form__field.notFocused label,
        .fpwPopup__content__body__field.notFocused label,
        .changePwPopup__content__form__field.notFocused label,
        .changePwPopup__content__body__field.notFocused label,
        .signupPopup__content__form__field.notFocused label,
        .signupPopup__content__body__field.notFocused label,
        .messagePopup__content__form__field.notFocused label,
        .messagePopup__content__body__field.notFocused label {
          transform: translateY(40px); }
        .loginPopup__content__form__field.notFocused.msg, .loginPopup__content__body__field.notFocused.msg,
        .fpwPopup__content__form__field.notFocused.msg,
        .fpwPopup__content__body__field.notFocused.msg,
        .changePwPopup__content__form__field.notFocused.msg,
        .changePwPopup__content__body__field.notFocused.msg,
        .signupPopup__content__form__field.notFocused.msg,
        .signupPopup__content__body__field.notFocused.msg,
        .messagePopup__content__form__field.notFocused.msg,
        .messagePopup__content__body__field.notFocused.msg {
          margin-bottom: 0rem; }
          .loginPopup__content__form__field.notFocused.msg label, .loginPopup__content__body__field.notFocused.msg label,
          .fpwPopup__content__form__field.notFocused.msg label,
          .fpwPopup__content__body__field.notFocused.msg label,
          .changePwPopup__content__form__field.notFocused.msg label,
          .changePwPopup__content__body__field.notFocused.msg label,
          .signupPopup__content__form__field.notFocused.msg label,
          .signupPopup__content__body__field.notFocused.msg label,
          .messagePopup__content__form__field.notFocused.msg label,
          .messagePopup__content__body__field.notFocused.msg label {
            transform: none; }
        .loginPopup__content__form__field label.pwTitleForget__forget, .loginPopup__content__form__field label.sendCode__forget, .loginPopup__content__body__field label.pwTitleForget__forget, .loginPopup__content__body__field label.sendCode__forget,
        .fpwPopup__content__form__field label.pwTitleForget__forget,
        .fpwPopup__content__form__field label.sendCode__forget,
        .fpwPopup__content__body__field label.pwTitleForget__forget,
        .fpwPopup__content__body__field label.sendCode__forget,
        .changePwPopup__content__form__field label.pwTitleForget__forget,
        .changePwPopup__content__form__field label.sendCode__forget,
        .changePwPopup__content__body__field label.pwTitleForget__forget,
        .changePwPopup__content__body__field label.sendCode__forget,
        .signupPopup__content__form__field label.pwTitleForget__forget,
        .signupPopup__content__form__field label.sendCode__forget,
        .signupPopup__content__body__field label.pwTitleForget__forget,
        .signupPopup__content__body__field label.sendCode__forget,
        .messagePopup__content__form__field label.pwTitleForget__forget,
        .messagePopup__content__form__field label.sendCode__forget,
        .messagePopup__content__body__field label.pwTitleForget__forget,
        .messagePopup__content__body__field label.sendCode__forget {
          cursor: pointer; }
        .loginPopup__content__form__field label.pwTitleForget__forget, .loginPopup__content__body__field label.pwTitleForget__forget,
        .fpwPopup__content__form__field label.pwTitleForget__forget,
        .fpwPopup__content__body__field label.pwTitleForget__forget,
        .changePwPopup__content__form__field label.pwTitleForget__forget,
        .changePwPopup__content__body__field label.pwTitleForget__forget,
        .signupPopup__content__form__field label.pwTitleForget__forget,
        .signupPopup__content__body__field label.pwTitleForget__forget,
        .messagePopup__content__form__field label.pwTitleForget__forget,
        .messagePopup__content__body__field label.pwTitleForget__forget {
          text-align: left;
          text-decoration: underline; }
        .loginPopup__content__form__field.code.notFocused label, .loginPopup__content__body__field.code.notFocused label,
        .fpwPopup__content__form__field.code.notFocused label,
        .fpwPopup__content__body__field.code.notFocused label,
        .changePwPopup__content__form__field.code.notFocused label,
        .changePwPopup__content__body__field.code.notFocused label,
        .signupPopup__content__form__field.code.notFocused label,
        .signupPopup__content__body__field.code.notFocused label,
        .messagePopup__content__form__field.code.notFocused label,
        .messagePopup__content__body__field.code.notFocused label {
          transform: translateY(0); }
        .loginPopup__content__form__field.code label, .loginPopup__content__body__field.code label,
        .fpwPopup__content__form__field.code label,
        .fpwPopup__content__body__field.code label,
        .changePwPopup__content__form__field.code label,
        .changePwPopup__content__body__field.code label,
        .signupPopup__content__form__field.code label,
        .signupPopup__content__body__field.code label,
        .messagePopup__content__form__field.code label,
        .messagePopup__content__body__field.code label {
          font-size: 0.875rem;
          color: #8e8e8e;
          margin-bottom: 0; }
        .loginPopup__content__form__field.code .codeInput, .loginPopup__content__body__field.code .codeInput,
        .fpwPopup__content__form__field.code .codeInput,
        .fpwPopup__content__body__field.code .codeInput,
        .changePwPopup__content__form__field.code .codeInput,
        .changePwPopup__content__body__field.code .codeInput,
        .signupPopup__content__form__field.code .codeInput,
        .signupPopup__content__body__field.code .codeInput,
        .messagePopup__content__form__field.code .codeInput,
        .messagePopup__content__body__field.code .codeInput {
          padding: 0.3rem;
          border-bottom: 0;
          border: 1px solid #cbcbcb; }
        .loginPopup__content__form__field.password, .loginPopup__content__body__field.password,
        .fpwPopup__content__form__field.password,
        .fpwPopup__content__body__field.password,
        .changePwPopup__content__form__field.password,
        .changePwPopup__content__body__field.password,
        .signupPopup__content__form__field.password,
        .signupPopup__content__body__field.password,
        .messagePopup__content__form__field.password,
        .messagePopup__content__body__field.password {
          position: relative; }
          .loginPopup__content__form__field.password .show-hide-password, .loginPopup__content__body__field.password .show-hide-password,
          .fpwPopup__content__form__field.password .show-hide-password,
          .fpwPopup__content__body__field.password .show-hide-password,
          .changePwPopup__content__form__field.password .show-hide-password,
          .changePwPopup__content__body__field.password .show-hide-password,
          .signupPopup__content__form__field.password .show-hide-password,
          .signupPopup__content__body__field.password .show-hide-password,
          .messagePopup__content__form__field.password .show-hide-password,
          .messagePopup__content__body__field.password .show-hide-password {
            position: absolute;
            left: 5px;
            bottom: 6px;
            cursor: pointer; }
            .loginPopup__content__form__field.password .show-hide-password:after, .loginPopup__content__body__field.password .show-hide-password:after,
            .fpwPopup__content__form__field.password .show-hide-password:after,
            .fpwPopup__content__body__field.password .show-hide-password:after,
            .changePwPopup__content__form__field.password .show-hide-password:after,
            .changePwPopup__content__body__field.password .show-hide-password:after,
            .signupPopup__content__form__field.password .show-hide-password:after,
            .signupPopup__content__body__field.password .show-hide-password:after,
            .messagePopup__content__form__field.password .show-hide-password:after,
            .messagePopup__content__body__field.password .show-hide-password:after {
              content: "";
              width: 1px;
              height: 20px;
              transform: rotate(135deg) scale(0.9);
              position: absolute;
              left: 8px;
              bottom: 2px;
              opacity: 0;
              background: #7c7c7b;
              transition: all 300ms ease; }
            .loginPopup__content__form__field.password .show-hide-password.active:after, .loginPopup__content__body__field.password .show-hide-password.active:after,
            .fpwPopup__content__form__field.password .show-hide-password.active:after,
            .fpwPopup__content__body__field.password .show-hide-password.active:after,
            .changePwPopup__content__form__field.password .show-hide-password.active:after,
            .changePwPopup__content__body__field.password .show-hide-password.active:after,
            .signupPopup__content__form__field.password .show-hide-password.active:after,
            .signupPopup__content__body__field.password .show-hide-password.active:after,
            .messagePopup__content__form__field.password .show-hide-password.active:after,
            .messagePopup__content__body__field.password .show-hide-password.active:after {
              transform: rotate(45deg) scale(1);
              opacity: 1; }
      .loginPopup__content__form .pwTitleForget__forget, .loginPopup__content__body .pwTitleForget__forget,
      .fpwPopup__content__form .pwTitleForget__forget,
      .fpwPopup__content__body .pwTitleForget__forget,
      .changePwPopup__content__form .pwTitleForget__forget,
      .changePwPopup__content__body .pwTitleForget__forget,
      .signupPopup__content__form .pwTitleForget__forget,
      .signupPopup__content__body .pwTitleForget__forget,
      .messagePopup__content__form .pwTitleForget__forget,
      .messagePopup__content__body .pwTitleForget__forget {
        margin-top: .5rem;
        font-size: 0.7rem;
        text-align: left;
        cursor: pointer; }
      .loginPopup__content__form img.resendcode, .loginPopup__content__body img.resendcode,
      .fpwPopup__content__form img.resendcode,
      .fpwPopup__content__body img.resendcode,
      .changePwPopup__content__form img.resendcode,
      .changePwPopup__content__body img.resendcode,
      .signupPopup__content__form img.resendcode,
      .signupPopup__content__body img.resendcode,
      .messagePopup__content__form img.resendcode,
      .messagePopup__content__body img.resendcode {
        width: 20px;
        height: 20px;
        padding: 1%;
        display: none;
        margin-right: 20px; }
        @media (max-width: 991.98px) {
          .loginPopup__content__form img.resendcode, .loginPopup__content__body img.resendcode,
          .fpwPopup__content__form img.resendcode,
          .fpwPopup__content__body img.resendcode,
          .changePwPopup__content__form img.resendcode,
          .changePwPopup__content__body img.resendcode,
          .signupPopup__content__form img.resendcode,
          .signupPopup__content__body img.resendcode,
          .messagePopup__content__form img.resendcode,
          .messagePopup__content__body img.resendcode {
            margin-right: 10px; } }
    .loginPopup__content__signUpBtn,
    .fpwPopup__content__signUpBtn,
    .changePwPopup__content__signUpBtn,
    .signupPopup__content__signUpBtn,
    .messagePopup__content__signUpBtn {
      margin: 1.875rem 2.5rem 2.5rem;
      color: #7c7c7b;
      cursor: pointer; }
      .loginPopup__content__signUpBtn p,
      .fpwPopup__content__signUpBtn p,
      .changePwPopup__content__signUpBtn p,
      .signupPopup__content__signUpBtn p,
      .messagePopup__content__signUpBtn p {
        text-decoration: underline; }
    .loginPopup__content.cancelOrder,
    .fpwPopup__content.cancelOrder,
    .changePwPopup__content.cancelOrder,
    .signupPopup__content.cancelOrder,
    .messagePopup__content.cancelOrder {
      border: 1px solid #ededed; }
      .loginPopup__content.cancelOrder .messagePopup__content__header,
      .fpwPopup__content.cancelOrder .messagePopup__content__header,
      .changePwPopup__content.cancelOrder .messagePopup__content__header,
      .signupPopup__content.cancelOrder .messagePopup__content__header,
      .messagePopup__content.cancelOrder .messagePopup__content__header {
        background: none;
        color: #7c7c7b; }
      .loginPopup__content.cancelOrder .messagePopup__content__footer,
      .fpwPopup__content.cancelOrder .messagePopup__content__footer,
      .changePwPopup__content.cancelOrder .messagePopup__content__footer,
      .signupPopup__content.cancelOrder .messagePopup__content__footer,
      .messagePopup__content.cancelOrder .messagePopup__content__footer {
        border: none; }
        .loginPopup__content.cancelOrder .messagePopup__content__footer button:first-of-type,
        .fpwPopup__content.cancelOrder .messagePopup__content__footer button:first-of-type,
        .changePwPopup__content.cancelOrder .messagePopup__content__footer button:first-of-type,
        .signupPopup__content.cancelOrder .messagePopup__content__footer button:first-of-type,
        .messagePopup__content.cancelOrder .messagePopup__content__footer button:first-of-type {
          margin-left: 0.625rem; }
      .loginPopup__content.cancelOrder h5,
      .fpwPopup__content.cancelOrder h5,
      .changePwPopup__content.cancelOrder h5,
      .signupPopup__content.cancelOrder h5,
      .messagePopup__content.cancelOrder h5 {
        margin-bottom: 1.25rem; }
      .loginPopup__content.cancelOrder h6,
      .fpwPopup__content.cancelOrder h6,
      .changePwPopup__content.cancelOrder h6,
      .signupPopup__content.cancelOrder h6,
      .messagePopup__content.cancelOrder h6 {
        font-weight: 600;
        margin-bottom: 0.3125rem; }
      .loginPopup__content.cancelOrder p,
      .fpwPopup__content.cancelOrder p,
      .changePwPopup__content.cancelOrder p,
      .signupPopup__content.cancelOrder p,
      .messagePopup__content.cancelOrder p {
        margin-bottom: 0.25rem; }
        .loginPopup__content.cancelOrder p:last-of-type,
        .fpwPopup__content.cancelOrder p:last-of-type,
        .changePwPopup__content.cancelOrder p:last-of-type,
        .signupPopup__content.cancelOrder p:last-of-type,
        .messagePopup__content.cancelOrder p:last-of-type {
          margin-bottom: 0; }
      .loginPopup__content.cancelOrder .btn.button,
      .fpwPopup__content.cancelOrder .btn.button,
      .changePwPopup__content.cancelOrder .btn.button,
      .signupPopup__content.cancelOrder .btn.button,
      .messagePopup__content.cancelOrder .btn.button {
        border: 1px solid #7c7c7b !important; }
      .loginPopup__content.cancelOrder .btn.button.whiteBg,
      .fpwPopup__content.cancelOrder .btn.button.whiteBg,
      .changePwPopup__content.cancelOrder .btn.button.whiteBg,
      .signupPopup__content.cancelOrder .btn.button.whiteBg,
      .messagePopup__content.cancelOrder .btn.button.whiteBg {
        background: #ffffff !important;
        border: 1px solid #7c7c7b !important;
        color: #7c7c7b !important; }
    .loginPopup__content__footer,
    .fpwPopup__content__footer,
    .changePwPopup__content__footer,
    .signupPopup__content__footer,
    .messagePopup__content__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid #dee2e6;
      padding: 1.5625rem 0;
      margin: 0 2.5rem;
      width: calc(100% - 80px); }
      .loginPopup__content__footer .button,
      .fpwPopup__content__footer .button,
      .changePwPopup__content__footer .button,
      .signupPopup__content__footer .button,
      .messagePopup__content__footer .button {
        padding: 0.9375rem; }
        .loginPopup__content__footer .button::after,
        .fpwPopup__content__footer .button::after,
        .changePwPopup__content__footer .button::after,
        .signupPopup__content__footer .button::after,
        .messagePopup__content__footer .button::after {
          display: none; }
  .loginPopup .dropdown,
  .fpwPopup .dropdown,
  .changePwPopup .dropdown,
  .signupPopup .dropdown,
  .messagePopup .dropdown {
    width: 100%;
    padding-top: 0.4375rem; }
    .loginPopup .dropdown label,
    .fpwPopup .dropdown label,
    .changePwPopup .dropdown label,
    .signupPopup .dropdown label,
    .messagePopup .dropdown label {
      display: block;
      font-size: 0.6875rem;
      font-family: "Tajawal";
      color: #b0b0b0;
      margin-bottom: 0.625rem; }
    .loginPopup .dropdown button,
    .fpwPopup .dropdown button,
    .changePwPopup .dropdown button,
    .signupPopup .dropdown button,
    .messagePopup .dropdown button {
      display: block;
      width: 100%;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #ffffff;
      background-clip: padding-box;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #cbcbcb;
      padding: 0.3125rem 0; }
      .loginPopup .dropdown button::after,
      .fpwPopup .dropdown button::after,
      .changePwPopup .dropdown button::after,
      .signupPopup .dropdown button::after,
      .messagePopup .dropdown button::after {
        display: none; }
      .loginPopup .dropdown button span::before,
      .fpwPopup .dropdown button span::before,
      .changePwPopup .dropdown button span::before,
      .signupPopup .dropdown button span::before,
      .messagePopup .dropdown button span::before {
        color: #7c7c7b;
        font-size: 0.5rem; }
    .loginPopup .dropdown .dropdown-menu,
    .fpwPopup .dropdown .dropdown-menu,
    .changePwPopup .dropdown .dropdown-menu,
    .signupPopup .dropdown .dropdown-menu,
    .messagePopup .dropdown .dropdown-menu {
      width: 100%;
      max-height: 12.5rem;
      overflow: auto;
      background: #ffffff; }
  .loginPopup .dropdown:first-of-type,
  .fpwPopup .dropdown:first-of-type,
  .changePwPopup .dropdown:first-of-type,
  .signupPopup .dropdown:first-of-type,
  .messagePopup .dropdown:first-of-type {
    padding-left: 20px; }
    .loginPopup .dropdown:first-of-type .dropdown-menu,
    .fpwPopup .dropdown:first-of-type .dropdown-menu,
    .changePwPopup .dropdown:first-of-type .dropdown-menu,
    .signupPopup .dropdown:first-of-type .dropdown-menu,
    .messagePopup .dropdown:first-of-type .dropdown-menu {
      width: calc(100% - 20px);
      max-height: 12.5rem;
      overflow: auto;
      background: #ffffff; }

@media (max-width: 991.98px) {
  .signupPopup, .messagePopup {
    overflow-y: auto; } }

@media screen and (min-width: 768px) {
  .withInnerHeader .messagePopup__content {
    width: 41.875rem; } }

@media screen and (min-width: 768px) {
  .withInnerHeader .messagePopup__content.orderModify {
    width: 45rem; } }

.loginPopup__content__mediaBtns {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 575.98px) {
    .loginPopup__content__mediaBtns {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  @media (max-width: 575.98px) {
    .loginPopup__content__mediaBtns__facebook a, .loginPopup__content__mediaBtns__google a {
      padding: 0;
      border: 0;
      margin-bottom: 1.875rem; } }

.signupPopup__content .dropdown {
  width: 50%; }
  .signupPopup__content .dropdown button {
    position: relative; }
    .signupPopup__content .dropdown button:after {
      position: absolute;
      right: 0;
      top: 45%; }
  .signupPopup__content .dropdown label {
    display: block; }

.signupPopup__content__or {
  padding-bottom: 0.3125rem;
  padding-top: 0.3125rem; }

.signupPopup__content .btnStyle {
  font-size: 1.125rem; }
  .signupPopup__content .btnStyle.signup {
    padding: 1.25rem; }

.signupPopup__content__mediaBtns {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 575.98px) {
    .signupPopup__content__mediaBtns {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  .signupPopup__content__mediaBtns__google, .signupPopup__content__mediaBtns__facebook, .signupPopup__content__mediaBtns__twitter {
    padding-top: 0.9375rem;
    padding-bottom: 1.5625rem; }
  @media (max-width: 575.98px) {
    .signupPopup__content__mediaBtns__facebook a, .signupPopup__content__mediaBtns__google a {
      padding: 0;
      border: 0; } }

.page__en .loginPopup__content__footer button:first-of-type,
.page__en .fpwPopup__content__footer button:first-of-type,
.page__en .changePwPopup__content__footer button:first-of-type,
.page__en .signupPopup__content__footer button:first-of-type,
.page__en .messagePopup__content__footer button:first-of-type {
  margin-left: 0;
  margin-right: 0.625rem; }

.page__en .loginPopup__content__form h2, .page__en .loginPopup__content__form p, .page__en .loginPopup__content__body h2, .page__en .loginPopup__content__body p,
.page__en .fpwPopup__content__form h2,
.page__en .fpwPopup__content__form p,
.page__en .fpwPopup__content__body h2,
.page__en .fpwPopup__content__body p,
.page__en .changePwPopup__content__form h2,
.page__en .changePwPopup__content__form p,
.page__en .changePwPopup__content__body h2,
.page__en .changePwPopup__content__body p,
.page__en .signupPopup__content__form h2,
.page__en .signupPopup__content__form p,
.page__en .signupPopup__content__body h2,
.page__en .signupPopup__content__body p,
.page__en .messagePopup__content__form h2,
.page__en .messagePopup__content__form p,
.page__en .messagePopup__content__body h2,
.page__en .messagePopup__content__body p {
  text-align: center; }

.page__en .loginPopup__content__form .invalidInput__password, .page__en .loginPopup__content__form .invalidInput__email, .page__en .loginPopup__content__form .invalidInput__code, .page__en .loginPopup__content__form .invalidInput__mobile, .page__en .loginPopup__content__form .invalidInput__name, .page__en .loginPopup__content__form .invalidInput__address, .page__en .loginPopup__content__body .invalidInput__password, .page__en .loginPopup__content__body .invalidInput__email, .page__en .loginPopup__content__body .invalidInput__code, .page__en .loginPopup__content__body .invalidInput__mobile, .page__en .loginPopup__content__body .invalidInput__name, .page__en .loginPopup__content__body .invalidInput__address,
.page__en .fpwPopup__content__form .invalidInput__password,
.page__en .fpwPopup__content__form .invalidInput__email,
.page__en .fpwPopup__content__form .invalidInput__code,
.page__en .fpwPopup__content__form .invalidInput__mobile,
.page__en .fpwPopup__content__form .invalidInput__name,
.page__en .fpwPopup__content__form .invalidInput__address,
.page__en .fpwPopup__content__body .invalidInput__password,
.page__en .fpwPopup__content__body .invalidInput__email,
.page__en .fpwPopup__content__body .invalidInput__code,
.page__en .fpwPopup__content__body .invalidInput__mobile,
.page__en .fpwPopup__content__body .invalidInput__name,
.page__en .fpwPopup__content__body .invalidInput__address,
.page__en .changePwPopup__content__form .invalidInput__password,
.page__en .changePwPopup__content__form .invalidInput__email,
.page__en .changePwPopup__content__form .invalidInput__code,
.page__en .changePwPopup__content__form .invalidInput__mobile,
.page__en .changePwPopup__content__form .invalidInput__name,
.page__en .changePwPopup__content__form .invalidInput__address,
.page__en .changePwPopup__content__body .invalidInput__password,
.page__en .changePwPopup__content__body .invalidInput__email,
.page__en .changePwPopup__content__body .invalidInput__code,
.page__en .changePwPopup__content__body .invalidInput__mobile,
.page__en .changePwPopup__content__body .invalidInput__name,
.page__en .changePwPopup__content__body .invalidInput__address,
.page__en .signupPopup__content__form .invalidInput__password,
.page__en .signupPopup__content__form .invalidInput__email,
.page__en .signupPopup__content__form .invalidInput__code,
.page__en .signupPopup__content__form .invalidInput__mobile,
.page__en .signupPopup__content__form .invalidInput__name,
.page__en .signupPopup__content__form .invalidInput__address,
.page__en .signupPopup__content__body .invalidInput__password,
.page__en .signupPopup__content__body .invalidInput__email,
.page__en .signupPopup__content__body .invalidInput__code,
.page__en .signupPopup__content__body .invalidInput__mobile,
.page__en .signupPopup__content__body .invalidInput__name,
.page__en .signupPopup__content__body .invalidInput__address,
.page__en .messagePopup__content__form .invalidInput__password,
.page__en .messagePopup__content__form .invalidInput__email,
.page__en .messagePopup__content__form .invalidInput__code,
.page__en .messagePopup__content__form .invalidInput__mobile,
.page__en .messagePopup__content__form .invalidInput__name,
.page__en .messagePopup__content__form .invalidInput__address,
.page__en .messagePopup__content__body .invalidInput__password,
.page__en .messagePopup__content__body .invalidInput__email,
.page__en .messagePopup__content__body .invalidInput__code,
.page__en .messagePopup__content__body .invalidInput__mobile,
.page__en .messagePopup__content__body .invalidInput__name,
.page__en .messagePopup__content__body .invalidInput__address {
  text-align: left; }

.page__en .loginPopup__content__form .cancelOrder__cancelTips p, .page__en .loginPopup__content__body .cancelOrder__cancelTips p,
.page__en .fpwPopup__content__form .cancelOrder__cancelTips p,
.page__en .fpwPopup__content__body .cancelOrder__cancelTips p,
.page__en .changePwPopup__content__form .cancelOrder__cancelTips p,
.page__en .changePwPopup__content__body .cancelOrder__cancelTips p,
.page__en .signupPopup__content__form .cancelOrder__cancelTips p,
.page__en .signupPopup__content__body .cancelOrder__cancelTips p,
.page__en .messagePopup__content__form .cancelOrder__cancelTips p,
.page__en .messagePopup__content__body .cancelOrder__cancelTips p {
  text-align: left; }

.page__en .loginPopup__content__form__place,
.page__en .fpwPopup__content__form__place,
.page__en .changePwPopup__content__form__place,
.page__en .signupPopup__content__form__place,
.page__en .messagePopup__content__form__place {
  padding-top: 1.875rem; }
  .page__en .loginPopup__content__form__place .placeComponent .dropdown,
  .page__en .fpwPopup__content__form__place .placeComponent .dropdown,
  .page__en .changePwPopup__content__form__place .placeComponent .dropdown,
  .page__en .signupPopup__content__form__place .placeComponent .dropdown,
  .page__en .messagePopup__content__form__place .placeComponent .dropdown {
    padding-left: 0 !important;
    padding-right: 1.25rem; }

.page__en .loginPopup__content__form__field label.sendCode__forget,
.page__en .fpwPopup__content__form__field label.sendCode__forget,
.page__en .changePwPopup__content__form__field label.sendCode__forget,
.page__en .signupPopup__content__form__field label.sendCode__forget,
.page__en .messagePopup__content__form__field label.sendCode__forget {
  text-align: right; }

.page__en .loginPopup__content__form__field.password .show-hide-password,
.page__en .fpwPopup__content__form__field.password .show-hide-password,
.page__en .changePwPopup__content__form__field.password .show-hide-password,
.page__en .signupPopup__content__form__field.password .show-hide-password,
.page__en .messagePopup__content__form__field.password .show-hide-password {
  right: 5px;
  left: auto; }
  .page__en .loginPopup__content__form__field.password .show-hide-password:after,
  .page__en .fpwPopup__content__form__field.password .show-hide-password:after,
  .page__en .changePwPopup__content__form__field.password .show-hide-password:after,
  .page__en .signupPopup__content__form__field.password .show-hide-password:after,
  .page__en .messagePopup__content__form__field.password .show-hide-password:after {
    right: 7px;
    bottom: 1px;
    left: auto; }

.page__en .loginPopup__content__form .pwTitleForget__forget,
.page__en .fpwPopup__content__form .pwTitleForget__forget,
.page__en .changePwPopup__content__form .pwTitleForget__forget,
.page__en .signupPopup__content__form .pwTitleForget__forget,
.page__en .messagePopup__content__form .pwTitleForget__forget {
  text-align: right; }

.page__en .loginPopup__content__form img.resendcode,
.page__en .fpwPopup__content__form img.resendcode,
.page__en .changePwPopup__content__form img.resendcode,
.page__en .signupPopup__content__form img.resendcode,
.page__en .messagePopup__content__form img.resendcode {
  margin-right: 0;
  margin-left: 20px; }
  @media (max-width: 991.98px) {
    .page__en .loginPopup__content__form img.resendcode,
    .page__en .fpwPopup__content__form img.resendcode,
    .page__en .changePwPopup__content__form img.resendcode,
    .page__en .signupPopup__content__form img.resendcode,
    .page__en .messagePopup__content__form img.resendcode {
      margin-left: 10px; } }

.page__en .loginPopup__content__mediaBtns__google a, .page__en .loginPopup__content__mediaBtns__facebook a,
.page__en .fpwPopup__content__mediaBtns__google a,
.page__en .fpwPopup__content__mediaBtns__facebook a,
.page__en .changePwPopup__content__mediaBtns__google a,
.page__en .changePwPopup__content__mediaBtns__facebook a,
.page__en .signupPopup__content__mediaBtns__google a,
.page__en .signupPopup__content__mediaBtns__facebook a,
.page__en .messagePopup__content__mediaBtns__google a,
.page__en .messagePopup__content__mediaBtns__facebook a {
  padding-right: 1.25rem;
  border-right: 1px solid #e6e6e6;
  padding-left: 0;
  border-left: 0; }

.customizationsContent .messagePopup__content__closeIcon {
  cursor: pointer;
  padding: 1.25rem 0;
  width: 100%;
  display: block;
  background-color: #7c7c7b;
  color: #ffffff;
  margin-bottom: 0.9375rem; }

.customizationsContent .messagePopup__content__btns {
  margin-bottom: 1.875rem; }
  .customizationsContent .messagePopup__content__btns .btnStyle:first-of-type {
    padding-left: 0.625rem; }

.page__en .customizationsContent .imagespopUp .closeIcon {
  justify-content: flex-end; }

.page__en .customizationsContent .messagePopup__content__btns .btnStyle:first-of-type {
  padding-right: 0.625rem;
  padding-left: 0; }

.requestTailorPopup .signupPopup__content .time-label {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none; }

@media screen and (min-width: 768px) {
  .requestTailorPopup .signupPopup__content {
    width: 50%; } }

.measurementsGuidePage .tabContainer__title {
  color: #7c7c7b;
  font-size: 2.8125rem;
  font-weight: 300;
  display: block;
  margin-bottom: 3.125rem; }

.measurementsGuidePage .tabContainer nav .nav.nav-tabs {
  border-bottom: 1px solid #ededed;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem; }
  .measurementsGuidePage .tabContainer nav .nav.nav-tabs .nav-item.nav-link {
    color: #8e8e8e;
    background-color: #f8f8f8;
    border-color: none;
    border-radius: 30px;
    width: 18.125rem;
    text-align: center;
    margin-left: 0.1875rem;
    font-size: 1.125rem;
    font-weight: 300;
    color: #5f5d59;
    border: none; }
    @media screen and (max-width: 767.98px) {
      .measurementsGuidePage .tabContainer nav .nav.nav-tabs .nav-item.nav-link {
        width: 50%;
        margin-left: 0; } }
    .measurementsGuidePage .tabContainer nav .nav.nav-tabs .nav-item.nav-link:hover, .measurementsGuidePage .tabContainer nav .nav.nav-tabs .nav-item.nav-link:focus {
      border: none; }
    .measurementsGuidePage .tabContainer nav .nav.nav-tabs .nav-item.nav-link.active {
      color: #ffffff !important;
      background-color: #7c7c7b;
      border-color: none; }

.measurementsGuidePage .tabContainer .tab-content {
  border: none;
  margin-bottom: 8.75rem;
  padding: 0; }

.profilePage * {
  font-family: "Tajawal"; }

.profilePage .formContainer .title {
  color: #7c7c7b;
  font-size: 2.8125rem;
  font-weight: 300;
  display: block;
  margin-bottom: 5rem; }

.profilePage .formContainer h4 {
  color: #7c7c7b;
  font-size: 1.5625rem;
  margin-bottom: 2.1875rem;
  font-weight: normal; }

.profilePage .formContainer form {
  margin-bottom: 10rem; }
  .profilePage .formContainer form .form-group {
    margin-bottom: 3.125rem; }
    .profilePage .formContainer form .form-group label {
      font-size: 0.6875rem;
      font-family: "Tajawal";
      color: #b0b0b0;
      margin-bottom: 0.625rem; }
    .profilePage .formContainer form .form-group input {
      padding: 0.3125rem 0;
      height: auto;
      border: none;
      border-bottom: 1px solid #cbcbcb;
      border-radius: 0; }
      .profilePage .formContainer form .form-group input.mobile {
        direction: ltr; }
  .profilePage .formContainer form .placeComponent {
    margin-bottom: 3.125rem; }
  .profilePage .formContainer form .button {
    width: 100%;
    font-size: 1.25rem;
    font-weight: bold;
    padding: 0.3125rem 1.875rem;
    height: 3.125rem;
    font-family: "Tajawal"; }
  .profilePage .formContainer form .forgetPasswordBtn {
    font-size: 0.6875rem;
    font-family: "Tajawal";
    color: #b0b0b0;
    margin-bottom: 0.625rem;
    padding: 0;
    border: none;
    width: 100%;
    text-align: right;
    /* height: 37px; */
    display: block;
    /* background-color: #e9ecef; */
    padding: 8px 0px;
    line-height: 1.5; }
    @media (max-width: 767.98px) {
      .profilePage .formContainer form .forgetPasswordBtn {
        margin: 0; } }
    .profilePage .formContainer form .forgetPasswordBtn span {
      font-size: 0.875rem;
      color: #8a633e;
      padding-right: 0.625rem; }
  .profilePage .formContainer form .dropdown {
    width: 100%;
    padding-top: 0.4375rem; }
    .profilePage .formContainer form .dropdown label {
      display: block;
      font-size: 0.6875rem;
      font-family: "Tajawal";
      color: #b0b0b0;
      margin-bottom: 0.625rem; }
    .profilePage .formContainer form .dropdown button {
      display: block;
      width: 100%;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #cbcbcb;
      padding: 0.3125rem 0; }
      .profilePage .formContainer form .dropdown button::after {
        display: none; }
      .profilePage .formContainer form .dropdown button span::before {
        color: #7c7c7b;
        font-size: 0.5rem; }
    .profilePage .formContainer form .dropdown .dropdown-menu {
      width: 100%;
      max-height: 12.5rem;
      overflow: auto;
      background: #ffffff; }
  .profilePage .formContainer form .dropdown:first-of-type {
    padding-left: 20px; }
    .profilePage .formContainer form .dropdown:first-of-type .dropdown-menu {
      width: calc(100% - 20px);
      max-height: 12.5rem;
      overflow: auto;
      background: #ffffff; }

.profilePage .modal .modal-dialog {
  display: flex;
  align-items: center;
  height: calc(100% - 56px); }

.profilePage .modal .modal-content .modal-header .close {
  margin: 0;
  padding: 0; }

.profilePage .alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .profilePage .alert img {
    max-width: 2.75rem;
    margin-left: 1rem; }

.profilePage .alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }

.profilePage .alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }

.page__en .alert img {
  margin-left: 0;
  margin-right: 1rem; }

.page__en .formContainer button#submit::after {
  right: -46px;
  left: unset !important;
  transform: rotate(180deg); }
  @media (max-width: 991.98px) {
    .page__en .formContainer button#submit::after {
      right: -45px; } }

.page__en .formContainer form .forgetPasswordBtn {
  text-align: left; }
  .page__en .formContainer form .forgetPasswordBtn span {
    padding-right: 0;
    padding-left: 0.625rem; }

.page__en .formContainer .placeComponent .dropdown:first-of-type {
  padding-left: 0;
  padding-right: 1.25rem; }

.mycartPage .title {
  font-size: 2.8125rem;
  font-weight: 300;
  color: #7c7c7b;
  font-family: "Tajawal";
  margin-bottom: 3.125rem; }

.mycartPage .not-available-card {
  position: relative; }
  .mycartPage .not-available-card .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #ffffff99;
    opacity: 1;
    z-index: 1; }
  .mycartPage .not-available-card .not-available {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background: #151515;
    color: #ffffff; }
    .mycartPage .not-available-card .not-available a {
      margin: 10px;
      color: #8a633e; }
  .mycartPage .not-available-card .productTags {
    opacity: 0.5; }

.mycartPage .productCard {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 1.875rem;
  align-items: flex-start; }
  .mycartPage .productCard .productImgContainer {
    position: relative;
    margin: 0 0 1.875rem 1.875rem;
    width: 100%;
    overflow: hidden; }
    .mycartPage .productCard .productImgContainer .productImg {
      width: 100%;
      max-height: 275px; }
    .mycartPage .productCard .productImgContainer .productTags {
      padding: 0;
      left: 0;
      top: 1.5625rem; }
  .mycartPage .productCard .card-body {
    width: 100%;
    justify-content: space-between;
    align-self: stretch;
    padding: 1.25rem 0 0 0; }
    .mycartPage .productCard .card-body .productSammery {
      display: flex;
      flex-direction: column; }
      .mycartPage .productCard .card-body .productSammery .card-title {
        font-size: 1.375rem;
        color: #7c7c7b;
        cursor: pointer;
        margin-bottom: 1.875rem; }
        .mycartPage .productCard .card-body .productSammery .card-title span {
          font-size: 0.75rem;
          padding-right: 0.625rem;
          color: #808080; }
      .mycartPage .productCard .card-body .productSammery .button.sizeBtn {
        padding: 0.625rem 0.9375rem;
        width: fit-content; }
        .mycartPage .productCard .card-body .productSammery .button.sizeBtn::after {
          left: -32px; }
      .mycartPage .productCard .card-body .productSammery .card-text .sizeSelected,
      .mycartPage .productCard .card-body .productSammery .card-text .incompleteSizeLabel {
        color: #808080;
        font-size: 0.875rem; }
        .mycartPage .productCard .card-body .productSammery .card-text .sizeSelected span,
        .mycartPage .productCard .card-body .productSammery .card-text .incompleteSizeLabel span {
          color: #7c7c7b;
          padding-right: 0.3125rem; }
      .mycartPage .productCard .card-body .productSammery .card-text .noSizeselected {
        color: #dc2626;
        font-size: 0.875rem; }
        .mycartPage .productCard .card-body .productSammery .card-text .noSizeselected a {
          color: #8a633e;
          padding-right: 0.625rem; }
      .mycartPage .productCard .card-body .productSammery .editProduct {
        margin-top: auto;
        margin-bottom: 2rem;
        list-style: none;
        padding: 1.25rem 0 0 0; }
        .mycartPage .productCard .card-body .productSammery .editProduct li {
          display: inline-block; }
          .mycartPage .productCard .card-body .productSammery .editProduct li a {
            color: #808080; }
            .mycartPage .productCard .card-body .productSammery .editProduct li a.card-link.modify, .mycartPage .productCard .card-body .productSammery .editProduct li a.card-link.change-measurement {
              color: #8a633e;
              padding-left: 1rem;
              margin-left: 1rem;
              border-left: 1px solid #808080; }
            .mycartPage .productCard .card-body .productSammery .editProduct li a:hover {
              color: #8a633e; }
        .mycartPage .productCard .card-body .productSammery .editProduct li:first-child {
          padding-right: 0;
          border: none; }
          .mycartPage .productCard .card-body .productSammery .editProduct li:first-child a.card-link.modify {
            color: #8a633e; }
    .mycartPage .productCard .card-body .priceAndquantity {
      display: flex;
      flex-direction: column;
      padding: 0; }
      .mycartPage .productCard .card-body .priceAndquantity .totalPrice {
        color: #8a633e;
        font-size: 1.375rem;
        margin-bottom: 0.625rem; }
        .mycartPage .productCard .card-body .priceAndquantity .totalPrice span {
          padding-left: 0.3125rem; }
      .mycartPage .productCard .card-body .priceAndquantity .price {
        font-size: 0.75rem;
        color: #7c7c7b; }
        .mycartPage .productCard .card-body .priceAndquantity .price span {
          font-weight: bold;
          padding: 0 0.3125rem; }
      .mycartPage .productCard .card-body .priceAndquantity .measurementsCustom {
        margin: auto 0 2rem 0; }
        .mycartPage .productCard .card-body .priceAndquantity .measurementsCustom.sizeSection {
          height: 100%; }
        .mycartPage .productCard .card-body .priceAndquantity .measurementsCustom .title {
          display: none; }
        .mycartPage .productCard .card-body .priceAndquantity .measurementsCustom .spanText {
          display: none; }
        .mycartPage .productCard .card-body .priceAndquantity .measurementsCustom .contentItem {
          padding: 0.3125rem;
          border: 1px solid #f2f2f2; }
          .mycartPage .productCard .card-body .priceAndquantity .measurementsCustom .contentItem .inc {
            cursor: pointer; }
          .mycartPage .productCard .card-body .priceAndquantity .measurementsCustom .contentItem .quantityValue {
            padding: 0 1.25rem;
            margin: 0;
            white-space: nowrap;
            background: none; }
        .mycartPage .productCard .card-body .priceAndquantity .measurementsCustom .radioBtn input {
          margin-left: 0.625rem; }
  @media (min-width: 767px) {
    .mycartPage .productCard .productImgContainer {
      width: 30%; }
      .mycartPage .productCard .productImgContainer .productImg {
        max-height: 220px; }
    .mycartPage .productCard .card-body {
      width: 70%; } }

.mycartPage .editSizeContainer .editSize .measurementsCustom.sizeSection {
  height: 100%; }
  @media screen and (max-width: 767.98px) {
    .mycartPage .editSizeContainer .editSize .measurementsCustom.sizeSection {
      flex-direction: column !important; } }
  @media screen and (max-width: 1199.98px) {
    .mycartPage .editSizeContainer .editSize .measurementsCustom.sizeSection .dropdownContainer {
      flex-direction: column !important;
      align-items: flex-start !important; } }
  .mycartPage .editSizeContainer .editSize .measurementsCustom.sizeSection .dropdownContainer .dropdownForm {
    width: unset;
    margin-left: 0.625rem; }
    .mycartPage .editSizeContainer .editSize .measurementsCustom.sizeSection .dropdownContainer .dropdownForm .dropdown {
      padding: 0.9375rem 0; }
      .mycartPage .editSizeContainer .editSize .measurementsCustom.sizeSection .dropdownContainer .dropdownForm .dropdown .dropdownHeaderCopy {
        padding: 0 0.9375rem 0 2.1875rem;
        position: relative; }
        .mycartPage .editSizeContainer .editSize .measurementsCustom.sizeSection .dropdownContainer .dropdownForm .dropdown .dropdownHeaderCopy::after {
          content: ">";
          transform: rotate(-90deg);
          display: inline-block;
          position: absolute;
          left: 0.9375rem;
          font-size: 1.125rem; }

.orderSammury {
  margin-bottom: 1.875rem;
  background: #f9f9f9 !important;
  height: 100%;
  position: relative; }
  .orderSammury .card-title {
    height: 3.375rem;
    padding-top: 0.875rem !important;
    padding-right: 1.875rem;
    font-size: 1.375rem !important;
    margin-bottom: 1.25rem !important; }
  .orderSammury .card-text {
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 1.875rem;
    margin-bottom: 1.5625rem; }
    @media (max-width: 991.98px) {
      .orderSammury .card-text {
        padding: 0 0.9375rem; } }
    .orderSammury .card-text .title {
      font-size: 1rem;
      padding-left: 0.625rem;
      margin-bottom: 0; }
      .orderSammury .card-text .title span {
        font-size: 0.75rem;
        color: #8a8a8a;
        font-weight: normal;
        padding: 0; }
        .orderSammury .card-text .title span.icon-round-error-symbol {
          cursor: pointer;
          padding: 0 0.3125rem; }
        .orderSammury .card-text .title span.desc {
          display: none;
          background-color: white;
          padding: 0.9375rem;
          border-radius: 5px;
          border: 1px solid white;
          position: absolute;
          z-index: 999999;
          margin-top: 1.25rem; }
    .orderSammury .card-text .price {
      font-size: 1.125rem; }
      .orderSammury .card-text .price span {
        font-size: 1.125rem;
        color: inherit; }
        .orderSammury .card-text .price span small {
          font-size: 0.625rem !important;
          white-space: nowrap;
          padding: 0 0.3125rem; }
  .orderSammury .partnerDiscount {
    color: #ac2431; }
    .orderSammury .partnerDiscount .title {
      color: #ac2431; }
      .orderSammury .partnerDiscount .title span {
        color: #ac2431; }
  .orderSammury hr {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
    margin-bottom: 1.875rem; }
  .orderSammury .total {
    margin-bottom: 3.5rem; }
  .orderSammury .button.card-link {
    margin-right: 1.875rem;
    margin-left: 1.875rem;
    margin-bottom: 5rem;
    width: calc(100% - 60px);
    font-size: 1.25rem;
    font-weight: bold;
    padding: 0.625rem;
    text-align: center;
    display: block; }
    .orderSammury .button.card-link::after {
      display: none; }
    .orderSammury .button.card-link.disabled {
      pointer-events: auto; }

.ithoobPartners {
  margin-bottom: 1.875rem;
  background: transparent; }
  .ithoobPartners .card-header {
    background: transparent;
    padding: 0 1.875rem;
    border: none; }
    .ithoobPartners .card-header div .card-title {
      font-size: 1.375rem;
      color: #7c7c7b;
      font-weight: normal;
      display: inline-block;
      padding-left: 0.625rem;
      margin-bottom: 0; }
      .ithoobPartners .card-header div .card-title:hover {
        cursor: pointer; }
    .ithoobPartners .card-header div a {
      font-size: 0.75rem;
      color: #8a8a8a;
      font-weight: normal; }
    .ithoobPartners .card-header .card-title .icon-arrow-right {
      transform: rotate(90deg);
      padding: 0;
      display: block; }
      .ithoobPartners .card-header .card-title .icon-arrow-right::before {
        color: #7c7c7b !important; }
    .ithoobPartners .card-header .card-title.collapsed .icon-arrow-right {
      transform: rotate(0deg); }
    .ithoobPartners .card-header span {
      padding: 0;
      margin-bottom: 0; }
      .ithoobPartners .card-header span:hover {
        cursor: pointer; }
  .ithoobPartners .card-body {
    padding: 2.5rem 1.875rem 0; }
  .ithoobPartners .card-title {
    font-weight: bold;
    font-size: 1.5rem; }
    .ithoobPartners .card-title span {
      font-size: 1rem;
      padding-right: 0.3125rem;
      font-weight: bold;
      color: #8a8a8a; }
  .ithoobPartners .button {
    padding: 0.375rem 0.75rem;
    background: #7c7c7b !important;
    border-bottom: 0;
    color: #ffffff !important; }
    .ithoobPartners .button::after {
      display: none; }
  .ithoobPartners .form-group {
    flex-grow: 1;
    width: calc(100% - 65px); }
  .ithoobPartners input.form-control {
    max-width: 100% !important;
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid #cbcbcb;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none; }

#partnersModel .modal-dialog {
  height: calc(100% - 56px);
  display: flex;
  align-items: center; }
  #partnersModel .modal-dialog .modal-content .modal-header {
    align-items: center; }
    #partnersModel .modal-dialog .modal-content .modal-header button {
      padding: 0;
      margin-left: 0; }
  #partnersModel .modal-dialog .modal-content .modal-body {
    overflow: auto; }

.orderSummaryContainer {
  position: relative;
  height: 100%;
  background: #f9f9f9 !important; }
  .orderSummaryContainer .orderSammury {
    height: unset; }
  .orderSummaryContainer .ithoobPartnersContainer {
    position: absolute;
    width: 100%;
    margin-top: 4.375rem; }
    @media (max-width: 991.98px) {
      .orderSummaryContainer .ithoobPartnersContainer {
        position: relative; } }
    .orderSummaryContainer .ithoobPartnersContainer::after {
      content: "";
      background: url("images/add-measurement/before-bg.png");
      height: 1.75rem;
      position: absolute;
      top: -70px;
      right: 0;
      width: calc(100% + 13px);
      right: -13px;
      background-position: right center;
      background-repeat: no-repeat;
      background-size: cover; }
  .orderSummaryContainer:empty {
    background: none !important; }

.editSize,
.customsDiv {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  background: white;
  z-index: 1001;
  padding: 20px 30px;
  height: 80%;
  display: flex;
  flex-direction: column; }
  @media (max-width: 991.98px) {
    .editSize,
    .customsDiv {
      width: 100%; } }
  .editSize .mainTitle,
  .customsDiv .mainTitle {
    color: #06133b;
    font-size: 1.25rem;
    font-weight: bold;
    display: block;
    margin-bottom: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .editSize .mainTitle span,
    .customsDiv .mainTitle span {
      font-size: 1rem;
      color: #8a8a8a; }
      .editSize .mainTitle span:hover,
      .customsDiv .mainTitle span:hover {
        cursor: pointer; }
  .editSize .button,
  .customsDiv .button {
    margin-top: auto;
    width: calc(100% - 46px) !important;
    margin-right: auto;
    margin-left: auto; }

.customsDiv {
  top: auto;
  bottom: 0; }
  .customsDiv .colorsAndCustoms {
    overflow: auto; }
  .customsDiv .colors {
    margin-bottom: 1.875rem; }
    .customsDiv .colors label {
      font-family: "Tajawal";
      color: #7c7c7b;
      font-size: 0.875rem;
      font-weight: 300;
      padding-left: 1.5625rem;
      margin-bottom: 0; }
    .customsDiv .colors img {
      border-radius: unset;
      margin-right: 0.625rem;
      padding: 0.25rem;
      border: 1px solid #9e9e9e; }
      .customsDiv .colors img.active {
        border: 1px solid #8a633e; }
  .customsDiv .costums h4 {
    font-weight: 300;
    font-size: 0.875rem;
    color: #7c7c7b;
    margin-bottom: 1.25rem; }
  .customsDiv .costums .customsBlock {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem; }
    .customsDiv .costums .customsBlock label {
      margin-bottom: 0;
      margin-left: 1.5625rem;
      font-size: 0.75rem;
      font-weight: 300;
      position: relative;
      font-family: "Tajawal";
      display: block;
      padding-right: 15px; }
      .customsDiv .costums .customsBlock label::after {
        content: "";
        width: 0.3125rem;
        height: 0.3125rem;
        background: #7c7c7b;
        border-radius: 100%; }
    .customsDiv .costums .customsBlock .images .image img {
      padding: 0.25rem;
      border: 1px solid #9e9e9e; }
      .customsDiv .costums .customsBlock .images .image img.active {
        border: 1px solid #8a633e; }
    .customsDiv .costums .customsBlock .images .image .tickCircle {
      display: none; }

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  background: #7c7c7b;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  z-index: 1000; }

.modal .modal-dialog {
  display: flex;
  align-items: center;
  height: calc(100% - 56px); }
  .modal .modal-dialog .modal-content .modal-header button {
    margin-left: 0;
    margin-right: auto; }

.page__en .orderSammury .card-title {
  padding-right: 0;
  padding-left: 1.875rem; }

.page__en .editSize,
.page__en .customsDiv {
  top: 0;
  left: 0; }

.page__en.mycartPage .editSize button::after {
  right: -46px;
  left: unset !important;
  transform: rotate(180deg); }
  @media (max-width: 991.98px) {
    .page__en.mycartPage .editSize button::after {
      right: -45px; } }

.page__en.mycartPage .orderSummaryContainer .orderSammury .card-text .title {
  padding-left: 0;
  padding-right: 0.625rem; }

.page__en.mycartPage .productCard .productImgContainer {
  margin-left: 0;
  margin-right: 1.875rem; }
  .page__en.mycartPage .productCard .productImgContainer .productTags {
    left: auto;
    right: 0; }
    .page__en.mycartPage .productCard .productImgContainer .productTags .tagSpan {
      right: 0;
      left: auto;
      margin-right: 0;
      margin-left: auto; }

.page__en.mycartPage .productCard .card-body {
  padding-left: 0;
  padding-right: 2.5rem; }
  .page__en.mycartPage .productCard .card-body .productSammery button.sizeBtn::after {
    right: -46px;
    left: unset !important;
    transform: rotate(180deg); }
    @media (max-width: 991.98px) {
      .page__en.mycartPage .productCard .card-body .productSammery button.sizeBtn::after {
        right: -45px; } }
  .page__en.mycartPage .productCard .card-body .productSammery .card-title span {
    padding-right: 0;
    padding-left: 0.625rem; }
  .page__en.mycartPage .productCard .card-body .productSammery .card-text.total .title {
    padding-left: 0;
    padding-right: 0.625rem; }
  .page__en.mycartPage .productCard .card-body .productSammery .card-text .sizeSelected span {
    padding-right: 0;
    padding-left: 0.3125rem; }
  .page__en.mycartPage .productCard .card-body .productSammery .card-text .noSizeselected a {
    padding-right: 0;
    padding-left: 0.625rem; }
  .page__en.mycartPage .productCard .card-body .productSammery .editProduct li:last-child a {
    border-right: 0;
    padding-right: 0;
    margin-right: 0; }
  .page__en.mycartPage .productCard .card-body .productSammery .editProduct li a {
    padding-left: 0;
    margin-left: 0;
    border-left: 0;
    padding-right: 1rem;
    margin-right: 1rem;
    border-right: 1px solid #808080; }
    .page__en.mycartPage .productCard .card-body .productSammery .editProduct li a.card-link.modify, .page__en.mycartPage .productCard .card-body .productSammery .editProduct li a.card-link.change-measurement {
      padding-left: 0;
      margin-left: 0;
      border-left: 0;
      color: #8a633e; }
  .page__en.mycartPage .productCard .card-body .priceAndquantity {
    align-items: flex-end; }
    .page__en.mycartPage .productCard .card-body .priceAndquantity .totalPrice span {
      padding-left: 0;
      padding-right: 0.3125rem; }

.page__en.mycartPage .ithoobPartnersContainer .card-title {
  padding-left: 0 !important;
  padding-right: 0.625rem; }
  .page__en.mycartPage .ithoobPartnersContainer .card-title span {
    transform: rotate(180deg); }

.page__en.mycartPage .ithoobPartnersContainer span.card-title {
  padding-right: 0; }

.productSize {
  text-transform: uppercase; }

span .close {
  text-align: left;
  cursor: pointer;
  display: inline-block; }

span.outOfCoverageDesc {
  display: none;
  padding: 30px;
  background-color: #f1f1f1;
  position: absolute;
  bottom: -70px;
  z-index: 99;
  margin: 16px;
  border: 1px solid #e0dcdc; }

.ordersPage .emptyOrdersMsg {
  display: none; }

.ordersPage .bahijFont {
  font-family: "Tajawal" !important;
  color: #8a8a8a !important;
  word-break: break-word; }

.ordersPage .card-header {
  cursor: pointer; }
  .ordersPage .card-header .icon-arrow:before {
    color: #7c7c7b; }
  .ordersPage .card-header h5 {
    padding: 0.9375rem 1.25rem;
    align-items: center;
    background-color: #f3f3f3; }
    .ordersPage .card-header h5:first-child {
      margin-top: 1.25rem; }
    @media screen and (max-width: 767.98px) {
      .ordersPage .card-header h5 p {
        font-size: 0.875rem !important; } }
  .ordersPage .card-header p.redP {
    color: #e62323; }
  .ordersPage .card-header p.greenP {
    color: #27b548; }
  .ordersPage .card-header p.yellowP {
    color: #d49816; }

.ordersPage__emptyOrders {
  padding: 1.5625rem 0.9375rem;
  margin: 1.5625rem 0;
  background-color: #f3f3f3;
  border-radius: 30px; }

.ordersPage__cycle {
  margin-bottom: 1.5625rem;
  padding-bottom: 2.5rem;
  padding-top: 20px;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  position: relative; }
  .ordersPage__cycle .greyLine {
    background-color: #f3f3f3;
    content: "";
    height: 8px;
    width: 100%;
    align-self: flex-start;
    margin-top: 24px;
    z-index: 1 !important; }
  .ordersPage__cycle .greenLine {
    background-color: #27b548;
    content: "";
    height: 8px;
    width: 100%;
    align-self: flex-start;
    margin-top: 24px;
    z-index: 1 !important; }
  .ordersPage__cycle .redLine {
    background-color: #e62323;
    content: "";
    height: 8px;
    width: 100%;
    align-self: flex-start;
    margin-top: 24px;
    z-index: 1 !important; }
  .ordersPage__cycle .iconBox {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .ordersPage__cycle .iconBox.leftIcon.greenIconLine:after, .ordersPage__cycle .iconBox.leftIcon.greenIconLine:before, .ordersPage__cycle .iconBox.rightIcon.greenIconLine:after, .ordersPage__cycle .iconBox.rightIcon.greenIconLine:before, .ordersPage__cycle .iconBox.midIcon.greenIconLine:after, .ordersPage__cycle .iconBox.midIcon.greenIconLine:before {
      background-color: #27b548; }
    .ordersPage__cycle .iconBox.leftIcon.redIconLine:before, .ordersPage__cycle .iconBox.leftIcon.redIconLine:after, .ordersPage__cycle .iconBox.rightIcon.redIconLine:before, .ordersPage__cycle .iconBox.rightIcon.redIconLine:after, .ordersPage__cycle .iconBox.midIcon.redIconLine:before, .ordersPage__cycle .iconBox.midIcon.redIconLine:after {
      background-color: #e62323; }
    .ordersPage__cycle .iconBox.leftIcon.greyIconLine:before, .ordersPage__cycle .iconBox.leftIcon.greyIconLine:after, .ordersPage__cycle .iconBox.rightIcon.greyIconLine:before, .ordersPage__cycle .iconBox.rightIcon.greyIconLine:after, .ordersPage__cycle .iconBox.midIcon.greyIconLine:before, .ordersPage__cycle .iconBox.midIcon.greyIconLine:after {
      background-color: #f3f3f3; }
    .ordersPage__cycle .iconBox.leftIcon:after {
      content: "";
      position: absolute;
      height: 8px;
      width: 55%;
      top: 24px;
      left: 50%;
      z-index: -4; }
    .ordersPage__cycle .iconBox.rightIcon:before {
      content: "";
      position: absolute;
      height: 8px;
      width: 55%;
      top: 24px;
      right: 50%;
      z-index: -4; }
    .ordersPage__cycle .iconBox.rightIcon.greenIconLine:before {
      background-color: #27b548; }
    .ordersPage__cycle .iconBox.midIcon:before {
      position: absolute;
      height: 8px;
      width: 55%;
      top: 24px;
      left: 50%;
      z-index: -4;
      content: ""; }
    .ordersPage__cycle .iconBox.midIcon:after {
      position: absolute;
      height: 8px;
      width: 55%;
      top: 24px;
      right: 50%;
      z-index: -4;
      content: ""; }
    .ordersPage__cycle .iconBox span.icon-sewmachine, .ordersPage__cycle .iconBox span.icon-delivery, .ordersPage__cycle .iconBox span.icon-branch {
      font-size: 1.5625rem; }
    .ordersPage__cycle .iconBox span.iconPadding {
      padding: 0.9375rem;
      border-radius: 50%; }
    .ordersPage__cycle .iconBox span.iconGrey:before {
      color: #939393; }
    .ordersPage__cycle .iconBox span.iconGreen:before {
      color: #27b548; }
    .ordersPage__cycle .iconBox .iconGreenBg {
      background-color: #27b548;
      padding: 1.25rem; }
    .ordersPage__cycle .iconBox .iconRedBg {
      color: #ffffff;
      background-color: #e62323;
      padding: 1.25rem; }
    .ordersPage__cycle .iconBox .iconGreenBg,
    .ordersPage__cycle .iconBox .iconRedBg {
      border-radius: 50%; }
    .ordersPage__cycle .iconBox .greyBg {
      background-color: #f3f3f3; }
      .ordersPage__cycle .iconBox .greyBg.iconGreen {
        border: 1px solid #27b548;
        border-radius: 50%; }
  .ordersPage__cycle.cancelled {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    .ordersPage__cycle.cancelled > div {
      position: relative;
      z-index: 2; }
      .ordersPage__cycle.cancelled > div p {
        margin-top: 0.9375rem;
        padding-right: 0 !important;
        text-align: center;
        white-space: nowrap; }
  .ordersPage__cycle.homeDelivery, .ordersPage__cycle.branchDelivery {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: end; }
    .ordersPage__cycle.homeDelivery > div, .ordersPage__cycle.branchDelivery > div {
      position: relative;
      z-index: 2; }
      .ordersPage__cycle.homeDelivery > div p, .ordersPage__cycle.branchDelivery > div p {
        margin-top: 0.9375rem;
        padding-right: 0 !important;
        text-align: center;
        white-space: nowrap; }
        @media (max-width: 991.98px) {
          .ordersPage__cycle.homeDelivery > div p, .ordersPage__cycle.branchDelivery > div p {
            font-size: 0.75rem; } }
        @media (max-width: 767.98px) {
          .ordersPage__cycle.homeDelivery > div p, .ordersPage__cycle.branchDelivery > div p {
            font-size: 0.60625rem; } }

.ordersPage__filters ul {
  padding-right: 0;
  padding-left: 0; }
  @media (max-width: 767.98px) {
    .ordersPage__filters ul {
      display: flex;
      flex-wrap: wrap; } }
  .ordersPage__filters ul li {
    width: 24.6%;
    color: #8e8e8e;
    font-size: 0.9375rem;
    font-weight: normal;
    display: inline-block;
    margin-left: 1.25rem;
    padding: 0.9375rem 0;
    background: #f3f3f3;
    margin-left: 0.3125rem;
    text-align: center;
    cursor: pointer;
    border-radius: 30px; }
    .ordersPage__filters ul li:last-child {
      margin-left: 0; }
    .ordersPage__filters ul li:hover {
      text-decoration: none; }
    .ordersPage__filters ul li.active {
      background-color: #7c7c7b;
      color: white;
      cursor: default; }
    @media (max-width: 767.98px) {
      .ordersPage__filters ul li {
        padding: 0.9375rem 1.875rem;
        margin-bottom: 0.3125rem;
        width: 48%; } }

.ordersPage__btns {
  padding: 1.5625rem 0;
  width: 100%; }
  @media (max-width: 991.98px) {
    .ordersPage__btns {
      width: 85%;
      margin: 0 auto; } }
  .ordersPage__btns .btnStyle {
    margin-right: 1.875rem; }
    .ordersPage__btns .btnStyle a {
      text-align: center; }
    .ordersPage__btns .btnStyle:last-child a {
      border: 1px solid #7c7c7b !important;
      background-color: #ffffff !important;
      color: #7c7c7b !important; }

.ordersPage__orders__orderItem {
  position: relative;
  flex-wrap: wrap;
  border-bottom: 1px solid #e4e4e4;
  padding: 2.1875rem 0;
  margin-right: 1.25rem;
  margin-left: 1.25rem; }
  .ordersPage__orders__orderItem__details__image {
    width: 50%;
    position: relative;
    height: auto; }
    .ordersPage__orders__orderItem__details__image img {
      width: 100%;
      max-height: 160px; }
    .ordersPage__orders__orderItem__details__image .discountTag {
      position: absolute;
      left: 0.625rem;
      top: 0.625rem;
      z-index: 2; }
      .ordersPage__orders__orderItem__details__image .discountTag span {
        text-align: center;
        background-color: #e62323;
        font-size: 0.9375rem;
        color: #ffffff;
        width: 100%;
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.3125rem; }
  .ordersPage__orders__orderItem__details__info {
    height: 100%;
    width: 50%;
    padding: 1rem; }
    .ordersPage__orders__orderItem__details__info h4 {
      margin-bottom: 1rem;
      font-weight: bold;
      font-size: 1.3rem; }
      .ordersPage__orders__orderItem__details__info h4 span {
        font-size: 1rem;
        padding-right: 0.3125rem;
        font-weight: bold;
        color: #8a8a8a; }
    .ordersPage__orders__orderItem__details__info h5 {
      margin-bottom: 1rem;
      font-size: 0.875rem;
      color: #333333;
      padding: 0 !important; }
    .ordersPage__orders__orderItem__details__info a {
      font-size: 0.75rem;
      font-family: "Tajawal";
      color: #d49816 !important;
      text-decoration: none;
      cursor: pointer; }
    .ordersPage__orders__orderItem__details__info .price p {
      font-size: 1.25rem;
      color: #d49816;
      text-align: right; }
      .ordersPage__orders__orderItem__details__info .price p.onePiece {
        color: #8e8e8e !important;
        font-size: 0.6875rem;
        padding: 0.3125rem; }
  @media (min-width: 767px) {
    .ordersPage__orders__orderItem__details {
      width: 80%; }
      .ordersPage__orders__orderItem__details__image {
        width: 325px; }
        .ordersPage__orders__orderItem__details__image img {
          width: 100%;
          max-width: 190px;
          max-height: 190px; }
        .ordersPage__orders__orderItem__details__image .discountTag {
          left: 15%; }
      .ordersPage__orders__orderItem__details__info {
        width: 80%;
        padding: 0 10px; }
        .ordersPage__orders__orderItem__details__info .price {
          position: absolute;
          top: 32px;
          left: 0;
          width: 25%; }
          .ordersPage__orders__orderItem__details__info .price p {
            text-align: left; } }

.ordersPage__orders__orderItem:last-child {
  border-bottom: 0; }

.ordersPage__details__payment {
  position: relative; }
  .ordersPage__details__payment::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 1px;
    width: 100%;
    height: 10px;
    background: #fcfcfc; }

.ordersPage__details__summery, .ordersPage__details__payment {
  background-color: #f3f3f3;
  padding: 1.25rem 1.25rem;
  margin-bottom: 0.625rem; }
  .ordersPage__details__summery h3, .ordersPage__details__payment h3 {
    font-size: 1.5625rem;
    color: #7c7c7b;
    margin-bottom: 0.9375rem; }
  .ordersPage__details__summery p, .ordersPage__details__payment p {
    font-size: 1rem;
    color: #333333;
    font-weight: 400; }
    .ordersPage__details__summery p.smaltitle, .ordersPage__details__payment p.smaltitle {
      margin-bottom: 0.625rem;
      font-weight: 600; }
    .ordersPage__details__summery p span, .ordersPage__details__payment p span {
      color: #8e8e8e;
      font-weight: 300;
      font-size: 0.6875rem; }
      .ordersPage__details__summery p span.icon-round-error-symbol, .ordersPage__details__payment p span.icon-round-error-symbol {
        cursor: pointer;
        padding: 0.3125rem; }
      .ordersPage__details__summery p span.desc, .ordersPage__details__payment p span.desc {
        display: none;
        background-color: white;
        padding: 0.9375rem;
        border-radius: 5px;
        border: 1px solid white;
        position: absolute;
        z-index: 999999;
        top: 0.9375rem;
        margin-top: 0.3125rem; }
  .ordersPage__details__summery__total, .ordersPage__details__summery__delivery, .ordersPage__details__summery__costSizeMan, .ordersPage__details__payment__total, .ordersPage__details__payment__delivery, .ordersPage__details__payment__costSizeMan {
    margin-bottom: 0.625rem; }
  .ordersPage__details__summery__delivery, .ordersPage__details__summery__costSizeMan, .ordersPage__details__payment__delivery, .ordersPage__details__payment__costSizeMan {
    position: relative; }
  .ordersPage__details__summery__expectedTotal, .ordersPage__details__payment__expectedTotal {
    border-top: 1px solid #e7e5e5;
    margin: 0 1.25rem; }
    .ordersPage__details__summery__expectedTotal p, .ordersPage__details__payment__expectedTotal p {
      padding-top: 1.25rem !important;
      font-size: 1.25rem;
      color: #7c7c7b;
      font-weight: 500;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-bottom: 1.25rem !important; }

.ordersPage__details__summery {
  margin-bottom: 0; }
  .ordersPage__details__summery p:nth-of-type(2) {
    text-align: left; }

.ordersPage__details__payment {
  margin-bottom: 10px; }

.ordersPage__details__summery, .ordersPage__details__payment {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px; }

.ordersPage__cancelation {
  border-top: 1px solid #e7e5e5;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  .ordersPage__cancelation p {
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    color: #e62323;
    margin-top: 1.25rem;
    background-color: #e7e5e5;
    padding: 0.9375rem 0 !important;
    width: 80%;
    border-radius: 30px; }

@media (min-width: 767px) {
  .page__en .ordersPage__orders__orderItem__details__info .price {
    left: auto;
    right: 0; }
    .page__en .ordersPage__orders__orderItem__details__info .price p {
      text-align: right; } }

.page__en .ordersPage__orders__orderItem__details__image {
  margin-left: 0;
  margin-right: 0.8125rem; }

.page__en .ordersPage .button.btnVersa {
  margin-left: 0 !important; }
  .page__en .ordersPage .button.btnVersa::after {
    transform: rotate(0deg);
    left: -35px;
    right: unset !important; }

@media (max-width: 767.98px) {
  .page__en .ordersPage .card-header h5 {
    padding: 0.9375rem; } }

.page__en .ordersPage .card-header h5 p {
  text-align: end; }

.page__en .ordersPage__details__summery .button::after,
.page__en .ordersPage__details__payment .button::after {
  right: -45px;
  left: unset !important;
  transform: rotate(180deg); }

.page__en .ordersPage__cycle .iconBox.rightIcon::before {
  left: 50%;
  right: unset !important; }

.page__en .ordersPage__cycle .iconBox.leftIcon::after {
  right: 50%;
  left: unset !important; }

.page__en .ordersPage__filters ul li:first-child {
  margin-left: 0 !important; }

.page__en .ordersPage__filters ul li:last-child {
  margin-left: 0.3125rem !important; }

.page__en .buttonVersa {
  margin-left: 0.625rem !important;
  margin-right: 0 !important;
  border: 1px solid #7c7c7b;
  border-right: 0; }
  .page__en .buttonVersa::after {
    right: -35px;
    transform: scaleX(-1);
    left: unset !important; }

.checkoutPage .title {
  font-size: 2.8125rem;
  font-weight: 300;
  color: #7c7c7b;
  font-family: "Tajawal";
  margin-bottom: 3.125rem; }
  @media (max-width: 767.98px) {
    .checkoutPage .title {
      font-size: 1.875rem;
      margin-bottom: 1.25rem; } }

.checkoutPage .card {
  border-radius: 30px;
  background: #f9f9f9; }
  .checkoutPage .card-body {
    padding: 1.875rem 2.5rem; }
    @media (max-width: 991.98px) {
      .checkoutPage .card-body {
        padding: 1.875rem 0.625rem; } }
  .checkoutPage .card-title {
    font-size: 1.125rem; }
    .checkoutPage .card-title:nth-of-type(2) {
      margin-top: 1.5625rem; }
  .checkoutPage .card-text {
    font-size: 0.875rem; }
    .checkoutPage .card-text span {
      color: #6f6f6f;
      font-size: 0.6875rem;
      padding-right: 0.625rem; }

.checkoutPage .receiving,
.checkoutPage .payment,
.checkoutPage .delivery {
  margin-bottom: 3.125rem; }
  .checkoutPage .receiving h4,
  .checkoutPage .payment h4,
  .checkoutPage .delivery h4 {
    font-size: 1.5625rem;
    color: #7c7c7b;
    margin-bottom: 1.5625rem; }
  @media (max-width: 767.98px) {
    .checkoutPage .receiving,
    .checkoutPage .payment,
    .checkoutPage .delivery {
      margin-bottom: 1.25rem; }
      .checkoutPage .receiving h4,
      .checkoutPage .payment h4,
      .checkoutPage .delivery h4 {
        font-size: 1.25rem;
        margin-bottom: 0.9375rem; } }

.checkoutPage .delivery * {
  border: none;
  border-radius: 0; }

.checkoutPage .delivery nav .nav.nav-tabs .nav-item.nav-link {
  color: #8e8e8e;
  background-color: #fcfcfc;
  font-family: "Tajawal";
  border: none;
  text-align: center;
  font-size: 1em;
  font-weight: 300;
  border-radius: 24px;
  padding: 10px !important; }
  @media (max-width: 767.98px) {
    .checkoutPage .delivery nav .nav.nav-tabs .nav-item.nav-link {
      width: 50%;
      padding: 0.5rem;
      font-size: 0.75rem; } }

.checkoutPage .delivery nav .nav.nav-tabs .nav-item.nav-link.active {
  color: #ffffff !important;
  background-color: #7c7c7b; }

.checkoutPage .delivery .tab-content {
  padding-top: 1.25rem;
  padding-bottom: 0.9375rem; }
  .checkoutPage .delivery .tab-content label,
  .checkoutPage .delivery .tab-content h6 {
    font-size: 0.9375rem;
    font-weight: 300;
    font-family: "Tajawal";
    color: #7c7c7b; }
  .checkoutPage .delivery .tab-content label span {
    display: none;
    font-size: 0.875rem; }
    .checkoutPage .delivery .tab-content label span.showAlert {
      color: #e62323; }
  .checkoutPage .delivery .tab-content input, .checkoutPage .delivery .tab-content textarea, .checkoutPage .delivery .tab-content select {
    color: #7c7c7b;
    font-size: 0.875rem;
    border: 1px solid #3a3939;
    border-radius: 10px;
    margin: 5px 0; }
    .checkoutPage .delivery .tab-content input.alert, .checkoutPage .delivery .tab-content textarea.alert, .checkoutPage .delivery .tab-content select.alert {
      border-bottom: 1px solid #e62323; }
    .checkoutPage .delivery .tab-content input:focus, .checkoutPage .delivery .tab-content textarea:focus, .checkoutPage .delivery .tab-content select:focus {
      outline: none;
      box-shadow: none; }

.checkoutPage .payment .banksInfo div.bankBranch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.3125rem; }
  .checkoutPage .payment .banksInfo div.bankBranch .bankName {
    min-width: 9.375rem;
    font-size: 0.875rem;
    font-weight: bold; }
  .checkoutPage .payment .banksInfo div.bankBranch .accountNo {
    color: #6f6f6f;
    font-size: 0.875rem; }
    .checkoutPage .payment .banksInfo div.bankBranch .accountNo span {
      padding-left: 0.3125rem;
      padding-right: 0.3125rem; }

.checkoutPage .alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .checkoutPage .alert img {
    max-width: 2.75rem; }

.checkoutPage .alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }

.checkoutPage .alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }

.checkoutPage .modal .modal-dialog {
  display: flex;
  align-items: center;
  height: calc(100% - 56px); }

.checkoutPage .modal .modal-content .modal-header .close {
  margin: 0;
  padding: 0; }

@media (max-width: 767.98px) {
  .checkoutPage .modal-footer .button {
    padding: 0.5rem;
    font-size: 13px; } }

@media (max-width: 767.98px) {
  .checkoutPage .modal-footer {
    margin: 0 0.3125rem; } }

@media (max-width: 767.98px) {
  .checkoutPage .modal-body,
  .checkoutPage .modal-footer {
    padding: 1.5625rem 1.25rem; } }

.page__en .card-text span {
  padding-right: 0;
  padding-left: 0.625rem; }

.page__en.checkoutPage .orderSammury .card-title.button::after, .page__en.mycartPage .orderSammury .card-title.button::after {
  right: -46px;
  left: unset !important;
  transform: rotate(180deg);
  pointer-events: none; }
  @media (max-width: 991.98px) {
    .page__en.checkoutPage .orderSammury .card-title.button::after, .page__en.mycartPage .orderSammury .card-title.button::after {
      right: -45px; } }

@media (max-width: 767.98px) {
  .page__en .modal-footer .button {
    min-height: 54px;
    display: flex;
    justify-content: center;
    align-items: center; } }

.measurementsGuidePage h3.title {
  font-size: 2.8125rem;
  font-weight: 300;
  color: #7c7c7b;
  margin-bottom: 3.75rem; }

.measurementsGuidePage p.subTitle {
  font-size: 0.9375rem;
  margin-bottom: 0.9375rem; }

.measurementsGuidePage .toAddMeasurement {
  padding: 1rem 1.5rem;
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 1.25rem; }

.measurementsGuidePage .measurementList ul {
  padding: 0; }
  .measurementsGuidePage .measurementList ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.9375rem;
    background: #f8f8f8;
    padding: 2.5rem 1.5rem;
    position: relative;
    flex-wrap: wrap;
    border-radius: 40px; }
    @media (max-width: 575.98px) {
      .measurementsGuidePage .measurementList ul li {
        flex-direction: column;
        align-items: flex-end; } }
    .measurementsGuidePage .measurementList ul li * {
      display: block; }
    .measurementsGuidePage .measurementList ul li img {
      margin-left: 2.5rem; }
      @media (max-width: 767.98px) {
        .measurementsGuidePage .measurementList ul li img {
          margin-bottom: 0.625rem; } }
      @media (max-width: 767.98px) {
        .measurementsGuidePage .measurementList ul li img {
          margin-left: 1rem; } }
    .measurementsGuidePage .measurementList ul li .sizeTitle {
      min-width: 8.5rem; }
      @media (max-width: 767.98px) {
        .measurementsGuidePage .measurementList ul li .sizeTitle {
          margin-bottom: 0.625rem; } }
      .measurementsGuidePage .measurementList ul li .sizeTitle p {
        font-size: 1.375rem;
        word-break: break-word; }
      .measurementsGuidePage .measurementList ul li .sizeTitle span,
      .measurementsGuidePage .measurementList ul li .sizeTitle a {
        display: inline-block;
        font-size: 0.75rem;
        color: #989898;
        cursor: pointer; }
        .measurementsGuidePage .measurementList ul li .sizeTitle span::before,
        .measurementsGuidePage .measurementList ul li .sizeTitle a::before {
          content: "|";
          padding: 0 5px; }
      .measurementsGuidePage .measurementList ul li .sizeTitle a::before {
        display: none; }
    .measurementsGuidePage .measurementList ul li .progressSize {
      display: flex;
      align-items: center; }
      .measurementsGuidePage .measurementList ul li .progressSize .progressBar {
        width: 5rem;
        background: #dedede;
        height: 0.9375rem;
        position: relative;
        border-radius: 10px; }
        .measurementsGuidePage .measurementList ul li .progressSize .progressBar span {
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          background: #7c7c7b;
          border-radius: 10px; }
        @media (min-width: 450px) {
          .measurementsGuidePage .measurementList ul li .progressSize .progressBar {
            width: 10rem; } }
      .measurementsGuidePage .measurementList ul li .progressSize .amount {
        position: static;
        margin-right: 1.25rem;
        font-size: 0.75rem; }
    .measurementsGuidePage .measurementList ul li .lastUpdate {
      color: #989898;
      font-size: 0.75rem;
      display: flex; }
      .measurementsGuidePage .measurementList ul li .lastUpdate span {
        padding-right: 0.3125rem; }
    .measurementsGuidePage .measurementList ul li .defaultSize {
      position: absolute;
      top: 20px;
      left: 20px;
      background: #8a633e;
      color: #ffffff;
      font-size: 0.6875rem;
      padding: 0.3rem 0.5rem;
      border-radius: 20px; }

@media (min-width: 768px) {
  .measurementsGuidePage .measurementList ul li {
    width: 100%; }
    .measurementsGuidePage .measurementList ul li img {
      width: 10%;
      margin-left: 5% !important; }
    .measurementsGuidePage .measurementList ul li .progressSize {
      width: 30%; }
    .measurementsGuidePage .measurementList ul li .sizeTitle {
      width: 25%; }
    .measurementsGuidePage .measurementList ul li .lastUpdate {
      width: 27%;
      justify-content: flex-end;
      margin-right: 2%; } }

.page__en.measurementsGuidePage .measurementList ul li img {
  margin-right: 2.5rem;
  margin-left: 0;
  transform: scaleX(-1); }
  @media (max-width: 767.98px) {
    .page__en.measurementsGuidePage .measurementList ul li img {
      margin-right: 1rem; } }

.page__en.measurementsGuidePage .measurementList ul li .amount {
  margin-right: 0;
  margin-left: 1.25rem; }

.page__en.measurementsGuidePage .measurementList ul li .lastUpdate {
  margin-right: unset !important;
  margin-left: 2%; }
  .page__en.measurementsGuidePage .measurementList ul li .lastUpdate span {
    margin-left: 4px;
    padding-right: 0; }

.page__en.measurementsGuidePage .measurementList ul li .defaultSize {
  right: 20px;
  left: auto !important; }
  .page__en.measurementsGuidePage .measurementList ul li .defaultSize::after {
    left: -10px;
    right: unset !important;
    transform: rotate(180deg); }
    @media (max-width: 991.98px) {
      .page__en.measurementsGuidePage .measurementList ul li .defaultSize::after {
        left: -9px; } }

@media (min-width: 768px) {
  .page__en.measurementsGuidePage .measurementList ul li img {
    margin-right: 5% !important;
    margin-left: unset !important; } }

.page__en.measurementsGuidePage .toAddMeasurement::after {
  right: -46px;
  left: unset !important;
  transform: rotate(180deg); }
  @media (max-width: 991.98px) {
    .page__en.measurementsGuidePage .toAddMeasurement::after {
      right: -45px; } }

.addMeasurementPage * {
  font-family: "Tajawal"; }

.addMeasurementPage .pageHeaderContainer {
  border-bottom: 1px solid #ebebeb; }
  .addMeasurementPage .pageHeaderContainer .pageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 767.98px) {
      .addMeasurementPage .pageHeaderContainer .pageHeader {
        flex-direction: column-reverse; } }
    .addMeasurementPage .pageHeaderContainer .pageHeader .fileName {
      /* align-items: stretch; */
      /* height: 100%; */
      display: flex;
      background: #f8f8f8;
      align-self: center;
      padding: 2.3125rem 0 2.3125rem 4.375rem;
      border-left: 1px solid #ebebeb;
      box-shadow: 340px -150px 0 150px #f8f8f8;
      align-items: center;
      position: relative; }
      .addMeasurementPage .pageHeaderContainer .pageHeader .fileName .tooltip {
        display: none;
        background-color: white;
        padding: 0.9375rem;
        border-radius: 5px;
        border: 1px solid white;
        position: absolute;
        z-index: 999999;
        margin-top: 1.25rem;
        font-size: 0.75rem;
        color: #595959;
        bottom: 0;
        transform: translateY(50%); }
      .addMeasurementPage .pageHeaderContainer .pageHeader .fileName:hover .tooltip {
        display: block; }
      .addMeasurementPage .pageHeaderContainer .pageHeader .fileName label {
        margin-bottom: 0;
        padding-left: 0.625rem; }
      @media (max-width: 767.98px) {
        .addMeasurementPage .pageHeaderContainer .pageHeader .fileName {
          box-shadow: none;
          padding: 2rem 1rem;
          border-left: none;
          width: 100%; } }
      .addMeasurementPage .pageHeaderContainer .pageHeader .fileName input {
        padding: 0;
        border: none;
        background: #f8f8f8;
        border-bottom: 1px solid #ebebeb;
        font-size: 1.125rem;
        max-width: 7.5rem; }
        .addMeasurementPage .pageHeaderContainer .pageHeader .fileName input::placeholder {
          color: #7c7c7b; }
        .addMeasurementPage .pageHeaderContainer .pageHeader .fileName input:focus {
          outline: none; }
    .addMeasurementPage .pageHeaderContainer .pageHeader .buttonsContainer {
      display: flex;
      align-items: center; }
      @media (max-width: 767.98px) {
        .addMeasurementPage .pageHeaderContainer .pageHeader .buttonsContainer {
          justify-content: space-between;
          width: 100%; } }
      .addMeasurementPage .pageHeaderContainer .pageHeader .buttonsContainer .button {
        margin: 1.5rem 2.75rem 1.5rem 6rem;
        padding: 0.9375rem 1.5rem;
        display: block;
        cursor: pointer; }
        @media (max-width: 768px) {
          .addMeasurementPage .pageHeaderContainer .pageHeader .buttonsContainer .button {
            margin: 1.5rem 1rem; } }
        .addMeasurementPage .pageHeaderContainer .pageHeader .buttonsContainer .button span {
          display: block; }
        .addMeasurementPage .pageHeaderContainer .pageHeader .buttonsContainer .button:focus {
          outline: none; }
      .addMeasurementPage .pageHeaderContainer .pageHeader .buttonsContainer .closeBtn {
        color: #7c7c7b;
        cursor: pointer;
        text-decoration: none;
        font-size: 1.375rem;
        font-weight: normal;
        display: block;
        z-index: 1; }
        .addMeasurementPage .pageHeaderContainer .pageHeader .buttonsContainer .closeBtn span {
          display: block; }

@media (min-width: 576px) {
  .addMeasurementPage .modal-dialog {
    max-width: 600px; } }

@media (max-width: 520px) {
  .addMeasurementPage .modal-footer {
    flex-wrap: wrap !important;
    margin: 0 1.5rem; } }

.page__en.addMeasurementPage .pageHeaderContainer .pageHeader .fileName {
  text-indent: 1rem; }
  .page__en.addMeasurementPage .pageHeaderContainer .pageHeader .fileName label {
    padding-left: 0;
    padding-right: 0.625rem; }

.AddMeasurementPart {
  display: flex;
  padding: 1.25rem 0 1.875rem;
  background-image: url("images/add-measurement/pattern.png");
  background-repeat-y: no-repeat;
  background-size: contain;
  justify-content: space-between; }
  .AddMeasurementPart .sidebar {
    width: 75%;
    padding: 0;
    margin: 0;
    list-style: none; }
    .AddMeasurementPart .sidebar li {
      margin-bottom: 0.3125rem;
      display: none; }
      .AddMeasurementPart .sidebar li * {
        display: block; }
      .AddMeasurementPart .sidebar li .title {
        background-image: url("images/add-measurement/item-bg.png");
        width: 16.875rem;
        height: 4.375rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        font-size: 1.375rem;
        font-weight: 300;
        position: relative;
        margin-bottom: 2.375rem; }
        .AddMeasurementPart .sidebar li .title .arabicNumber {
          padding-left: 0.625rem; }
        .AddMeasurementPart .sidebar li .title::before {
          content: "";
          height: 100%;
          width: calc((100vw - 1140px + 15px) / 2);
          background: #f8f8f8;
          position: absolute;
          right: 0;
          transform: translateX(100%);
          z-index: 99999; }
          @media (min-width: 1200px) {
            .AddMeasurementPart .sidebar li .title::before {
              width: calc((100vw - 1140px + 15px) / 2); } }
          @media screen and (min-width: 992px) {
            .AddMeasurementPart .sidebar li .title::before {
              width: calc((100vw - 960px + 15px) / 2); } }
          @media (min-width: 768px) {
            .AddMeasurementPart .sidebar li .title::before .container {
              width: calc((100vw - 720px + 15px) / 2); } }
          @media (min-width: 576px) {
            .AddMeasurementPart .sidebar li .title::before {
              width: calc((100vw - 540px + 15px) / 2); } }
      .AddMeasurementPart .sidebar li .minAndMax {
        display: flex;
        margin-bottom: 4.75rem; }
        .AddMeasurementPart .sidebar li .minAndMax__minValue, .AddMeasurementPart .sidebar li .minAndMax__maxValue {
          display: flex;
          color: #848484;
          font-size: 0.875rem;
          align-items: flex-end;
          position: relative; }
          .AddMeasurementPart .sidebar li .minAndMax__minValue::after, .AddMeasurementPart .sidebar li .minAndMax__maxValue::after {
            content: "";
            width: 0.5625rem;
            height: 0.6875rem;
            position: absolute;
            align-self: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center; }
          .AddMeasurementPart .sidebar li .minAndMax__minValue span, .AddMeasurementPart .sidebar li .minAndMax__maxValue span {
            color: #7c7c7b;
            font-size: 1.125rem;
            padding: 0 0.3125rem; }
        .AddMeasurementPart .sidebar li .minAndMax__minValue {
          padding-right: 0.875rem; }
          .AddMeasurementPart .sidebar li .minAndMax__minValue::after {
            right: 0;
            background-image: url("images/add-measurement/min-bg.png"); }
        .AddMeasurementPart .sidebar li .minAndMax__maxValue {
          padding-right: 2.375rem; }
          .AddMeasurementPart .sidebar li .minAndMax__maxValue::before {
            content: "/";
            padding: 0 0.4375rem;
            position: absolute;
            right: 0;
            font-weight: bold; }
          .AddMeasurementPart .sidebar li .minAndMax__maxValue::after {
            right: 24px;
            background-image: url("images/add-measurement/max-bg.png"); }
      .AddMeasurementPart .sidebar li .measureSteps__title {
        font-size: 0.875rem;
        color: #858585;
        margin-bottom: 0.625rem; }
      .AddMeasurementPart .sidebar li .measureSteps__text {
        font-size: 1rem;
        color: #7c7c7b;
        width: 70%; }
      .AddMeasurementPart .sidebar li .measureTip {
        font-size: 0.875rem;
        color: #595959;
        display: flex;
        margin-top: 65px; }
        .AddMeasurementPart .sidebar li .measureTip strong {
          font-size: 0.875rem;
          color: #7c7c7b; }
      .AddMeasurementPart .sidebar li.active {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0; }
  .AddMeasurementPart .imageContainer p {
    font-size: 0.75rem;
    color: #858585; }
  .AddMeasurementPart .imageContainer video {
    display: none;
    max-width: 100%; }
    .AddMeasurementPart .imageContainer video.active {
      display: block; }
  .AddMeasurementPart .imageContainer img {
    display: none;
    max-width: 100%;
    width: 400px; }
    .AddMeasurementPart .imageContainer img.active {
      display: block; }
  @media (max-width: 991px) {
    .AddMeasurementPart {
      flex-wrap: wrap; }
      .AddMeasurementPart .sidebar, .AddMeasurementPart .imageContainer {
        width: 100%; }
      .AddMeasurementPart .sidebar li .title {
        padding: 0 1rem; }
      .AddMeasurementPart .sidebar li .minAndMax {
        margin-bottom: 1rem; }
      .AddMeasurementPart .sidebar li .measureTip {
        margin: 1rem 0; } }

.page__en.addMeasurementPage .fileName {
  border-left: 0 !important;
  border-right: 1px solid #ebebeb;
  padding-left: 0 !important;
  padding-right: 4.375rem !important;
  position: relative; }

.page__en.addMeasurementPage .buttonsContainer button {
  margin-right: 2rem; }
  @media (max-width: 768px) {
    .page__en.addMeasurementPage .buttonsContainer button {
      margin-right: auto;
      margin-left: 0; } }

.page__en.addMeasurementPage .AddMeasurementPart .sidebar li .title {
  background-image: url("images/add-measurement/item-mirror-bg.png");
  background-position: right center;
  background-repeat: no-repeat; }
  .page__en.addMeasurementPage .AddMeasurementPart .sidebar li .title::before {
    transform: translateX(-100%);
    right: auto;
    left: 0; }
  .page__en.addMeasurementPage .AddMeasurementPart .sidebar li .title span {
    padding-right: 0.625rem; }

.page__en.addMeasurementPage .AddMeasurementPart .sidebar li .minAndMax__minValue {
  padding-left: 0.875rem;
  padding-right: 0; }
  .page__en.addMeasurementPage .AddMeasurementPart .sidebar li .minAndMax__minValue::after {
    left: 0;
    background-image: url("images/add-measurement/min-bg.png"); }

.page__en.addMeasurementPage .AddMeasurementPart .sidebar li .minAndMax__maxValue {
  padding-left: 2.375rem; }
  .page__en.addMeasurementPage .AddMeasurementPart .sidebar li .minAndMax__maxValue::before {
    content: "/";
    padding: 0 0.4375rem;
    position: absolute;
    left: 0;
    font-weight: bold; }
  .page__en.addMeasurementPage .AddMeasurementPart .sidebar li .minAndMax__maxValue::after {
    left: 24px;
    background-image: url("images/add-measurement/max-bg.png"); }

.addMeasurementPage .PageFooter {
  background: #f8f8f8; }
  .addMeasurementPage .PageFooter .innerFooter {
    display: none;
    justify-content: space-between;
    align-items: center; }
    .addMeasurementPage .PageFooter .innerFooter.active {
      display: flex; }
      @media (max-width: 991.98px) {
        .addMeasurementPage .PageFooter .innerFooter.active {
          flex-wrap: wrap; } }
    @media (max-width: 1199.98px) {
      .addMeasurementPage .PageFooter .innerFooter {
        flex-direction: column-reverse; } }
    .addMeasurementPage .PageFooter .innerFooter .prev,
    .addMeasurementPage .PageFooter .innerFooter .next {
      font-size: 1.5625rem;
      font-weight: bold;
      color: #7c7c7b;
      display: flex;
      align-items: center;
      width: 10.625rem;
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
      cursor: pointer; }
      @media (max-width: 767.98px) {
        .addMeasurementPage .PageFooter .innerFooter .prev,
        .addMeasurementPage .PageFooter .innerFooter .next {
          justify-content: center; } }
      .addMeasurementPage .PageFooter .innerFooter .prev span::before,
      .addMeasurementPage .PageFooter .innerFooter .next span::before {
        color: #7c7c7b; }
      @media (max-width: 1199.98px) {
        .addMeasurementPage .PageFooter .innerFooter .prev,
        .addMeasurementPage .PageFooter .innerFooter .next {
          padding: 1.25rem 0; } }
      .addMeasurementPage .PageFooter .innerFooter .prev.disabled span,
      .addMeasurementPage .PageFooter .innerFooter .next.disabled span {
        cursor: no-drop; }
        .addMeasurementPage .PageFooter .innerFooter .prev.disabled span::before,
        .addMeasurementPage .PageFooter .innerFooter .next.disabled span::before {
          color: #b7b7b7; }
    .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer {
      display: flex; }
      @media (max-width: 991.98px) {
        .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer {
          margin: auto; } }
      .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .label {
        background: #7c7c7b;
        width: 13rem;
        height: 6.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-position: right;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px; }
        @media (max-width: 575.98px) {
          .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .label {
            width: 11.25rem; } }
        .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .label label {
          color: #ffffff;
          font-size: 1.5rem;
          margin: 0; }
          @media (max-width: 575.98px) {
            .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .label label {
              font-size: 1.1rem; } }
      .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer {
        background: #7c7c7b;
        width: 16.8125rem;
        height: 6.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px; }
        @media (max-width: 575.98px) {
          .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer {
            width: 10rem; } }
        .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer input[type="number"] {
          -webkit-appearance: textfield;
          -moz-appearance: textfield;
          appearance: textfield; }
        .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer input[type=number]::-webkit-inner-spin-button,
        .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none; }
        .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer input {
          padding: 0;
          border: none;
          background: #7c7c7b;
          border-bottom: 1px solid #8a633e;
          font-size: 1.125rem;
          max-width: 7.5rem;
          color: #8a633e;
          font-size: 2.5rem;
          font-weight: bold;
          text-indent: 20px;
          text-align: center;
          min-width: 5rem;
          height: 3.75rem; }
          @media (max-width: 575.98px) {
            .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer input {
              min-width: 2.5rem; } }
          .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer input:focus {
            outline: none; }
          @media (max-width: 991.98px) {
            .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer input {
              font-size: 1.875rem; } }
        .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer .cm {
          color: #8a633e;
          font-size: 1.5625rem;
          border-bottom: 1px solid #8a633e;
          height: 3.75rem;
          line-height: 3.75rem;
          padding-right: 0.625rem; }
        .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer .plus, .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer .minus {
          position: absolute;
          width: 28px;
          right: 28px;
          background: 0;
          border: 0; }
          .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer .plus:focus, .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer .minus:focus {
            outline: none; }
          @media (max-width: 991.98px) {
            .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer .plus, .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer .minus {
              right: 20px; } }
        .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer .plus {
          top: 20px; }
        .addMeasurementPage .PageFooter .innerFooter .measurementPartContainer .inputContainer .minus {
          bottom: 28px; }
    .addMeasurementPage .PageFooter .innerFooter .next span:last-child {
      padding-right: 0.9375rem; }
      @media (max-width: 575.98px) {
        .addMeasurementPage .PageFooter .innerFooter .next span:last-child {
          padding-right: 0rem; } }
    .addMeasurementPage .PageFooter .innerFooter .next.disabled {
      justify-content: flex-end; }
    .addMeasurementPage .PageFooter .innerFooter .prev span:first-child {
      padding-left: 0.9375rem; }
      @media (max-width: 575.98px) {
        .addMeasurementPage .PageFooter .innerFooter .prev span:first-child {
          padding-left: 0rem; } }
    .addMeasurementPage .PageFooter .innerFooter .prev.disabled {
      justify-content: flex-start; }
  .addMeasurementPage .PageFooter__errMsg {
    background: #ffffff;
    color: #de0000;
    text-align: center;
    padding-bottom: 0.625rem;
    display: none; }
    .addMeasurementPage .PageFooter__errMsg p {
      opacity: 0;
      text-align: center; }
    .addMeasurementPage .PageFooter__errMsg span {
      padding: 0 0.3125rem; }
    .addMeasurementPage .PageFooter__errMsg.active {
      display: block; }
  @media (min-width: 991px) {
    .addMeasurementPage .PageFooter {
      position: absolute;
      width: 100%;
      bottom: 0; } }

.page__en.addMeasurementPage .innerFooter .measurementPartContainer .label {
  padding-left: 2.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px; }
  .page__en.addMeasurementPage .innerFooter .measurementPartContainer .label::after {
    left: -6.25rem;
    right: unset !important;
    background-position: left center;
    transform: rotate(180deg); }

.page__en.addMeasurementPage .innerFooter .measurementPartContainer .inputContainer {
  padding-right: 2.5rem;
  padding-left: unset !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px; }
  .page__en.addMeasurementPage .innerFooter .measurementPartContainer .inputContainer::after {
    right: -6.25rem;
    left: unset !important;
    background-position: right center;
    transform: rotate(0deg) !important; }
  .page__en.addMeasurementPage .innerFooter .measurementPartContainer .inputContainer .plus, .page__en.addMeasurementPage .innerFooter .measurementPartContainer .inputContainer .minus {
    right: 0;
    left: 28px; }
    @media (max-width: 991.98px) {
      .page__en.addMeasurementPage .innerFooter .measurementPartContainer .inputContainer .plus, .page__en.addMeasurementPage .innerFooter .measurementPartContainer .inputContainer .minus {
        left: -5px; } }

.page__en.addMeasurementPage .innerFooter .prev span.icon-arrow-left, .page__en.addMeasurementPage .innerFooter .prev span.icon-arrow-right,
.page__en.addMeasurementPage .innerFooter .next span.icon-arrow-left,
.page__en.addMeasurementPage .innerFooter .next span.icon-arrow-right {
  transform: rotate(180deg); }

.addMeasurementPage .confirmationModal.modal .modal-footer .button {
  text-align: center; }
  .addMeasurementPage .confirmationModal.modal .modal-footer .button::after {
    display: none; }
  .addMeasurementPage .confirmationModal.modal .modal-footer .button:first-of-type {
    margin-right: 0;
    margin-left: 0.3125rem; }
  .addMeasurementPage .confirmationModal.modal .modal-footer .button:last-of-type {
    margin-right: 0.3125rem; }
  .addMeasurementPage .confirmationModal.modal .modal-footer .button:focus, .addMeasurementPage .confirmationModal.modal .modal-footer .button:hover {
    box-shadow: none;
    outline: none; }

.addMeasurementPage .modal-footer .button::after {
  display: none; }

.page__en .modal-header button {
  margin-left: auto !important;
  margin-right: 0 !important; }

.page__en .modal-footer .button:first-of-type {
  margin-right: 0.3125rem; }

.page__en .modal-footer .button:last-of-type {
  margin-right: 0;
  margin-left: 0.3125rem; }

.thoob-design-page {
  width: 100%;
  height: 100%;
  background-color: #e2e2e2; }
  .thoob-design-page .dark-bg {
    background-color: #c09256;
    color: #fff; }
  .thoob-design-page .dark-bg-web {
    background-color: #c09256; }
    @media (max-width: 767.98px) {
      .thoob-design-page .dark-bg-web {
        background-color: #ffffff; } }
  .thoob-design-page .header {
    display: flex;
    justify-content: flex-start;
    align-items: baseline; }
    @media (max-width: 767.98px) {
      .thoob-design-page .header {
        padding: 0px;
        justify-content: space-between; } }
    .thoob-design-page .header .logo {
      width: 80px;
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
      margin-left: 20px; }
      .thoob-design-page .header .logo img {
        width: 70px; }
    .thoob-design-page .header .links a {
      color: #fff; }
    @media (max-width: 767.98px) {
      .thoob-design-page .header .links {
        display: none; } }
    .thoob-design-page .header .cart-part {
      display: none;
      align-items: center;
      justify-content: space-between;
      width: 70%; }
      @media (max-width: 767.98px) {
        .thoob-design-page .header .cart-part {
          display: flex; } }
      .thoob-design-page .header .cart-part .count-part {
        flex: 2;
        font-size: 1em; }
        .thoob-design-page .header .cart-part .count-part button {
          background: #c09256;
          color: #fff;
          width: 40px;
          height: 40px;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          margin: 0 8px;
          font-size: 1em;
          display: flex;
          justify-content: center;
          align-items: center; }
        .thoob-design-page .header .cart-part .count-part span {
          margin: 0 10px;
          color: #c09256; }
      .thoob-design-page .header .cart-part .cartBtn {
        padding: 10px;
        border: none;
        background-color: goldenrod;
        color: #fff;
        flex: 2;
        justify-content: space-around;
        height: 70px;
        width: 130px;
        border-radius: unset; }
        .thoob-design-page .header .cart-part .cartBtn span {
          font-size: 1em; }
        .thoob-design-page .header .cart-part .cartBtn p {
          font-size: 1.6em; }
          @media (max-width: 767.98px) {
            .thoob-design-page .header .cart-part .cartBtn p {
              font-size: 1.2em; } }
        .thoob-design-page .header .cart-part .cartBtn .icon-shopping-cart1 {
          font-size: 2em; }
  .thoob-design-page .main {
    padding: 20px;
    width: 100%;
    height: calc(100vh - 140px);
    display: flex;
    justify-content: space-between;
    align-items: stretch; }
    @media (max-width: 767.98px) {
      .thoob-design-page .main {
        height: calc(100vh - 240px); } }
    .thoob-design-page .main .designPart {
      flex: 4; }
      .thoob-design-page .main .designPart .customsPart {
        height: 100%;
        display: flex;
        align-items: stretch;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px; }
        .thoob-design-page .main .designPart .customsPart .sidebar {
          width: 100px;
          background-color: #c09256;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          padding-top: 2px; }
          @media (max-width: 767.98px) {
            .thoob-design-page .main .designPart .customsPart .sidebar {
              flex-wrap: wrap; } }
          @media (max-width: 767.98px) {
            .thoob-design-page .main .designPart .customsPart .sidebar {
              position: fixed;
              bottom: 0;
              left: 0;
              flex-direction: row;
              width: 100%;
              padding: 20px 3px;
              align-items: baseline; } }
          .thoob-design-page .main .designPart .customsPart .sidebar .sidebarOption {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer; }
            @media (max-width: 767.98px) {
              .thoob-design-page .main .designPart .customsPart .sidebar .sidebarOption {
                width: 25%;
                margin-bottom: 10px; } }
            .thoob-design-page .main .designPart .customsPart .sidebar .sidebarOption .image-container {
              display: flex;
              justify-content: center;
              align-items: center; }
            .thoob-design-page .main .designPart .customsPart .sidebar .sidebarOption img {
              width: 40px; }
              @media (max-width: 991.98px) {
                .thoob-design-page .main .designPart .customsPart .sidebar .sidebarOption img {
                  width: 35px; } }
              @media (max-width: 767.98px) {
                .thoob-design-page .main .designPart .customsPart .sidebar .sidebarOption img {
                  width: 35px; } }
            .thoob-design-page .main .designPart .customsPart .sidebar .sidebarOption p {
              color: #f4f4f4;
              margin-top: 2px;
              font-size: .9em;
              font-weight: 500; }
              @media (max-width: 767.98px) {
                .thoob-design-page .main .designPart .customsPart .sidebar .sidebarOption p {
                  font-size: 0.9em; } }
        .thoob-design-page .main .designPart .customsPart .sidebar .selected p {
          color: #8a633e; }
        .thoob-design-page .main .designPart .customsPart .options {
          width: 80%;
          background-color: #fff;
          padding: 15px;
          overflow-y: auto; }
          .thoob-design-page .main .designPart .customsPart .options::-webkit-scrollbar {
            width: 6px; }
          .thoob-design-page .main .designPart .customsPart .options::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px; }
          .thoob-design-page .main .designPart .customsPart .options::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px; }
          @media (max-width: 767.98px) {
            .thoob-design-page .main .designPart .customsPart .options {
              width: 100%; } }
          .thoob-design-page .main .designPart .customsPart .options .not-available {
            font-size: 1.2em;
            width: 60%;
            margin-bottom: 20px; }
            @media (max-width: 991.98px) {
              .thoob-design-page .main .designPart .customsPart .options .not-available {
                width: 100%;
                font-size: 1em;
                margin-bottom: 20px; } }
          .thoob-design-page .main .designPart .customsPart .options .addMeasurement .btn {
            border: 1px solid #707070;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            width: 120px;
            padding: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2em; }
            @media (max-width: 767.98px) {
              .thoob-design-page .main .designPart .customsPart .options .addMeasurement .btn {
                width: 100px;
                padding: 0; } }
          .thoob-design-page .main .designPart .customsPart .options .addMeasurement p {
            margin-bottom: 20px; }
          .thoob-design-page .main .designPart .customsPart .options .radioBtn input {
            margin-left: 0.625rem;
            margin-bottom: 0.3125rem;
            cursor: pointer;
            background-color: #ffffff !important; }
          .thoob-design-page .main .designPart .customsPart .options .radioBtn label {
            font-size: 0.75rem;
            width: 100%;
            display: block;
            padding: 0.875rem 0;
            color: #333333; }
            .thoob-design-page .main .designPart .customsPart .options .radioBtn label span {
              color: #8a8a8a;
              font-size: 0.625rem; }
          .thoob-design-page .main .designPart .customsPart .options .dropdownForm {
            position: relative;
            display: inline-block;
            width: 200px;
            z-index: 99;
            margin: 10px 0; }
            @media (max-width: 991.98px) {
              .thoob-design-page .main .designPart .customsPart .options .dropdownForm {
                width: 100%; } }
            .thoob-design-page .main .designPart .customsPart .options .dropdownForm .dropdown {
              white-space: nowrap;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              padding: 0.625rem 0;
              border: 1px solid #ededed;
              padding: 0.9375rem 0;
              cursor: pointer;
              pointer-events: none;
              width: 100%;
              border-radius: 8px;
              -webkit-border-radius: 8px;
              -moz-border-radius: 8px;
              -ms-border-radius: 8px;
              -o-border-radius: 8px; }
              .thoob-design-page .main .designPart .customsPart .options .dropdownForm .dropdown p {
                color: #333333;
                margin: 0;
                padding: 0 0.625rem;
                pointer-events: auto;
                margin-left: 10px; }
              .thoob-design-page .main .designPart .customsPart .options .dropdownForm .dropdown.active {
                background: #ededed;
                border: 1px solid #8a633e; }
                .thoob-design-page .main .designPart .customsPart .options .dropdownForm .dropdown.active p {
                  color: #7c7c7b;
                  margin: 0;
                  pointer-events: auto; }
                  .thoob-design-page .main .designPart .customsPart .options .dropdownForm .dropdown.active p.dropdownHeaderCopy {
                    background: #ededed;
                    white-space: nowrap; }
          .thoob-design-page .main .designPart .customsPart .options .dropdownList {
            display: none;
            position: absolute;
            top: 3.125rem;
            right: 0;
            left: auto;
            width: auto;
            background-color: #f2f2f2;
            border-bottom: 1px solid #ededed;
            max-height: 190px;
            overflow-y: auto;
            overflow-x: hidden;
            min-width: 200px; }
            .thoob-design-page .main .designPart .customsPart .options .dropdownList p {
              cursor: pointer;
              margin: 0;
              padding: 0.3125rem 0.625rem;
              color: #7c7c7b;
              border-bottom: 1px solid #ededed; }
              .thoob-design-page .main .designPart .customsPart .options .dropdownList p a {
                color: #333333; }
                .thoob-design-page .main .designPart .customsPart .options .dropdownList p a:hover {
                  color: #7c7c7b; }
              .thoob-design-page .main .designPart .customsPart .options .dropdownList p.uncomplete {
                white-space: normal;
                font-size: 0.5rem;
                color: #333333;
                padding: 0 0.3125rem; }
            .thoob-design-page .main .designPart .customsPart .options .dropdownList__item {
              white-space: nowrap;
              padding: 0.3125rem 0;
              border: 1px solid #ededed;
              background-color: #ffffff; }
              .thoob-design-page .main .designPart .customsPart .options .dropdownList__item:not(:first-of-type) {
                border-top: 0; }
          .thoob-design-page .main .designPart .customsPart .options .items-container {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px; }
            .thoob-design-page .main .designPart .customsPart .options .items-container .slideItem {
              width: 33%; }
              .thoob-design-page .main .designPart .customsPart .options .items-container .slideItem .contentItem {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center; }
                .thoob-design-page .main .designPart .customsPart .options .items-container .slideItem .contentItem label {
                  text-align: center; }
                  @media (max-width: 767.98px) {
                    .thoob-design-page .main .designPart .customsPart .options .items-container .slideItem .contentItem label {
                      font-size: .8em; } }
          .thoob-design-page .main .designPart .customsPart .options .itemImage {
            width: 80px;
            height: 80px;
            padding: 10px;
            margin-bottom: 8px;
            border: 1px solid #b4b4b4;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center; }
            @media (max-width: 767.98px) {
              .thoob-design-page .main .designPart .customsPart .options .itemImage {
                width: 60px;
                height: 60px; } }
          .thoob-design-page .main .designPart .customsPart .options .tickCircle .icon-tick .path1::before {
            color: #8a633e !important; }
          .thoob-design-page .main .designPart .customsPart .options .itemContentDesc {
            display: flex;
            border-bottom: 1px solid #ddd;
            padding: 10px;
            align-items: flex-end; }
            .thoob-design-page .main .designPart .customsPart .options .itemContentDesc .itemImage {
              margin: 0 8px;
              width: 60px;
              height: 60px; }
            .thoob-design-page .main .designPart .customsPart .options .itemContentDesc .image__desc {
              max-width: 80%;
              flex: 5; }
              @media (max-width: 767.98px) {
                .thoob-design-page .main .designPart .customsPart .options .itemContentDesc .image__desc {
                  font-size: .8em; } }
            .thoob-design-page .main .designPart .customsPart .options .itemContentDesc img {
              max-width: 120px; }
          .thoob-design-page .main .designPart .customsPart .options .imagesContainer {
            display: flex;
            flex-flow: wrap; }
        .thoob-design-page .main .designPart .customsPart .actions {
          padding: 0 10px; }
          @media (max-width: 767.98px) {
            .thoob-design-page .main .designPart .customsPart .actions {
              position: absolute;
              top: 12%; } }
          .thoob-design-page .main .designPart .customsPart .actions .actionsBtns {
            background-color: #c09256;
            padding: 5px;
            height: 120px;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center; }
            .thoob-design-page .main .designPart .customsPart .actions .actionsBtns .actionBtn image {
              height: 40px;
              display: block;
              margin: auto; }
            .thoob-design-page .main .designPart .customsPart .actions .actionsBtns .actionBtn p {
              color: #fff;
              text-align: center;
              font-size: 0.9em; }
        .thoob-design-page .main .designPart .customsPart .messagePopup__content {
          width: 33% !important;
          margin: 0 auto;
          background-color: #ffffff;
          padding: 2.8125rem;
          padding-top: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center; }
          @media (max-width: 991.98px) {
            .thoob-design-page .main .designPart .customsPart .messagePopup__content {
              width: 95% !important; } }
          .thoob-design-page .main .designPart .customsPart .messagePopup__content p.discount {
            font-size: 2.1875rem;
            font-weight: 500; }
          .thoob-design-page .main .designPart .customsPart .messagePopup__content.subMsg {
            padding: 2.8125rem 0; }
            .thoob-design-page .main .designPart .customsPart .messagePopup__content.subMsg .messagePopup__content__btns {
              width: 100%; }
              .thoob-design-page .main .designPart .customsPart .messagePopup__content.subMsg .messagePopup__content__btns .Btn,
              .thoob-design-page .main .designPart .customsPart .messagePopup__content.subMsg .messagePopup__content__btns .oppBtn,
              .thoob-design-page .main .designPart .customsPart .messagePopup__content.subMsg .messagePopup__content__btns .goOut {
                padding: 0.9375rem; }
                .thoob-design-page .main .designPart .customsPart .messagePopup__content.subMsg .messagePopup__content__btns .Btn button,
                .thoob-design-page .main .designPart .customsPart .messagePopup__content.subMsg .messagePopup__content__btns .Btn a,
                .thoob-design-page .main .designPart .customsPart .messagePopup__content.subMsg .messagePopup__content__btns .oppBtn button,
                .thoob-design-page .main .designPart .customsPart .messagePopup__content.subMsg .messagePopup__content__btns .oppBtn a,
                .thoob-design-page .main .designPart .customsPart .messagePopup__content.subMsg .messagePopup__content__btns .goOut button,
                .thoob-design-page .main .designPart .customsPart .messagePopup__content.subMsg .messagePopup__content__btns .goOut a {
                  padding: 0.625rem 1.875rem !important;
                  font-size: 0.9375rem; }
              .thoob-design-page .main .designPart .customsPart .messagePopup__content.subMsg .messagePopup__content__btns .oppBtn button {
                color: #7c7c7b !important;
                background-color: #ffffff !important;
                border: 1px solid #7c7c7b; }
              .thoob-design-page .main .designPart .customsPart .messagePopup__content.subMsg .messagePopup__content__btns .goOut a {
                background-color: #ffffff !important;
                border: 1px solid #7c7c7b !important;
                color: #7c7c7b !important; }
          .thoob-design-page .main .designPart .customsPart .messagePopup__content img {
            max-width: 20%; }
          .thoob-design-page .main .designPart .customsPart .messagePopup__content p {
            padding: 0.9375rem; }
          .thoob-design-page .main .designPart .customsPart .messagePopup__content h3 {
            font-size: 1.5625rem; }
          .thoob-design-page .main .designPart .customsPart .messagePopup__content__closeIcon {
            display: inline-flex;
            align-items: flex-start;
            cursor: pointer;
            align-self: flex-end;
            margin: 1.25rem;
            margin-top: 0; }
          .thoob-design-page .main .designPart .customsPart .messagePopup__content.cancelOrder {
            background: red; }
            .thoob-design-page .main .designPart .customsPart .messagePopup__content.cancelOrder__cancelTips h5 {
              margin-bottom: 1.25rem; }
            .thoob-design-page .main .designPart .customsPart .messagePopup__content.cancelOrder__cancelTips h6 {
              font-weight: 600; }
            .thoob-design-page .main .designPart .customsPart .messagePopup__content.cancelOrder .whiteBg {
              padding: 0.625rem 2.5rem !important; }
          .thoob-design-page .main .designPart .customsPart .messagePopup__content.orderModify {
            width: 40%;
            margin: 0; }
            .thoob-design-page .main .designPart .customsPart .messagePopup__content.orderModify img {
              max-width: 100% !important; }
            .thoob-design-page .main .designPart .customsPart .messagePopup__content.orderModify .messagePopup__content__closeIcon {
              margin-left: 0; }
              .thoob-design-page .main .designPart .customsPart .messagePopup__content.orderModify .messagePopup__content__closeIcon p {
                font-size: 1.25rem;
                padding-left: 0;
                padding-right: 0; }
                .thoob-design-page .main .designPart .customsPart .messagePopup__content.orderModify .messagePopup__content__closeIcon p:last-child {
                  color: #8e8e8e; }
                  .thoob-design-page .main .designPart .customsPart .messagePopup__content.orderModify .messagePopup__content__closeIcon p:last-child:hover {
                    color: #7c7c7b; }
                .thoob-design-page .main .designPart .customsPart .messagePopup__content.orderModify .messagePopup__content__closeIcon p span {
                  color: #8e8e8e;
                  font-size: 0.875rem; }
            .thoob-design-page .main .designPart .customsPart .messagePopup__content.orderModify .orderModify__custom {
              width: 100%;
              border: 1px solid #8e8e8e;
              padding: 0.9375rem; }
              @media (max-width: 991.98px) {
                .thoob-design-page .main .designPart .customsPart .messagePopup__content.orderModify .orderModify__custom {
                  padding: 0.625rem 0.3125rem; } }
              .thoob-design-page .main .designPart .customsPart .messagePopup__content.orderModify .orderModify__custom__titleimages h3 {
                font-size: 1.25rem;
                font-family: "TheSans-Plain";
                color: #7c7c7b;
                margin-left: 1.25rem; }
              .thoob-design-page .main .designPart .customsPart .messagePopup__content.orderModify .orderModify__custom__titleimages img {
                margin-right: 0.1875rem;
                max-width: none;
                width: 85px;
                height: 75px;
                border: 1px solid #8e8e8e; }
              .thoob-design-page .main .designPart .customsPart .messagePopup__content.orderModify .orderModify__custom__titleimages label {
                border: 1px solid #8e8e8e;
                padding: 0.9375rem;
                margin-bottom: 0; }
              .thoob-design-page .main .designPart .customsPart .messagePopup__content.orderModify .orderModify__custom__price p {
                font-size: 1.125rem;
                font-family: "TheSans-Plain";
                color: #7c7c7b; }
            .thoob-design-page .main .designPart .customsPart .messagePopup__content.orderModify .customsBlock {
              text-align: right;
              margin-bottom: 1.25rem; }
      .thoob-design-page .main .designPart .items-container.images.subItems {
        width: 100%;
        flex-direction: column; }
        .thoob-design-page .main .designPart .items-container.images.subItems .slideItem {
          width: 100% !important;
          justify-content: space-between; }
          .thoob-design-page .main .designPart .items-container.images.subItems .slideItem .image {
            width: 100%; }
    .thoob-design-page .main .imagesPart {
      flex: 3;
      background-color: #fff;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      padding: 8px; }
      @media (max-width: 767.98px) {
        .thoob-design-page .main .imagesPart {
          display: none; } }
      .thoob-design-page .main .imagesPart .productImages {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px; }
        .thoob-design-page .main .imagesPart .productImages .magnify {
          width: 100% !important; }
  .thoob-design-page .footer {
    background-color: #c09256;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    display: flex;
    padding: 5px;
    justify-content: flex-end; }
    @media (max-width: 767.98px) {
      .thoob-design-page .footer {
        display: none; } }
    .thoob-design-page .footer .cart-part {
      display: flex;
      width: 40%;
      margin: 0 20px;
      justify-content: space-around;
      align-items: center; }
      @media (max-width: 991.98px) {
        .thoob-design-page .footer .cart-part {
          width: 55%; } }
      .thoob-design-page .footer .cart-part .count-part {
        flex: 3;
        font-size: 1.4em; }
        .thoob-design-page .footer .cart-part .count-part button {
          background: #fff;
          color: #c09256;
          width: 40px;
          height: 40px;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          margin: 0 10px;
          font-size: 1.4em;
          display: flex;
          justify-content: center;
          align-items: center; }
        .thoob-design-page .footer .cart-part .count-part span {
          margin: 0 10px;
          color: #fff; }
  .thoob-design-page .cartBtn {
    padding: 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: none;
    background-color: #7b5e14;
    color: #fff;
    flex: 2;
    justify-content: space-around;
    float: left; }
  .thoob-design-page .magnify img {
    width: 100%;
    min-height: 70vh; }
